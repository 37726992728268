import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ErrorIcon from "@mui/icons-material/Error";
import useUser from "../hook/useLogin";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress'

export default function Login() {

  const { login, isLoggedIn, error, message, loading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
    return () => { };
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
      });
    }

    return () => { };
  }, [error, message]);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .required("Required"),
          password: Yup.string()
            .required("Required"),
        })}
        onSubmit={(values) => {
          login(values);
        }}
      >
        {({
          values,
          handleChange,
          touched,
          errors
        }) => (
          <Form>
            <div className="flex min-h-screen">
              <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                  <div>
                    <img
                      className="w-1/2"
                      src="/img/FondoMexicoUruguay.png"
                      alt="AMEXCID"
                    />
                    <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                      Iniciar sesión
                    </h2>
                  </div>

                  <div className="mt-8">
                    <div>
                      <div className="relative mt-6">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                          <span className="bg-white px-2 text-gray-500">
                            Bienvenido al sistema de consulta de denuncias PAM
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="mt-6">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Nombre de usuario
                        </label>
                        <div className="mt-2">
                          <input
                            value={values.email}
                            onChange={handleChange}
                            id="email"
                            name="email"
                            type="text"
                            placeholder="Inserta tu nombre de usuario"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-claret-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      {touched.email && errors.email ? (
                        <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.email}
                        </div>
                      ) : null}

                      <div className="space-y-1">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Contraseña
                        </label>
                        <div className="mt-2">
                          <input
                            value={values.password}
                            onChange={handleChange}
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-claret-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      {touched.password && errors.password ? (
                        <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.password}
                        </div>
                      ) : null}

                      <div className="mt-3">
                        {
                          loading ?
                            <button
                              className="flex w-full justify-center rounded-md bg-claret-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-claret-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-claret-600"
                              disabled
                            >
                              Iniciando sesión...
                            </button>
                            :
                            <button
                              type="submit"
                              className="flex w-full justify-center rounded-md bg-claret-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-claret-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-claret-600"
                            >
                              Iniciar sesión
                            </button>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative hidden w-0 flex-1 lg:block h-screen bg-[#9D2449]"></div>
            </div>
          </Form>
        )}
      </Formik >
    </>
  );
}
