/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Layout from '../Components/Layout';
import ComentariosComponents from '../Components/Coomentarios';
import { get } from '../service/get';
import { useParams } from 'react-router-dom';
import useLogin from '../hook/useLogin';
import { post_ } from '../service/post';
import { del } from '../service/delete';
import { put } from '../service/put';
import { useNavigate } from 'react-router-dom';

const Comentarios = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { token, _id, role } = useLogin();
    const [comentarios, setcomentarios] = useState([])
    const [loading, setloading] = useState(false)
    const [updateCommentario, setupdateCommentario] = useState(false)
    const [comentarioValue, setcomentarioValue] = useState("")
    useEffect(() => {
        getInformacion();
        return () => { };
    }, []);

    const getInformacion = () => {
        get("comments/" + params.id, token).then((res) => {
            setcomentarios(res?.comentarios ?? [])
        });
    }

    const guardar = (e) => {
        e.preventDefault();
        setloading(true)
        const comentarios = {
            id_denunciante: params.id,
            description: comentarioValue,
            user_id: _id
        }
        post_("comments-save", comentarios, token).then((res) => {
            setloading(false)
            if (res.success) {
                setcomentarioValue("")
                getInformacion()
            } else {
                alert("No se pudo guardar el comentario")
            }
        })
    }

    const deleteComment = (id) => {
        del("comments-delete/" + id, {}, token).then((res) => {
            if (res.success) {
                setcomentarioValue("")
                getInformacion()
            } else {
                alert("No se pudo eliminar el comentario")
            }
        })
    }

    const updateComment = (e) => {
        e.preventDefault();
        const data = {
            description: comentarioValue,
            _id: updateCommentario
        }
        put("comments-update/" + updateCommentario, data, token).then((res) => {
            if (res.success) {
                setupdateCommentario(false)
                setcomentarioValue("")
                getInformacion()
            } else {
                alert("No se pudo eliminar el comentario")
            }
        })
    }
    return (
        <>
            <Layout>
                {/* button de regresar */}
                <div className="mx-auto px-16 pt-14 lg:pt-20">
                    <div className="grid space-y-6 divide-y p-2">
                        <div className="border-b border-t border-gray-900/10 col-span-4 pb-6 pt-6 mt-6 mb-6 bg-green-100 p-2 rounded shadow flex gap-3">
                            <button onClick={() => {
                                navigate("/")
                            }} className="flex items-center text-gray-900 hover:text-gray-900">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                                </svg>

                            </button>
                            <h2 className="text-base font-bold leading-7 text-gray-900">
                                Comentarios
                            </h2>
                        </div>
                    </div>
                    <div className='w-full flex justify-center'>
                        <div className='w-75'>
                            <ComentariosComponents
                                comentarioValue={comentarioValue}
                                setcomentarioValue={setcomentarioValue}
                                comentarios={comentarios}
                                guardar={guardar}
                                loading={loading}
                                role={role}
                                deleteComment={deleteComment}
                                updateComment={updateComment}
                                updateCommentario={updateCommentario}
                                setupdateCommentario={setupdateCommentario}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Comentarios;