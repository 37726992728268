export const SINONOSABE = [
  { value: "Si", label: "Si" },
  { value: "No", label: "No" },
  { value: "No sabe", label: "No sabe" },
];

export const SINO = [
  { value: "Si", label: "Si" },
  { value: "No", label: "No" }
];

export const DATONUMEROS = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "No sabe", label: "No sabe" },
];

export const HombreMujer = [
  { value: "Hombre", label: "Hombre" },
  { value: "Mujer", label: "Mujer" },
];

export const estadoCivil = [
  { value: "Casado/Unión Libre", label: "Casado/Unión Libre" },
  { value: "Separado", label: "Separado" },
  { value: "Divorciado", label: "Divorciado" },
  { value: "Soltero", label: "Soltero" },
  { value: "Viudo", label: "Viudo" },
];

export const ultimoGrado = [
  { value: "Ninguno", label: "Ninguno" },
  { value: "Primaria", label: "Primaria" },
  { value: "Secundaria", label: "Secundaria" },
  { value: "Preparatoria o Bachiller", label: "Preparatoria o Bachiller" },
  { value: "Universidad", label: "Universidad" },
  { value: "Posgrado", label: "Posgrado" },
];

export const vivienda = [
  { value: "Propio", label: "Propio" },
  { value: "Rentado por la PAM o su cónyuge", label: "Rentado por La PAM o su cónyuge" },
  { value: "De los hijos", label: "De los hijos" },
  { value: "Prestada", label: "Prestada" },
  { value: "Rentada por los hijos", label: "Rentada por los hijos" },
  { value: "Comunidad/asilo", label: "Comunidad/asilo" },
  { value: "En la calle", label: "En la calle" },
];

export const herencia = [
  { value: "No", label: "No" },
  { value: "Si, sin usufructo", label: "Si, sin usufructo" },
  { value: "Si, con usufructo", label: "Si, con usufructo" },
];
export const heredo = [
  { value: "No", label: "No" },
  { value: "Si, parte de lo que tengo", label: "Si, parte de lo que tengo" },
  { value: "Si, todo lo que tenía", label: "Si, todo lo que tenía" },
];

export const viveCon = [
  { value: "Cónyuge", label: "Cónyuge" },
  { value: "Hijos/as", label: "Hijos/as" },
  { value: "Conyuge e hijos/as", label: "Conyuge e hijos/as" },
  { value: "Otros familiares", label: "Otros familiares" },
  { value: "Otras personas (no familiares)", label: "Otras personas (no familiares)" },
  { value: "Solo", label: "Solo" },
];

export const seguridadSocial = [
  { value: "IMSS", label: "IMSS" },
  { value: "ISSSTE", label: "ISSSTE" },
  { value: "Afore", label: "Afore" },
  { value: "Sedena, Pemex, Semar", label: "Sedena, Pemex, Semar" },
  { value: "Otro", label: "Otro" },
  { value: "Ninguno", label: "Ninguno" },
  { value: "No sabe", label: "No sabe" },
];

export const atencionMedica = [
  { value: "IMSS-INSABI", label: "IMSS-INSABI" },
  { value: "ISSSTE", label: "ISSSTE" },
  { value: "Sedena, Pemex, Semar", label: "Sedena, Pemex, Semar" },
  { value: "Médico privado (de paga)", label: "Médico privado (de paga)" },
  { value: "Curandero/herbolaria", label: "Curandero/herbolaria" },
  { value: "Me curo solo/automedico", label: "Me curo solo/automedico" },
  { value: "Espero que pase", label: "Espero que pase" },
];

export const horasTrabajo = [
  { value: "1 a 10hrs", label: "1 a 10hrs" },
  { value: "11 a 20hrs", label: "11 a 20hrs" },
  { value: "21 a 30hrs", label: "21 a 30hrs" },
  { value: "31 a 40hrs", label: "31 a 40hrs" },
  { value: "más de 40hrs", label: "más de 40hrs" },
];

export const remuneracion = [
  { value: "No recibo dinero/no me pagan", label: "No recibo dinero/no me pagan" },
  { value: "1 -1,000", label: "1 -1,000" },
  { value: "1,001 - 2,000", label: "1,001 - 2,000" },
  { value: "2,001 - 3,000", label: "2,001 - 3,000" },
  { value: "3,001 - 4,000", label: "3,001 - 4,000" },
  { value: "4,001 - 5,000", label: "4,001 - 5,000" },
  { value: "más de 5,000", label: "más de 5,000" },
];

export const alzanza = [
  { value: "Siempre alcanzan", label: "Siempre alcanzan" },
  { value: "Casi siempre alcanzan", label: "Casi siempre alcanzan" },
  { value: "A veces alcanzan", label: "A veces alcanzan" },
  { value: "Casi nunca alcanzan", label: "Casi nunca alcanzan" },
  { value: "Nunca alcanzan", label: "Nunca alcanzan" },
];

export const inscritoPrograma = [
  { value: "No estoy inscrito", label: "No estoy inscrito" },
  { value: "Si, sin tarjeta", label: "Si, sin tarjeta" },
  { value: "Si, con tarjeta", label: "Si, con tarjeta" },
];

export const usaTarjeta = [
  { value: "Si, sólo yo", label: "Si, sólo yo" },
  { value: "Si, pero a veces/siempre me ayudan", label: "Si, pero a veces/siempre me ayudan" },
  { value: "No", label: "No" },
];

export const dispone65 = [
  { value: "Si, siempre yo", label: "Si, siempre yo" },
  { value: "A veces", label: "A veces" },
  { value: "Nunca", label: "Nunca" },
];

export const manejoCuentas = [
  { value: 0, label: "Tengo cuentas/tarjetas, las manejo sólo yo" },
  { value: 1, label: "Tengo cuentas/tarjetas, me ayudan en el manejo" },
  { value: 2, label: "Tengo cuentas/tarjetas, pero no las manejo yo" },
];

export const ayuda = [
  { value: "No sé usar el cajero", label: "No sé usar el cajero" },
  { value: "Lo uso sin ayuda", label: "Lo uso sin ayuda" },
  { value: "Lo uso con ayuda de hijos", label: "Lo uso con ayuda de hijos" },
  { value: "Lo uso con ayuda de otros familiares", label: "Lo uso con ayuda de otros familiares" },
  { value: "Lo uso con ayuda de empleados banco", label: "Lo uso con ayuda de empleados banco" },
  { value: "Lo uso con ayuda de extraños", label: "Lo uso con ayuda de extraños" },
];

export const acudeBanco = [
  { value: "No voy al banco", label: "No voy al banco" },
  { value: "No necesito ayuda", label: "No necesito ayuda" },
  { value: "Si, me ayudan los hijos", label: "Si, me ayudan los hijos" },
  { value: "Si, me ayudan otros familiares", label: "Si, me ayudan otros familiares" },
  { value: "Si, me ayudanlos empleados del banco", label: "Si, me ayudanlos empleados del banco" },
  { value: "Si, me ayudan otras personas (extraños)", label: "Si, me ayudan otras personas (extraños)" },
];

export const Municipios = [
  {
    value: "Amaxac de Guerrero",
    label: "Amaxac de Guerrero",
  },
  {
    value: "Apetatitlán de Antonio Carvajal",
    label: "Apetatitlán de Antonio Carvajal",
  },
  {
    value: "Atlangatepec",
    label: "Atlangatepec",
  },
  {
    value: "Atltzayanca",
    label: "Atltzayanca",
  },
  {
    value: "Apizaco",
    label: "Apizaco",
  },
  {
    value: "Calpulalpan",
    label: "Calpulalpan",
  },
  {
    value: "El Carmen Tequexquitla",
    label: "El Carmen Tequexquitla",
  },
  {
    value: "Cuapiaxtla",
    label: "Cuapiaxtla",
  },
  {
    value: "Cuaxomulco",
    label: "Cuaxomulco",
  },
  {
    value: "Chiautempan",
    label: "Chiautempan",
  },
  {
    value: "Muñoz de Domingo Arenas",
    label: "Muñoz de Domingo Arenas",
  },
  {
    value: "Españita",
    label: "Españita",
  },
  {
    value: "Huamantla",
    label: "Huamantla",
  },
  {
    value: "Hueyotlipan",
    label: "Hueyotlipan",
  },
  {
    value: "Ixtacuixtla de Mariano Matamoros",
    label: "Ixtacuixtla de Mariano Matamoros",
  },
  {
    value: "Ixtenco",
    label: "Ixtenco",
  },
  {
    value: "Mazatecochco de José María Morelos",
    label: "Mazatecochco de José María Morelos",
  },
  {
    value: "Contla de Juan Cuamatzi",
    label: "Contla de Juan Cuamatzi",
  },
  {
    value: "Tepetitla de Lardizábal",
    label: "Tepetitla de Lardizábal",
  },
  {
    value: "Sanctórum de Lázaro Cárdenas",
    label: "Sanctórum de Lázaro Cárdenas",
  },
  {
    value: "Nanacamilpa de Mariano Arista",
    label: "Nanacamilpa de Mariano Arista",
  },
  {
    value: "Acuamanala de Miguel Hidalgo",
    label: "Acuamanala de Miguel Hidalgo",
  },
  {
    value: "Natívitas",
    label: "Natívitas",
  },
  {
    value: "Panotla",
    label: "Panotla",
  },
  {
    value: "San Pablo del Monte",
    label: "San Pablo del Monte",
  },
  {
    value: "Santa Cruz Tlaxcala",
    label: "Santa Cruz Tlaxcala",
  },
  {
    value: "Tenancingo",
    label: "Tenancingo",
  },
  {
    value: "Teolocholco",
    label: "Teolocholco",
  },
  {
    value: "Tepeyanco",
    label: "Tepeyanco",
  },
  {
    value: "Terrenate",
    label: "Terrenate",
  },
  {
    value: "Tetla de la Solidaridad",
    label: "Tetla de la Solidaridad",
  },
  {
    value: "Tetlatlahuca",
    label: "Tetlatlahuca",
  },
  {
    value: "Tlaxcala",
    label: "Tlaxcala",
  },
  {
    value: "Tlaxco",
    label: "Tlaxco",
  },
  {
    value: "Tocatlán",
    label: "Tocatlán",
  },
  {
    value: "Totolac",
    label: "Totolac",
  },
  {
    value: "Ziltlaltépec de Trinidad Sánchez Santos",
    label: "Ziltlaltépec de Trinidad Sánchez Santos",
  },
  {
    value: "Tzompantepec",
    label: "Tzompantepec",
  },
  {
    value: "Xaloztoc",
    label: "Xaloztoc",
  },
  {
    value: "Xaltocan",
    label: "Xaltocan",
  },
  {
    value: "Papalotla de Xicohténcatl",
    label: "Papalotla de Xicohténcatl",
  },
  {
    value: "Xicohtzinco",
    label: "Xicohtzinco",
  },
  {
    value: "Yauhquemehcan",
    label: "Yauhquemehcan",
  },
  {
    value: "Zacatelco",
    label: "Zacatelco",
  },
  {
    value: "Benito Juárez",
    label: "Benito Juárez",
  },
  {
    value: "Emiliano Zapata",
    label: "Emiliano Zapata",
  },
  {
    value: "Lázaro Cárdenas",
    label: "Lázaro Cárdenas",
  },
  {
    value: "La Magdalena Tlaltelulco",
    label: "La Magdalena Tlaltelulco",
  },
  {
    value: "San Damián Texóloc",
    label: "San Damián Texóloc",
  },
  {
    value: "San Francisco Tetlanohcan",
    label: "San Francisco Tetlanohcan",
  },
  {
    value: "San Jerónimo Zacualpan",
    label: "San Jerónimo Zacualpan",
  },
  {
    value: "San José Teacalco",
    label: "San José Teacalco",
  },
  {
    value: "San Juan Huactzinco",
    label: "San Juan Huactzinco",
  },
  {
    value: "San Lorenzo Axocomanitla",
    label: "San Lorenzo Axocomanitla",
  },
  {
    value: "San Lucas Tecopilco",
    label: "San Lucas Tecopilco",
  },
  {
    value: "Santa Ana Nopalucan",
    label: "Santa Ana Nopalucan",
  },
  {
    value: "Santa Apolonia Teacalco",
    label: "Santa Apolonia Teacalco",
  },
  {
    value: "Santa Catarina Ayometla",
    label: "Santa Catarina Ayometla",
  },
  {
    value: "Santa Cruz Quilehtla",
    label: "Santa Cruz Quilehtla",
  },
  {
    value: "Santa Isabel Xiloxoxtla",
    label: "Santa Isabel Xiloxoxtla",
  },
];

export const Comunidades = [
  {
    idMunicipio: "Amaxac de Guerrero",
    value: "Amaxac de Guerrero",
    label: "Amaxac de Guerrero",
  },
  {
    idMunicipio: "Amaxac de Guerrero",
    value: "San Damián Tlacocalpan",
    label: "San Damián Tlacocalpan",
  },
  {
    idMunicipio: "Amaxac de Guerrero",
    value: "La Preciosa [Rancho]",
    label: "La Preciosa [Rancho]",
  },
  {
    idMunicipio: "Amaxac de Guerrero",
    value: "Amintla",
    label: "Amintla",
  },
  {
    idMunicipio: "Apetatitlán de Antonio Carvajal",
    value: "Apetatitlán",
    label: "Apetatitlán",
  },
  {
    idMunicipio: "Apetatitlán de Antonio Carvajal",
    value: "Belén Atzitzimititlán",
    label: "Belén Atzitzimititlán",
  },
  {
    idMunicipio: "Apetatitlán de Antonio Carvajal",
    value: "San Matías Tepetomatitlán",
    label: "San Matías Tepetomatitlán",
  },
  {
    idMunicipio: "Apetatitlán de Antonio Carvajal",
    value: "Tecolotla",
    label: "Tecolotla",
  },
  {
    idMunicipio: "Apetatitlán de Antonio Carvajal",
    value: "Tlatempan",
    label: "Tlatempan",
  },
  {
    idMunicipio: "Apetatitlán de Antonio Carvajal",
    value: "La Loma [Colonia]",
    label: "La Loma [Colonia]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Atlangatepec",
    label: "Atlangatepec",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Segunda Fracción de Olivares",
    label: "Segunda Fracción de Olivares",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Ex-Hacienda San José Atlanga",
    label: "Ex-Hacienda San José Atlanga",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Colonia Agrícola San Luis",
    label: "Colonia Agrícola San Luis",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San Pedro Ecatepec",
    label: "San Pedro Ecatepec",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Santa Clara Ozumba",
    label: "Santa Clara Ozumba",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Santa María Tepetzala",
    label: "Santa María Tepetzala",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San José de Santa Rosa Zacapexco",
    label: "San José de Santa Rosa Zacapexco",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Ex-Hacienda San Antonio Tepetzala",
    label: "Ex-Hacienda San Antonio Tepetzala",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Colonia Benito Juárez (Tezoyo)",
    label: "Colonia Benito Juárez (Tezoyo)",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Tlalmimilolpan",
    label: "Tlalmimilolpan",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Trasquila",
    label: "La Trasquila",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Santiago Villalta",
    label: "Santiago Villalta",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Vista Hermosa",
    label: "Vista Hermosa",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Ex-Hacienda de Zocac",
    label: "Ex-Hacienda de Zocac",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Zumpango",
    label: "Zumpango",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San José de Santa Rosa",
    label: "San José de Santa Rosa",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Paraíso",
    label: "El Paraíso",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Cañada",
    label: "La Cañada",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Cerro de las Flores",
    label: "Cerro de las Flores",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Ex-Hacienda la Trasquila",
    label: "Ex-Hacienda la Trasquila",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Ocotal",
    label: "El Ocotal",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Santa Elena",
    label: "Santa Elena",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Tenopala [Rancho]",
    label: "Tenopala [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Villa de las Flores",
    label: "Villa de las Flores",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Colonia Loma Bonita",
    label: "Colonia Loma Bonita",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Jericó [Rancho]",
    label: "Jericó [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Guadiana [Rancho]",
    label: "Guadiana [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San Antonio Zacapexco",
    label: "San Antonio Zacapexco",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Xuxtepec",
    label: "Xuxtepec",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Flor [Rancho]",
    label: "La Flor [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Fuerte Apache [Centro Turístico]",
    label: "El Fuerte Apache [Centro Turístico]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Cuarta Fracción de Olivares",
    label: "Cuarta Fracción de Olivares",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Cuchilla (El Alcanfor)",
    label: "La Cuchilla (El Alcanfor)",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Fracción San Pedro Ecatepec",
    label: "Fracción San Pedro Ecatepec",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "José Rodríguez",
    label: "José Rodríguez",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Loma Bonita Zacapexco",
    label: "Loma Bonita Zacapexco",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Sabino Grande (El Carmen)",
    label: "Sabino Grande (El Carmen)",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Mauro Vázquez",
    label: "Mauro Vázquez",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Villa Quinta Olivares [Centro Vacacional]",
    label: "Villa Quinta Olivares [Centro Vacacional]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Xalnene [Rancho]",
    label: "Xalnene [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Herradura [Rancho]",
    label: "La Herradura [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San Felipe [Rancho]",
    label: "San Felipe [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Los Patos [Rancho]",
    label: "Los Patos [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Zacapexco (La Soledad)",
    label: "Zacapexco (La Soledad)",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Tepitzilapa",
    label: "Tepitzilapa",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Tercera Fracción de Olivares",
    label: "Tercera Fracción de Olivares",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Troje",
    label: "La Troje",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Casa Roja",
    label: "La Casa Roja",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Loma (El Cerrito)",
    label: "La Loma (El Cerrito)",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Cañada [Rancho]",
    label: "La Cañada [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Catorce",
    label: "El Catorce",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Francisco Espinoza Elizalde",
    label: "Francisco Espinoza Elizalde",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San Isidro la Gasca",
    label: "San Isidro la Gasca",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Huérfano",
    label: "El Huérfano",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San Marcos",
    label: "San Marcos",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Ocote",
    label: "El Ocote",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Recuerdo [Rancho]",
    label: "El Recuerdo [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Ranchito del Monte",
    label: "Ranchito del Monte",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Encino [Rancho]",
    label: "El Encino [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Ocote [Rancho]",
    label: "El Ocote [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Potrero [Rancho]",
    label: "El Potrero [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Palma",
    label: "La Palma",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Las Barrancas [Rancho]",
    label: "Las Barrancas [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Las Tinajas [Rancho]",
    label: "Las Tinajas [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Los Capulines [Rancho]",
    label: "Los Capulines [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Los Encinos [Rancho]",
    label: "Los Encinos [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Los Nopalitos [Rancho]",
    label: "Los Nopalitos [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San Francisco [Rancho]",
    label: "San Francisco [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Filemón Hernández",
    label: "Filemón Hernández",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San Simón",
    label: "San Simón",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Las Tinajas (La Tepozanera)",
    label: "Las Tinajas (La Tepozanera)",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Vista Hermosa",
    label: "Vista Hermosa",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Luis Echeverría [Establo Bovino]",
    label: "Luis Echeverría [Establo Bovino]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San Felipe",
    label: "San Felipe",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Atlangatepec [Gasolinera]",
    label: "Atlangatepec [Gasolinera]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Alemán",
    label: "El Alemán",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Mirador",
    label: "El Mirador",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Providencia",
    label: "La Providencia",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Samuel Orea (Rancho el Pato)",
    label: "Samuel Orea (Rancho el Pato)",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Joya",
    label: "La Joya",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Guadalupe [Rancho]",
    label: "Guadalupe [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Unidad Habitacional I",
    label: "Unidad Habitacional I",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Almeya",
    label: "Almeya",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Capricho",
    label: "El Capricho",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Colibrí",
    label: "El Colibrí",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Herradero",
    label: "El Herradero",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "La Vaca Prieta",
    label: "La Vaca Prieta",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Los Cuatro Hermanos [Rancho]",
    label: "Los Cuatro Hermanos [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San Antonio Zacapexco (San Pedro Zacapexco)",
    label: "San Antonio Zacapexco (San Pedro Zacapexco)",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Cenzontle (Los Tres Pocillos)",
    label: "El Cenzontle (Los Tres Pocillos)",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Camino Real",
    label: "Camino Real",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Las Cabañas",
    label: "Las Cabañas",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Jesús García",
    label: "Jesús García",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Villa Esperanza [Rancho]",
    label: "Villa Esperanza [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Tercera Sección de Vaivén",
    label: "Tercera Sección de Vaivén",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Alcatraces",
    label: "Alcatraces",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Finca el Cristo",
    label: "Finca el Cristo",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Los Arbolitos [Rancho]",
    label: "Los Arbolitos [Rancho]",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Pazo Da Lyz E Do Sanci",
    label: "Pazo Da Lyz E Do Sanci",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Demetrio Flores",
    label: "Demetrio Flores",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "El Embarcadero",
    label: "El Embarcadero",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Francisco Vázquez Jiménez",
    label: "Francisco Vázquez Jiménez",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Gregorio Herrera",
    label: "Gregorio Herrera",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Hortencia Palafox González",
    label: "Hortencia Palafox González",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Las Caridades",
    label: "Las Caridades",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "San Isidro",
    label: "San Isidro",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Tepizil (San Pedro)",
    label: "Tepizil (San Pedro)",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Cerro de la Cantera",
    label: "Cerro de la Cantera",
  },
  {
    idMunicipio: "Atlangatepec",
    value: "Granja Avícola Cocotla",
    label: "Granja Avícola Cocotla",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Atlzayanca",
    label: "Atlzayanca",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San Francisco Axopilco",
    label: "San Francisco Axopilco",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San José Buenavista",
    label: "San José Buenavista",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Concepción Hidalgo",
    label: "Concepción Hidalgo",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Felipe Carrillo Puerto",
    label: "Felipe Carrillo Puerto",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "La Garita",
    label: "La Garita",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Lázaro Cárdenas",
    label: "Lázaro Cárdenas",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Mesa Redonda",
    label: "Mesa Redonda",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Miahuapan el Bajo",
    label: "Miahuapan el Bajo",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Nexnopala",
    label: "Nexnopala",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Ocotla [Rancho]",
    label: "Ocotla [Rancho]",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "La Rincona",
    label: "La Rincona",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San Antonio Tecopilco",
    label: "San Antonio Tecopilco",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San José Xalasco",
    label: "San José Xalasco",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San Juan Ocotitla",
    label: "San Juan Ocotitla",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Santa Cruz Pocitos",
    label: "Santa Cruz Pocitos",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Santa María las Cuevas",
    label: "Santa María las Cuevas",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Xaltitla",
    label: "Xaltitla",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Colonia Delicias",
    label: "Colonia Delicias",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San José Pilancón",
    label: "San José Pilancón",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Rancho Blanco",
    label: "Rancho Blanco",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Guadalupe [Rancho]",
    label: "Guadalupe [Rancho]",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "El Mirador",
    label: "El Mirador",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Ranchería de Pocitos",
    label: "Ranchería de Pocitos",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Santa Martha [Rancho]",
    label: "Santa Martha [Rancho]",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Carreta",
    label: "Carreta",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Xarero Xaltitla",
    label: "Xarero Xaltitla",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Mazehualpa",
    label: "Mazehualpa",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Loma de Junguito",
    label: "Loma de Junguito",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San Miguel Franco [Ranchería]",
    label: "San Miguel Franco [Ranchería]",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Miahuapan el Alto",
    label: "Miahuapan el Alto",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Colonia Nazareth",
    label: "Colonia Nazareth",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San José Miravalle",
    label: "San José Miravalle",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "La Higuera",
    label: "La Higuera",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San Antonio Zoapila",
    label: "San Antonio Zoapila",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Santa Viviana [Rancho]",
    label: "Santa Viviana [Rancho]",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "La Soledad Loma de Ocotla [Ranchería]",
    label: "La Soledad Loma de Ocotla [Ranchería]",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "El Salto",
    label: "El Salto",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Santa Margarita [Rancho]",
    label: "Santa Margarita [Rancho]",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Amparito",
    label: "Amparito",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Los Ocalitos",
    label: "Los Ocalitos",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San José el Infinito",
    label: "San José el Infinito",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Corazón de Jesús [Rancho]",
    label: "Corazón de Jesús [Rancho]",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Aurelio Aguilar",
    label: "Aurelio Aguilar",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "José Fernández",
    label: "José Fernández",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "El Piñonal (Guadalupe el Piñonal)",
    label: "El Piñonal (Guadalupe el Piñonal)",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Rancho de Jesús",
    label: "Rancho de Jesús",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Guadalupe [Rancho]",
    label: "Guadalupe [Rancho]",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San Jerónimo Zacualpan",
    label: "San Jerónimo Zacualpan",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Techachalco",
    label: "Techachalco",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Huerta Lizet",
    label: "Huerta Lizet",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "San Miguel",
    label: "San Miguel",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Santo Domingo",
    label: "Santo Domingo",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "Marina Paredes",
    label: "Marina Paredes",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "El Desmonte",
    label: "El Desmonte",
  },
  {
    idMunicipio: "Atltzayanca",
    value: "La Lobera",
    label: "La Lobera",
  },
  {
    idMunicipio: "Apizaco",
    value: "Ciudad de Apizaco",
    label: "Ciudad de Apizaco",
  },
  {
    idMunicipio: "Apizaco",
    value: "Cerrito de Guadalupe",
    label: "Cerrito de Guadalupe",
  },
  {
    idMunicipio: "Apizaco",
    value: "Guadalupe Texcalac",
    label: "Guadalupe Texcalac",
  },
  {
    idMunicipio: "Apizaco",
    value: "Jagüey Prieto",
    label: "Jagüey Prieto",
  },
  {
    idMunicipio: "Apizaco",
    value: "José María Morelos",
    label: "José María Morelos",
  },
  {
    idMunicipio: "Apizaco",
    value: "Colonia San Isidro",
    label: "Colonia San Isidro",
  },
  {
    idMunicipio: "Apizaco",
    value: "San Luis Apizaquito",
    label: "San Luis Apizaquito",
  },
  {
    idMunicipio: "Apizaco",
    value: "Santa Anita Huiloac",
    label: "Santa Anita Huiloac",
  },
  {
    idMunicipio: "Apizaco",
    value: "Santa María Texcalac",
    label: "Santa María Texcalac",
  },
  {
    idMunicipio: "Apizaco",
    value: "Actipan",
    label: "Actipan",
  },
  {
    idMunicipio: "Apizaco",
    value: "Ejido de Santa Úrsula Zimatepec",
    label: "Ejido de Santa Úrsula Zimatepec",
  },
  {
    idMunicipio: "Apizaco",
    value: "Ejido de San Dionisio",
    label: "Ejido de San Dionisio",
  },
  {
    idMunicipio: "Apizaco",
    value: "Ejido de Xaltocan",
    label: "Ejido de Xaltocan",
  },
  {
    idMunicipio: "Apizaco",
    value: "Los Gemelos [Rancho]",
    label: "Los Gemelos [Rancho]",
  },
  {
    idMunicipio: "Apizaco",
    value: "San José Tetel (La Quebradora)",
    label: "San José Tetel (La Quebradora)",
  },
  {
    idMunicipio: "Apizaco",
    value: "Cuarta Manzana",
    label: "Cuarta Manzana",
  },
  {
    idMunicipio: "Apizaco",
    value: "Horcasitas",
    label: "Horcasitas",
  },
  {
    idMunicipio: "Apizaco",
    value: "Tlalcoyotla",
    label: "Tlalcoyotla",
  },
  {
    idMunicipio: "Apizaco",
    value: "Tochac",
    label: "Tochac",
  },
  {
    idMunicipio: "Apizaco",
    value: "Unidad Deportiva",
    label: "Unidad Deportiva",
  },
  {
    idMunicipio: "Apizaco",
    value: "Fraccionamiento Jardines de San Francisco",
    label: "Fraccionamiento Jardines de San Francisco",
  },
  {
    idMunicipio: "Apizaco",
    value: "Ejido de Santa Úrsula",
    label: "Ejido de Santa Úrsula",
  },
  {
    idMunicipio: "Apizaco",
    value: "Primera Sección",
    label: "Primera Sección",
  },
  {
    idMunicipio: "Apizaco",
    value: "La Bomba",
    label: "La Bomba",
  },
  {
    idMunicipio: "Apizaco",
    value: "San Patricio [Rancho]",
    label: "San Patricio [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Heroica Ciudad de Calpulalpan",
    label: "Heroica Ciudad de Calpulalpan",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Amantla",
    label: "Amantla",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Coecillos",
    label: "Coecillos",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Carmen",
    label: "El Carmen",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Mazapa",
    label: "Mazapa",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Bartolomé del Monte",
    label: "San Bartolomé del Monte",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Cristóbal Zacacalco",
    label: "San Cristóbal Zacacalco",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Felipe Sultepec",
    label: "San Felipe Sultepec",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Isidro",
    label: "San Isidro",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Tres Hermanos",
    label: "Los Tres Hermanos",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Marcos Guaquilpan",
    label: "San Marcos Guaquilpan",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Mateo Aticpac",
    label: "San Mateo Aticpac",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Santa Catalina",
    label: "Santa Catalina",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Santiago Cuaula",
    label: "Santiago Cuaula",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Soledad",
    label: "La Soledad",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Santo Domingo Tequixtla [Rancho]",
    label: "Santo Domingo Tequixtla [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Tlazalán",
    label: "Tlazalán",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Zoquiapan",
    label: "Zoquiapan",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "NCPE Gustavo Díaz Ordaz",
    label: "NCPE Gustavo Díaz Ordaz",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Palacios",
    label: "Los Palacios",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Benigno [Rancho]",
    label: "San Benigno [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Juan Temazcales",
    label: "San Juan Temazcales",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Santa Cruz",
    label: "Santa Cruz",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Santa Teresa",
    label: "Santa Teresa",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Venta",
    label: "La Venta",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Capulines",
    label: "Los Capulines",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Pablo Godínez",
    label: "Pablo Godínez",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Márquez",
    label: "Los Márquez",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Sacrificio",
    label: "El Sacrificio",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Tinaja",
    label: "La Tinaja",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San José",
    label: "San José",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Zanhuanvichi (Guillermo Cano)",
    label: "Zanhuanvichi (Guillermo Cano)",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Pinos",
    label: "Los Pinos",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Herlinda Hernández",
    label: "Herlinda Hernández",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San José (Los Hornos)",
    label: "San José (Los Hornos)",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Hermanos García",
    label: "Hermanos García",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Monte de San Agustín",
    label: "Monte de San Agustín",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Carlos Morales",
    label: "Carlos Morales",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Cruz Salamanca",
    label: "Cruz Salamanca",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Ciruelo",
    label: "El Ciruelo",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Caracol",
    label: "El Caracol",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Tejocote",
    label: "El Tejocote",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Hermenegildo [Rancho]",
    label: "Hermenegildo [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Las Dalias",
    label: "Las Dalias",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Santa Félix",
    label: "Santa Félix",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Díaz",
    label: "Los Díaz",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Finca Guadalupe Velázquez",
    label: "Finca Guadalupe Velázquez",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Mogotes",
    label: "Los Mogotes",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Quebradora",
    label: "La Quebradora",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Pozo San Vicente [Restaurante]",
    label: "Pozo San Vicente [Restaurante]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "David Sánchez",
    label: "David Sánchez",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Mejía",
    label: "Los Mejía",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Cacalote",
    label: "El Cacalote",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Palma",
    label: "La Palma",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Las Auras",
    label: "Las Auras",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Paraíso",
    label: "El Paraíso",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Rosario",
    label: "El Rosario",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Blanca",
    label: "La Blanca",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Ángeles",
    label: "Los Ángeles",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Padrino",
    label: "El Padrino",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Zorrillo",
    label: "El Zorrillo",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Puente del Ocomique",
    label: "Puente del Ocomique",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Ángel García",
    label: "Ángel García",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Las Calandrias",
    label: "Las Calandrias",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Montalvo",
    label: "Los Montalvo",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Enrique Ávila",
    label: "Enrique Ávila",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Guillermo Rodríguez",
    label: "Guillermo Rodríguez",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Carmen [Rancho]",
    label: "El Carmen [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "José Gregorio García Hernández",
    label: "José Gregorio García Hernández",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Simón Mercader",
    label: "Simón Mercader",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Marcelino Cruz",
    label: "Marcelino Cruz",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Morena",
    label: "La Morena",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Loma Bonita",
    label: "Loma Bonita",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Raymundo Álvarez Guerrero",
    label: "Raymundo Álvarez Guerrero",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Analco (Jorge López)",
    label: "Analco (Jorge López)",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Rancho Bonito",
    label: "Rancho Bonito",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Rancho del Venado",
    label: "Rancho del Venado",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Isidro [Rancho]",
    label: "San Isidro [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Mirador",
    label: "El Mirador",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Tejocote",
    label: "El Tejocote",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Cerritos",
    label: "Los Cerritos",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Dos Hermanos [Rancho]",
    label: "Dos Hermanos [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Pinos",
    label: "Los Pinos",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Miguel",
    label: "San Miguel",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Vicente",
    label: "San Vicente",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Loma",
    label: "La Loma",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Sabinal",
    label: "El Sabinal",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Vicente Torres",
    label: "Vicente Torres",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Antonio (San Pedro)",
    label: "San Antonio (San Pedro)",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "José Díaz",
    label: "José Díaz",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Barranca del Tizar",
    label: "Barranca del Tizar",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Ladera",
    label: "La Ladera",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Loma de San Agustín",
    label: "Loma de San Agustín",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Guadalupe Hernández",
    label: "Guadalupe Hernández",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Ocotera [Rancho]",
    label: "La Ocotera [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Dolores",
    label: "Dolores",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Convento de la Soledad",
    label: "El Convento de la Soledad",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Ernesto Alonso",
    label: "Ernesto Alonso",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Esperanza Ortiz",
    label: "Esperanza Ortiz",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Fernando Arroyo",
    label: "Fernando Arroyo",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Rodrigo",
    label: "San Rodrigo",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "José García",
    label: "José García",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "José García Sánchez",
    label: "José García Sánchez",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Joya",
    label: "La Joya",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Mateo [Fraccionamiento]",
    label: "San Mateo [Fraccionamiento]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Las Palomas",
    label: "Las Palomas",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Pedro",
    label: "San Pedro",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Pinos",
    label: "Los Pinos",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Rodolfo Ávila",
    label: "Rodolfo Ávila",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Samuel Labastida",
    label: "Samuel Labastida",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Macondo",
    label: "Macondo",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Triángulo",
    label: "El Triángulo",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Ángel Pelcastre",
    label: "Ángel Pelcastre",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Gonzalo Cerón",
    label: "Gonzalo Cerón",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Jacarandas",
    label: "Jacarandas",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Pino",
    label: "El Pino",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Providencia",
    label: "La Providencia",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Analco Dos",
    label: "Analco Dos",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Santa Rosa",
    label: "Santa Rosa",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Cola del Potrero",
    label: "Cola del Potrero",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Pacheco (De la Rosa)",
    label: "Pacheco (De la Rosa)",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Juan de los Lagos",
    label: "San Juan de los Lagos",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Santa Isabel Mixtitlán",
    label: "Santa Isabel Mixtitlán",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Emilio López",
    label: "Emilio López",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Compadres [Rancho]",
    label: "Los Compadres [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Francisco",
    label: "San Francisco",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Piñón",
    label: "El Piñón",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Antonio Martínez",
    label: "Antonio Martínez",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Fraccionamiento Ángeles",
    label: "Fraccionamiento Ángeles",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Luis Madrid",
    label: "Luis Madrid",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Regalo [Rancho]",
    label: "El Regalo [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Rancho Escondido",
    label: "Rancho Escondido",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Pajaritos [Rancho]",
    label: "Pajaritos [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Juan [Rancho]",
    label: "San Juan [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Andrés Ahumada",
    label: "Andrés Ahumada",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Candelario Hernández",
    label: "Candelario Hernández",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Los Cerritos [Rancho]",
    label: "Los Cerritos [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Luis Hernández",
    label: "Luis Hernández",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Tejocote [Rancho]",
    label: "El Tejocote [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Las Colmenas [Rancho]",
    label: "Las Colmenas [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Alberto [Rancho]",
    label: "Alberto [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Gas Global Corporativo [Gasera]",
    label: "Gas Global Corporativo [Gasera]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "La Loma",
    label: "La Loma",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "El Jarocho",
    label: "El Jarocho",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "Las Peñas [Rancho]",
    label: "Las Peñas [Rancho]",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San José",
    label: "San José",
  },
  {
    idMunicipio: "Calpulalpan",
    value: "San Isidro",
    label: "San Isidro",
  },
  {
    idMunicipio: "El Carmen Tequexquitla",
    value: "Villa de El Carmen Tequexquitla",
    label: "Villa de El Carmen Tequexquitla",
  },
  {
    idMunicipio: "El Carmen Tequexquitla",
    value: "La Soledad",
    label: "La Soledad",
  },
  {
    idMunicipio: "El Carmen Tequexquitla",
    value: "Ocotlán Temalacayucan",
    label: "Ocotlán Temalacayucan",
  },
  {
    idMunicipio: "El Carmen Tequexquitla",
    value: "Colonia Vicente Guerrero",
    label: "Colonia Vicente Guerrero",
  },
  {
    idMunicipio: "El Carmen Tequexquitla",
    value: "Barrio de Guadalupe",
    label: "Barrio de Guadalupe",
  },
  {
    idMunicipio: "El Carmen Tequexquitla",
    value: "Sebastián García",
    label: "Sebastián García",
  },
  {
    idMunicipio: "El Carmen Tequexquitla",
    value: "La Esmeralda",
    label: "La Esmeralda",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Cuapiaxtla",
    label: "Cuapiaxtla",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "La Asunción Rascona",
    label: "La Asunción Rascona",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Egipto",
    label: "Egipto",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Colonia Ignacio Allende",
    label: "Colonia Ignacio Allende",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Izocorral",
    label: "Izocorral",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Colonia José María Morelos",
    label: "Colonia José María Morelos",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Colonia Manuel Ávila Camacho",
    label: "Colonia Manuel Ávila Camacho",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Plan de Ayala",
    label: "Plan de Ayala",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "La Providencia",
    label: "La Providencia",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "San Cristóbal del Río",
    label: "San Cristóbal del Río",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Ex-Hacienda San Francisco Cuexcomatepec",
    label: "Ex-Hacienda San Francisco Cuexcomatepec",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "San Francisco Cuexcontzi",
    label: "San Francisco Cuexcontzi",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "San Isidro Cuexcontzi",
    label: "San Isidro Cuexcontzi",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "San Felipe Teometitla",
    label: "San Felipe Teometitla",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Mazarraza [Rancho]",
    label: "Mazarraza [Rancho]",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "San Miguel Teometitla",
    label: "San Miguel Teometitla",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Santa Fe Uno",
    label: "Santa Fe Uno",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Santa María Zoapila",
    label: "Santa María Zoapila",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "El Tejocote",
    label: "El Tejocote",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Colonia Loma Bonita",
    label: "Colonia Loma Bonita",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "San Rafael Tepatlaxco (Unión y Progreso)",
    label: "San Rafael Tepatlaxco (Unión y Progreso)",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Santa Beatriz la Nueva",
    label: "Santa Beatriz la Nueva",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Santa Rebeca",
    label: "Santa Rebeca",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "San Diego Coyotepec",
    label: "San Diego Coyotepec",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Colonia del Valle",
    label: "Colonia del Valle",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Granja de Guadalupe",
    label: "Granja de Guadalupe",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Santa Fe",
    label: "Santa Fe",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Tecoac [Granja Porcina]",
    label: "Tecoac [Granja Porcina]",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Ejido de Allende",
    label: "Ejido de Allende",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "La Granja (Camino a Franco)",
    label: "La Granja (Camino a Franco)",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Palma de Tres Brazos (Fracción Santo Domingo)",
    label: "Palma de Tres Brazos (Fracción Santo Domingo)",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "La Soledad",
    label: "La Soledad",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Cerón [Rancho]",
    label: "Cerón [Rancho]",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "Isi Automotive de México [Fábrica]",
    label: "Isi Automotive de México [Fábrica]",
  },
  {
    idMunicipio: "Cuapiaxtla",
    value: "El Durazno [Vivero]",
    label: "El Durazno [Vivero]",
  },
  {
    idMunicipio: "Cuaxomulco",
    value: "Cuaxomulco",
    label: "Cuaxomulco",
  },
  {
    idMunicipio: "Cuaxomulco",
    value: "San Miguel Buenavista",
    label: "San Miguel Buenavista",
  },
  {
    idMunicipio: "Cuaxomulco",
    value: "San Lorenzo Xaltelulco",
    label: "San Lorenzo Xaltelulco",
  },
  {
    idMunicipio: "Cuaxomulco",
    value: "Zacamolpa",
    label: "Zacamolpa",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Santa Ana Chiautempan",
    label: "Santa Ana Chiautempan",
  },
  {
    idMunicipio: "Chiautempan",
    value: "San Pedro Muñoztla",
    label: "San Pedro Muñoztla",
  },
  {
    idMunicipio: "Chiautempan",
    value: "San Bartolomé Cuahuixmatlac",
    label: "San Bartolomé Cuahuixmatlac",
  },
  {
    idMunicipio: "Chiautempan",
    value: "San Rafael Tepatlaxco",
    label: "San Rafael Tepatlaxco",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Santa Cruz Tetela",
    label: "Santa Cruz Tetela",
  },
  {
    idMunicipio: "Chiautempan",
    value: "San Pedro Tlalcuapan de Nicolás Bravo",
    label: "San Pedro Tlalcuapan de Nicolás Bravo",
  },
  {
    idMunicipio: "Chiautempan",
    value: "San Pedro Xochiteotla",
    label: "San Pedro Xochiteotla",
  },
  {
    idMunicipio: "Chiautempan",
    value: "San Antoñotla",
    label: "San Antoñotla",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Santa Fe Esperanza",
    label: "Santa Fe Esperanza",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Vicente Camacho (Mesa)",
    label: "Vicente Camacho (Mesa)",
  },
  {
    idMunicipio: "Chiautempan",
    value: "El Santuario",
    label: "El Santuario",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Tlalcolijia",
    label: "Tlalcolijia",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Colonia Libertad",
    label: "Colonia Libertad",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Ixcotlancuentlac",
    label: "Ixcotlancuentlac",
  },
  {
    idMunicipio: "Chiautempan",
    value: "El Monte",
    label: "El Monte",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Potrero Calzolc",
    label: "Potrero Calzolc",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Tetepetzi",
    label: "Tetepetzi",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Tronquera (Huexotitla)",
    label: "Tronquera (Huexotitla)",
  },
  {
    idMunicipio: "Chiautempan",
    value: "La Colonia",
    label: "La Colonia",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Tonantzinco",
    label: "Tonantzinco",
  },
  {
    idMunicipio: "Chiautempan",
    value: "Ojocotla",
    label: "Ojocotla",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Muñoz",
    label: "Muñoz",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Los Álamos",
    label: "Los Álamos",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "San Bartolo Cuamantzingo",
    label: "San Bartolo Cuamantzingo",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "San Isidro Chipila",
    label: "San Isidro Chipila",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Estación Guadalupe",
    label: "Estación Guadalupe",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Guadalupe Cuauhtémoc",
    label: "Guadalupe Cuauhtémoc",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "San José Cuamantzingo",
    label: "San José Cuamantzingo",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Ex-Hacienda Zacatepec",
    label: "Ex-Hacienda Zacatepec",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Ex-Hacienda de Guadalupe",
    label: "Ex-Hacienda de Guadalupe",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Los Rodríguez",
    label: "Los Rodríguez",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Ejido Muñoz",
    label: "Ejido Muñoz",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "María Fragoso",
    label: "María Fragoso",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Ejido San Martín Xaltocan",
    label: "Ejido San Martín Xaltocan",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Los Maizales [Rancho]",
    label: "Los Maizales [Rancho]",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Los Cipreses",
    label: "Los Cipreses",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Ejido de Zacatepec",
    label: "Ejido de Zacatepec",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "La Loma de Zacatepec",
    label: "La Loma de Zacatepec",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "La Bodega [Rancho]",
    label: "La Bodega [Rancho]",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "San Juan del Río [Rancho]",
    label: "San Juan del Río [Rancho]",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "Javier Zapata",
    label: "Javier Zapata",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "El Establo Dos",
    label: "El Establo Dos",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "El Sabinal [Rancho]",
    label: "El Sabinal [Rancho]",
  },
  {
    idMunicipio: "Muñoz de Domingo Arenas",
    value: "La Presa",
    label: "La Presa",
  },
  {
    idMunicipio: "Españita",
    value: "Españita",
    label: "Españita",
  },
  {
    idMunicipio: "Españita",
    value: "Álvaro Obregón",
    label: "Álvaro Obregón",
  },
  {
    idMunicipio: "Españita",
    value: "La Concepción",
    label: "La Concepción",
  },
  {
    idMunicipio: "Españita",
    value: "La Constancia",
    label: "La Constancia",
  },
  {
    idMunicipio: "Españita",
    value: "Francisco I. Madero el Viejo",
    label: "Francisco I. Madero el Viejo",
  },
  {
    idMunicipio: "Españita",
    value: "La Joya",
    label: "La Joya",
  },
  {
    idMunicipio: "Españita",
    value: "La Magdalena Cuextotitla",
    label: "La Magdalena Cuextotitla",
  },
  {
    idMunicipio: "Españita",
    value: "Miguel Aldama",
    label: "Miguel Aldama",
  },
  {
    idMunicipio: "Españita",
    value: "Las Pilas",
    label: "Las Pilas",
  },
  {
    idMunicipio: "Españita",
    value: "La Reforma",
    label: "La Reforma",
  },
  {
    idMunicipio: "Españita",
    value: "San Agustín",
    label: "San Agustín",
  },
  {
    idMunicipio: "Españita",
    value: "San Francisco Mitepec",
    label: "San Francisco Mitepec",
  },
  {
    idMunicipio: "Españita",
    value: "San Juan Mitepec",
    label: "San Juan Mitepec",
  },
  {
    idMunicipio: "Españita",
    value: "San Miguel el Piñón",
    label: "San Miguel el Piñón",
  },
  {
    idMunicipio: "Españita",
    value: "San Miguel Pipiyola",
    label: "San Miguel Pipiyola",
  },
  {
    idMunicipio: "Españita",
    value: "San Miguel Tepalca",
    label: "San Miguel Tepalca",
  },
  {
    idMunicipio: "Españita",
    value: "Vicente Guerrero",
    label: "Vicente Guerrero",
  },
  {
    idMunicipio: "Españita",
    value: "Lagunitas [Rancho]",
    label: "Lagunitas [Rancho]",
  },
  {
    idMunicipio: "Españita",
    value: "Barrio de Torres",
    label: "Barrio de Torres",
  },
  {
    idMunicipio: "Españita",
    value: "San Pablo",
    label: "San Pablo",
  },
  {
    idMunicipio: "Españita",
    value: "La Soledad [Rancho]",
    label: "La Soledad [Rancho]",
  },
  {
    idMunicipio: "Españita",
    value: "Los Capulines",
    label: "Los Capulines",
  },
  {
    idMunicipio: "Españita",
    value: "Rancho Nuevo",
    label: "Rancho Nuevo",
  },
  {
    idMunicipio: "Españita",
    value: "San José Bellavista",
    label: "San José Bellavista",
  },
  {
    idMunicipio: "Españita",
    value: "La Valentina",
    label: "La Valentina",
  },
  {
    idMunicipio: "Españita",
    value: "El Relicario",
    label: "El Relicario",
  },
  {
    idMunicipio: "Españita",
    value: "Guillén [Rancho]",
    label: "Guillén [Rancho]",
  },
  {
    idMunicipio: "Españita",
    value: "Asilo de Ancianos San Judas Tadeo",
    label: "Asilo de Ancianos San Judas Tadeo",
  },
  {
    idMunicipio: "Españita",
    value: "El Agua Santa (Las Tinas)",
    label: "El Agua Santa (Las Tinas)",
  },
  {
    idMunicipio: "Españita",
    value: "El Calicanto",
    label: "El Calicanto",
  },
  {
    idMunicipio: "Españita",
    value: "San Antonio",
    label: "San Antonio",
  },
  {
    idMunicipio: "Españita",
    value: "Lindavista",
    label: "Lindavista",
  },
  {
    idMunicipio: "Españita",
    value: "Pablo García Sánchez (Rancho Tecuicuilco)",
    label: "Pablo García Sánchez (Rancho Tecuicuilco)",
  },
  {
    idMunicipio: "Españita",
    value: "El Potrero",
    label: "El Potrero",
  },
  {
    idMunicipio: "Españita",
    value: "El Capulín",
    label: "El Capulín",
  },
  {
    idMunicipio: "Españita",
    value: "El Ocotal (Roque Sánchez)",
    label: "El Ocotal (Roque Sánchez)",
  },
  {
    idMunicipio: "Españita",
    value: "El Vergel",
    label: "El Vergel",
  },
  {
    idMunicipio: "Huamantla",
    value: "Huamantla",
    label: "Huamantla",
  },
  {
    idMunicipio: "Huamantla",
    value: "Colonia Altamira Guadalupe",
    label: "Colonia Altamira Guadalupe",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Pedro el Batán",
    label: "San Pedro el Batán",
  },
  {
    idMunicipio: "Huamantla",
    value: "Benito Juárez",
    label: "Benito Juárez",
  },
  {
    idMunicipio: "Huamantla",
    value: "Huerta de la Trinidad",
    label: "Huerta de la Trinidad",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Carmen Xalpatlahuaya",
    label: "El Carmen Xalpatlahuaya",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ex-Hacienda la Compañía",
    label: "Ex-Hacienda la Compañía",
  },
  {
    idMunicipio: "Huamantla",
    value: "La Providencia (El Conquián)",
    label: "La Providencia (El Conquián)",
  },
  {
    idMunicipio: "Huamantla",
    value: "Colonia Cuauhtémoc",
    label: "Colonia Cuauhtémoc",
  },
  {
    idMunicipio: "Huamantla",
    value: "Colonia Chapultepec",
    label: "Colonia Chapultepec",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ex-Hacienda de Guadalupe",
    label: "Ex-Hacienda de Guadalupe",
  },
  {
    idMunicipio: "Huamantla",
    value: "Colonia Hermenegildo Galeana",
    label: "Colonia Hermenegildo Galeana",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ignacio Zaragoza",
    label: "Ignacio Zaragoza",
  },
  {
    idMunicipio: "Huamantla",
    value: "José María Morelos",
    label: "José María Morelos",
  },
  {
    idMunicipio: "Huamantla",
    value: "Lázaro Cárdenas",
    label: "Lázaro Cárdenas",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Martín Notario",
    label: "San Martín Notario",
  },
  {
    idMunicipio: "Huamantla",
    value: "Los Pilares",
    label: "Los Pilares",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Antonio Atenco",
    label: "San Antonio Atenco",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Diego Meca",
    label: "San Diego Meca",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Francisco Tecóac [Colonia]",
    label: "San Francisco Tecóac [Colonia]",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Miguel Vista Hermosa [Rancho]",
    label: "San Miguel Vista Hermosa [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Miguel Báez",
    label: "San Miguel Báez",
  },
  {
    idMunicipio: "Huamantla",
    value: "Santa Ana Ríos",
    label: "Santa Ana Ríos",
  },
  {
    idMunicipio: "Huamantla",
    value: "Santa Clara Sotoltepec",
    label: "Santa Clara Sotoltepec",
  },
  {
    idMunicipio: "Huamantla",
    value: "NCP San José Teacalco",
    label: "NCP San José Teacalco",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ex-Hacienda Santo Domingo",
    label: "Ex-Hacienda Santo Domingo",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ranchería de Torres",
    label: "Ranchería de Torres",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ex-Hacienda San Diego Xalpatlahuaya",
    label: "Ex-Hacienda San Diego Xalpatlahuaya",
  },
  {
    idMunicipio: "Huamantla",
    value: "San José Xicohténcatl",
    label: "San José Xicohténcatl",
  },
  {
    idMunicipio: "Huamantla",
    value: "Santa María Magdalena Xonecuila",
    label: "Santa María Magdalena Xonecuila",
  },
  {
    idMunicipio: "Huamantla",
    value: "Colonia el Valle",
    label: "Colonia el Valle",
  },
  {
    idMunicipio: "Huamantla",
    value: "NCP José María Morelos",
    label: "NCP José María Morelos",
  },
  {
    idMunicipio: "Huamantla",
    value: "Colonia Licenciado Mauro Angulo",
    label: "Colonia Licenciado Mauro Angulo",
  },
  {
    idMunicipio: "Huamantla",
    value: "General Francisco Villa",
    label: "General Francisco Villa",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Cristóbal de la Laguna",
    label: "San Cristóbal de la Laguna",
  },
  {
    idMunicipio: "Huamantla",
    value: "Los Álamos [Rancho]",
    label: "Los Álamos [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Colonia Agrícola San Martín Notario",
    label: "Colonia Agrícola San Martín Notario",
  },
  {
    idMunicipio: "Huamantla",
    value: "Santiago Brito",
    label: "Santiago Brito",
  },
  {
    idMunicipio: "Huamantla",
    value: "La Lima [Ranchería]",
    label: "La Lima [Ranchería]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Cabildo [Rancho]",
    label: "Cabildo [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Colonia Francisco I. Madero la Mesa",
    label: "Colonia Francisco I. Madero la Mesa",
  },
  {
    idMunicipio: "Huamantla",
    value: "Colonia Francisco Villa Tecóac",
    label: "Colonia Francisco Villa Tecóac",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Francisco Notario",
    label: "San Francisco Notario",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Pablito [Rancho]",
    label: "San Pablito [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Colonia Acasillados San Martín Notario",
    label: "Colonia Acasillados San Martín Notario",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ex-Hacienda Santa Bárbara",
    label: "Ex-Hacienda Santa Bárbara",
  },
  {
    idMunicipio: "Huamantla",
    value: "Colonia Francisco I. Madero Tecóac",
    label: "Colonia Francisco I. Madero Tecóac",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Sueño",
    label: "El Sueño",
  },
  {
    idMunicipio: "Huamantla",
    value: "Santa Margarita",
    label: "Santa Margarita",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Diego Baquedano",
    label: "San Diego Baquedano",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Molino [Ranchería]",
    label: "El Molino [Ranchería]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Establo Tecopilco",
    label: "Establo Tecopilco",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ampliación Felipe Carrillo Puerto",
    label: "Ampliación Felipe Carrillo Puerto",
  },
  {
    idMunicipio: "Huamantla",
    value: "Maricarmen [Rancho]",
    label: "Maricarmen [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Rosario",
    label: "El Rosario",
  },
  {
    idMunicipio: "Huamantla",
    value: "Fracción del Rosario",
    label: "Fracción del Rosario",
  },
  {
    idMunicipio: "Huamantla",
    value: "Las Bugambilias",
    label: "Las Bugambilias",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ejido Yancuitlalpan",
    label: "Ejido Yancuitlalpan",
  },
  {
    idMunicipio: "Huamantla",
    value: "Rosendo Loaiza Flores",
    label: "Rosendo Loaiza Flores",
  },
  {
    idMunicipio: "Huamantla",
    value: "Fracción la Natividad",
    label: "Fracción la Natividad",
  },
  {
    idMunicipio: "Huamantla",
    value: "Cerro Alto",
    label: "Cerro Alto",
  },
  {
    idMunicipio: "Huamantla",
    value: "La Cruz [Rancho]",
    label: "La Cruz [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Diego Xalpatlahuaya",
    label: "San Diego Xalpatlahuaya",
  },
  {
    idMunicipio: "Huamantla",
    value: "Francisco Hernández",
    label: "Francisco Hernández",
  },
  {
    idMunicipio: "Huamantla",
    value: "Cornelio Márquez",
    label: "Cornelio Márquez",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ricardo Flores Magón [Deshidratadora de Alfalfa]",
    label: "Ricardo Flores Magón [Deshidratadora de Alfalfa]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Jorge Aquino",
    label: "Jorge Aquino",
  },
  {
    idMunicipio: "Huamantla",
    value: "Fracción Santa Ana Ríos",
    label: "Fracción Santa Ana Ríos",
  },
  {
    idMunicipio: "Huamantla",
    value: "Fracción Xonecuila (Las Palmas)",
    label: "Fracción Xonecuila (Las Palmas)",
  },
  {
    idMunicipio: "Huamantla",
    value: "Alejandro Aguilar",
    label: "Alejandro Aguilar",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Rafael Tepatlaxco el Bajo",
    label: "San Rafael Tepatlaxco el Bajo",
  },
  {
    idMunicipio: "Huamantla",
    value: "Las Palmas",
    label: "Las Palmas",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ranchito María Guadalupe",
    label: "Ranchito María Guadalupe",
  },
  {
    idMunicipio: "Huamantla",
    value: "Santa Clara [Gasolinera]",
    label: "Santa Clara [Gasolinera]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Chapultepec [Gasolinera]",
    label: "Chapultepec [Gasolinera]",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Diego (Las Animas)",
    label: "San Diego (Las Animas)",
  },
  {
    idMunicipio: "Huamantla",
    value: "Apolinar Rodríguez",
    label: "Apolinar Rodríguez",
  },
  {
    idMunicipio: "Huamantla",
    value: "La Cabaña",
    label: "La Cabaña",
  },
  {
    idMunicipio: "Huamantla",
    value: "Candelario Sánchez",
    label: "Candelario Sánchez",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ernesto Montiel",
    label: "Ernesto Montiel",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ex-Hacienda San Bartolo",
    label: "Ex-Hacienda San Bartolo",
  },
  {
    idMunicipio: "Huamantla",
    value: "José Manuel Flores",
    label: "José Manuel Flores",
  },
  {
    idMunicipio: "Huamantla",
    value: "Fracción de Xonecuila",
    label: "Fracción de Xonecuila",
  },
  {
    idMunicipio: "Huamantla",
    value: "Rancho Bonilla [Fracción]",
    label: "Rancho Bonilla [Fracción]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ernesto Gómez",
    label: "Ernesto Gómez",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ladislao Barrientos",
    label: "Ladislao Barrientos",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Porvenir",
    label: "El Porvenir",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Mirador",
    label: "El Mirador",
  },
  {
    idMunicipio: "Huamantla",
    value: "Las Palomas",
    label: "Las Palomas",
  },
  {
    idMunicipio: "Huamantla",
    value: "La Pedrera",
    label: "La Pedrera",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Pilar",
    label: "El Pilar",
  },
  {
    idMunicipio: "Huamantla",
    value: "Los Pinos Dos",
    label: "Los Pinos Dos",
  },
  {
    idMunicipio: "Huamantla",
    value: "Los Pinos",
    label: "Los Pinos",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Durazno",
    label: "El Durazno",
  },
  {
    idMunicipio: "Huamantla",
    value: "Rancho Alegre",
    label: "Rancho Alegre",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Rebombeo",
    label: "El Rebombeo",
  },
  {
    idMunicipio: "Huamantla",
    value: "María Elena [Rancho]",
    label: "María Elena [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Los Pinos [Rancho]",
    label: "Los Pinos [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Constantino",
    label: "San Constantino",
  },
  {
    idMunicipio: "Huamantla",
    value: "Santa Bárbara",
    label: "Santa Bárbara",
  },
  {
    idMunicipio: "Huamantla",
    value: "Santa Rita",
    label: "Santa Rita",
  },
  {
    idMunicipio: "Huamantla",
    value: "Sarita de la Montaña",
    label: "Sarita de la Montaña",
  },
  {
    idMunicipio: "Huamantla",
    value: "Tirso Gutiérrez",
    label: "Tirso Gutiérrez",
  },
  {
    idMunicipio: "Huamantla",
    value: "Tubera Guadalupe",
    label: "Tubera Guadalupe",
  },
  {
    idMunicipio: "Huamantla",
    value: "José Juan Romero López",
    label: "José Juan Romero López",
  },
  {
    idMunicipio: "Huamantla",
    value: "Los Ramales",
    label: "Los Ramales",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Antonio los Pinos",
    label: "San Antonio los Pinos",
  },
  {
    idMunicipio: "Huamantla",
    value: "La Perseverancia",
    label: "La Perseverancia",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Cristo (Don Carmelo)",
    label: "El Cristo (Don Carmelo)",
  },
  {
    idMunicipio: "Huamantla",
    value: "Jorge Pioquinto",
    label: "Jorge Pioquinto",
  },
  {
    idMunicipio: "Huamantla",
    value: "La Mesita",
    label: "La Mesita",
  },
  {
    idMunicipio: "Huamantla",
    value: "Pozo Blanco (El Colectivo)",
    label: "Pozo Blanco (El Colectivo)",
  },
  {
    idMunicipio: "Huamantla",
    value: "Martín Santiago Vázquez",
    label: "Martín Santiago Vázquez",
  },
  {
    idMunicipio: "Huamantla",
    value: "Real de la Paz",
    label: "Real de la Paz",
  },
  {
    idMunicipio: "Huamantla",
    value: "Trinidad Altamirano Hernández",
    label: "Trinidad Altamirano Hernández",
  },
  {
    idMunicipio: "Huamantla",
    value: "Víctor Lima (El Establito)",
    label: "Víctor Lima (El Establito)",
  },
  {
    idMunicipio: "Huamantla",
    value: "San Mateo [Rancho]",
    label: "San Mateo [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Arenal (Pozo Número 8)",
    label: "El Arenal (Pozo Número 8)",
  },
  {
    idMunicipio: "Huamantla",
    value: "Ejido Atenco",
    label: "Ejido Atenco",
  },
  {
    idMunicipio: "Huamantla",
    value: "La Loma (Juan Flores)",
    label: "La Loma (Juan Flores)",
  },
  {
    idMunicipio: "Huamantla",
    value: "Santa Magdalena",
    label: "Santa Magdalena",
  },
  {
    idMunicipio: "Huamantla",
    value: "Santo Domingo (Las Palomas)",
    label: "Santo Domingo (Las Palomas)",
  },
  {
    idMunicipio: "Huamantla",
    value: "Bernardino Santos",
    label: "Bernardino Santos",
  },
  {
    idMunicipio: "Huamantla",
    value: "Acacio Romero Guevara",
    label: "Acacio Romero Guevara",
  },
  {
    idMunicipio: "Huamantla",
    value: "Alfredo Palafox",
    label: "Alfredo Palafox",
  },
  {
    idMunicipio: "Huamantla",
    value: "Álvaro Pio Quinto (Pozo 10)",
    label: "Álvaro Pio Quinto (Pozo 10)",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Batán Dos",
    label: "El Batán Dos",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Cheff",
    label: "El Cheff",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Desmonte",
    label: "El Desmonte",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Repartidor",
    label: "El Repartidor",
  },
  {
    idMunicipio: "Huamantla",
    value: "Guadalupe Santos",
    label: "Guadalupe Santos",
  },
  {
    idMunicipio: "Huamantla",
    value: "Javier Luna",
    label: "Javier Luna",
  },
  {
    idMunicipio: "Huamantla",
    value: "José Bautista Santos",
    label: "José Bautista Santos",
  },
  {
    idMunicipio: "Huamantla",
    value: "José Varela",
    label: "José Varela",
  },
  {
    idMunicipio: "Huamantla",
    value: "Juan Hernández",
    label: "Juan Hernández",
  },
  {
    idMunicipio: "Huamantla",
    value: "La Cañada",
    label: "La Cañada",
  },
  {
    idMunicipio: "Huamantla",
    value: "La Constanza",
    label: "La Constanza",
  },
  {
    idMunicipio: "Huamantla",
    value: "Leones de Montecristo",
    label: "Leones de Montecristo",
  },
  {
    idMunicipio: "Huamantla",
    value: "Loma Bonita",
    label: "Loma Bonita",
  },
  {
    idMunicipio: "Huamantla",
    value: "Pedro Vieyra",
    label: "Pedro Vieyra",
  },
  {
    idMunicipio: "Huamantla",
    value: "Rosa María Daza Ramos",
    label: "Rosa María Daza Ramos",
  },
  {
    idMunicipio: "Huamantla",
    value: "Uza Gas [Gasera]",
    label: "Uza Gas [Gasera]",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Gran Rey",
    label: "El Gran Rey",
  },
  {
    idMunicipio: "Huamantla",
    value: "El Huerto",
    label: "El Huerto",
  },
  {
    idMunicipio: "Huamantla",
    value: "La Caridad del Cobre [Rancho]",
    label: "La Caridad del Cobre [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Los Caballitos [Rancho]",
    label: "Los Caballitos [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Rancho Alegre",
    label: "Rancho Alegre",
  },
  {
    idMunicipio: "Huamantla",
    value: "Castillotla [Rancho]",
    label: "Castillotla [Rancho]",
  },
  {
    idMunicipio: "Huamantla",
    value: "Pozo Once",
    label: "Pozo Once",
  },
  {
    idMunicipio: "Huamantla",
    value: "Venancio Márquez",
    label: "Venancio Márquez",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Hueyotlipan",
    label: "Hueyotlipan",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "La Cruz Techalote [Colonia]",
    label: "La Cruz Techalote [Colonia]",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Ignacio Zaragoza",
    label: "Ignacio Zaragoza",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Santa María Ixcotla",
    label: "Santa María Ixcotla",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Colonia Adolfo López Mateos",
    label: "Colonia Adolfo López Mateos",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "La Purísima [Rancho]",
    label: "La Purísima [Rancho]",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "San Diego Recoba",
    label: "San Diego Recoba",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "San Andrés Cuaximala",
    label: "San Andrés Cuaximala",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "San Antonio Techalote",
    label: "San Antonio Techalote",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Hacienda San Blas Cuaxomulco",
    label: "Hacienda San Blas Cuaxomulco",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "San Fernando",
    label: "San Fernando",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "San Lorenzo Techalote",
    label: "San Lorenzo Techalote",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "San Manuel Tlalpan",
    label: "San Manuel Tlalpan",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "San Simeón Xipetzinco",
    label: "San Simeón Xipetzinco",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Santiago Tlalpan",
    label: "Santiago Tlalpan",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "El Carmen (Las Carrozas)",
    label: "El Carmen (Las Carrozas)",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Santo Tomás (El Nopal) [Rancho]",
    label: "Santo Tomás (El Nopal) [Rancho]",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Agua Limpia",
    label: "Agua Limpia",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "La Loma [Rancho]",
    label: "La Loma [Rancho]",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "NCP San Antonio Techalote",
    label: "NCP San Antonio Techalote",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Loma Bonita",
    label: "Loma Bonita",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Las Tórtolas",
    label: "Las Tórtolas",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "El Pilar [Rancho]",
    label: "El Pilar [Rancho]",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Santa Lucía",
    label: "Santa Lucía",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Emilio Sánchez Piedras",
    label: "Emilio Sánchez Piedras",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Hacienda San Fernando",
    label: "Hacienda San Fernando",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Los Cuates [Rancho]",
    label: "Los Cuates [Rancho]",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Rancho de la Cruz",
    label: "Rancho de la Cruz",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "San Sebastián (Teozopilco)",
    label: "San Sebastián (Teozopilco)",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Rincón del Valle (Las Calaveras)",
    label: "Rincón del Valle (Las Calaveras)",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Potrero Grande",
    label: "Potrero Grande",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Dos Hermanos [Rancho]",
    label: "Dos Hermanos [Rancho]",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "El Jardín",
    label: "El Jardín",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "El Resumidero",
    label: "El Resumidero",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "El Paraíso",
    label: "El Paraíso",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "Colón",
    label: "Colón",
  },
  {
    idMunicipio: "Hueyotlipan",
    value: "La Purísima Sección Dos",
    label: "La Purísima Sección Dos",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Villa Mariano Matamoros",
    label: "Villa Mariano Matamoros",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Alpotzonga de Lira y Ortega",
    label: "Alpotzonga de Lira y Ortega",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Antonio Atotonilco",
    label: "San Antonio Atotonilco",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "La Caridad Cuaxonacayo",
    label: "La Caridad Cuaxonacayo",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "El Escobillal",
    label: "El Escobillal",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Espíritu Santo",
    label: "Espíritu Santo",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Guadalupe",
    label: "Guadalupe",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Juan Nepopualco",
    label: "San Juan Nepopualco",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Gabriel Popocatla",
    label: "San Gabriel Popocatla",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Antonio Tecóac",
    label: "San Antonio Tecóac",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Antonio Tizostoc",
    label: "San Antonio Tizostoc",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Cristóbal Oxtotlapango",
    label: "San Cristóbal Oxtotlapango",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Diego Xocoyucan",
    label: "San Diego Xocoyucan",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Joaquín",
    label: "San Joaquín",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Marcos Jilotepec",
    label: "San Marcos Jilotepec",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Miguel la Presa",
    label: "San Miguel la Presa",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Nicolás",
    label: "San Nicolás",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Santa Cruz el Porvenir",
    label: "Santa Cruz el Porvenir",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Santa Inés Tecuexcomac",
    label: "Santa Inés Tecuexcomac",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Santa Justina Ecatepec",
    label: "Santa Justina Ecatepec",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Santa María",
    label: "Santa María",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Santa Rosa de Lima",
    label: "Santa Rosa de Lima",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Santiago Xochimilco",
    label: "Santiago Xochimilco",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "La Soledad",
    label: "La Soledad",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Tlapexco",
    label: "Tlapexco",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "La Trinidad Tenexyecac",
    label: "La Trinidad Tenexyecac",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "La Virgen",
    label: "La Virgen",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Las Huertas",
    label: "Las Huertas",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San José Escandona",
    label: "San José Escandona",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Alpotzonga",
    label: "Alpotzonga",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Agustín López",
    label: "Agustín López",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Aytec (Monte de los Olivos) [Rancho]",
    label: "Aytec (Monte de los Olivos) [Rancho]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Capula",
    label: "Capula",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Santo Domingo",
    label: "Santo Domingo",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "El Centenario",
    label: "El Centenario",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Miguel Ángel [Rancho]",
    label: "Miguel Ángel [Rancho]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Rafael [Rancho]",
    label: "San Rafael [Rancho]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Río Chiquito",
    label: "Río Chiquito",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "La Laboría",
    label: "La Laboría",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "El Sabinal",
    label: "El Sabinal",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "La Suiza [Rancho]",
    label: "La Suiza [Rancho]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "La Pajuela (La Herradura)",
    label: "La Pajuela (La Herradura)",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Las Coquitas [Rancho]",
    label: "Las Coquitas [Rancho]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Carlos",
    label: "San Carlos",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Las Rositas",
    label: "Las Rositas",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Mira el Sol [Rancho]",
    label: "Mira el Sol [Rancho]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Anselmo Cavita",
    label: "Anselmo Cavita",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Las Moras (Las Moradillas)",
    label: "Las Moras (Las Moradillas)",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "La Joya",
    label: "La Joya",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Fracción la Virgen",
    label: "Fracción la Virgen",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Julio Castañeda",
    label: "Julio Castañeda",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Quinta Mary",
    label: "Quinta Mary",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Refugio Ramírez",
    label: "Refugio Ramírez",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Lucas",
    label: "San Lucas",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Esperanza [Campamento]",
    label: "Esperanza [Campamento]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "La Fiscala",
    label: "La Fiscala",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "José Islas (Los Caballitos)",
    label: "José Islas (Los Caballitos)",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Los Pérez [Rancho]",
    label: "Los Pérez [Rancho]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Colonia Ejido Santa Justina Ecatepec",
    label: "Colonia Ejido Santa Justina Ecatepec",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Cristóbal",
    label: "San Cristóbal",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "La Huérfana",
    label: "La Huérfana",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "El Ranchito",
    label: "El Ranchito",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Tlapexco II [Fraccionamiento]",
    label: "Tlapexco II [Fraccionamiento]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Amador Pérez Sánchez",
    label: "Amador Pérez Sánchez",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Anselmo García Velázquez",
    label: "Anselmo García Velázquez",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Cleotilde Astorga Sánchez",
    label: "Cleotilde Astorga Sánchez",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Eulalio Segundo (San Tomás)",
    label: "Eulalio Segundo (San Tomás)",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Javier Pulido",
    label: "Javier Pulido",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "El Sabinal [Rancho]",
    label: "El Sabinal [Rancho]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Luis Ramos Ramos",
    label: "Luis Ramos Ramos",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Las Peñas",
    label: "Las Peñas",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "El Refugio",
    label: "El Refugio",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Tláloc",
    label: "Tláloc",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Las Torres",
    label: "Las Torres",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Rancho Escondido",
    label: "Rancho Escondido",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Fernando [Rancho]",
    label: "San Fernando [Rancho]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San Francisco [Colonia]",
    label: "San Francisco [Colonia]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "El Retorno [Rancho]",
    label: "El Retorno [Rancho]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "San José",
    label: "San José",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "La Venta",
    label: "La Venta",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Montecristo [Rancho]",
    label: "Montecristo [Rancho]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Efrén Morales",
    label: "Efrén Morales",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Tma SSSS Gas [Gasera]",
    label: "Tma SSSS Gas [Gasera]",
  },
  {
    idMunicipio: "Ixtacuixtla de Mariano Matamoros",
    value: "Colonia el Progreso",
    label: "Colonia el Progreso",
  },
  {
    idMunicipio: "Ixtenco",
    value: "Ixtenco",
    label: "Ixtenco",
  },
  {
    idMunicipio: "Ixtenco",
    value: "Los Álamos",
    label: "Los Álamos",
  },
  {
    idMunicipio: "Ixtenco",
    value: "Los Pilares",
    label: "Los Pilares",
  },
  {
    idMunicipio: "Ixtenco",
    value: "La Era",
    label: "La Era",
  },
  {
    idMunicipio: "Ixtenco",
    value: "Angoa Díaz [Rancho]",
    label: "Angoa Díaz [Rancho]",
  },
  {
    idMunicipio: "Ixtenco",
    value: "El Desmonte",
    label: "El Desmonte",
  },
  {
    idMunicipio: "Ixtenco",
    value: "El Encino",
    label: "El Encino",
  },
  {
    idMunicipio: "Ixtenco",
    value: "Los Sabinos",
    label: "Los Sabinos",
  },
  {
    idMunicipio: "Ixtenco",
    value: "San Juan",
    label: "San Juan",
  },
  {
    idMunicipio: "Ixtenco",
    value: "El Cerrito",
    label: "El Cerrito",
  },
  {
    idMunicipio: "Ixtenco",
    value: "Ranchería de Pilares",
    label: "Ranchería de Pilares",
  },
  {
    idMunicipio: "Mazatecochco de José María Morelos",
    value: "Mazatecochco",
    label: "Mazatecochco",
  },
  {
    idMunicipio: "Mazatecochco de José María Morelos",
    value: "Zacatecoma",
    label: "Zacatecoma",
  },
  {
    idMunicipio: "Mazatecochco de José María Morelos",
    value: "Ramos Muñoz Manzano",
    label: "Ramos Muñoz Manzano",
  },
  {
    idMunicipio: "Mazatecochco de José María Morelos",
    value: "San Juan Ixhualco",
    label: "San Juan Ixhualco",
  },
  {
    idMunicipio: "Mazatecochco de José María Morelos",
    value: "San Isidro",
    label: "San Isidro",
  },
  {
    idMunicipio: "Contla de Juan Cuamatzi",
    value: "Contla",
    label: "Contla",
  },
  {
    idMunicipio: "Contla de Juan Cuamatzi",
    value: "San Felipe Cuauhtenco",
    label: "San Felipe Cuauhtenco",
  },
  {
    idMunicipio: "Contla de Juan Cuamatzi",
    value: "Ixtlahuaca",
    label: "Ixtlahuaca",
  },
  {
    idMunicipio: "Contla de Juan Cuamatzi",
    value: "San José Aztatla",
    label: "San José Aztatla",
  },
  {
    idMunicipio: "Contla de Juan Cuamatzi",
    value: "Santa María Aquiáhuac",
    label: "Santa María Aquiáhuac",
  },
  {
    idMunicipio: "Contla de Juan Cuamatzi",
    value: "La Luz [Barrio]",
    label: "La Luz [Barrio]",
  },
  {
    idMunicipio: "Contla de Juan Cuamatzi",
    value: "Ocotlán Tepatlaxco",
    label: "Ocotlán Tepatlaxco",
  },
  {
    idMunicipio: "Contla de Juan Cuamatzi",
    value: "Capula",
    label: "Capula",
  },
  {
    idMunicipio: "Contla de Juan Cuamatzi",
    value: "Zacatepanco",
    label: "Zacatepanco",
  },
  {
    idMunicipio: "Tepetitla de Lardizábal",
    value: "Tepetitla",
    label: "Tepetitla",
  },
  {
    idMunicipio: "Tepetitla de Lardizábal",
    value: "Guadalupe Victoria",
    label: "Guadalupe Victoria",
  },
  {
    idMunicipio: "Tepetitla de Lardizábal",
    value: "San Mateo Ayecac",
    label: "San Mateo Ayecac",
  },
  {
    idMunicipio: "Tepetitla de Lardizábal",
    value: "Villa Alta",
    label: "Villa Alta",
  },
  {
    idMunicipio: "Tepetitla de Lardizábal",
    value: "Los Morelia",
    label: "Los Morelia",
  },
  {
    idMunicipio: "Tepetitla de Lardizábal",
    value: "Aurelio Ramírez Pérez",
    label: "Aurelio Ramírez Pérez",
  },
  {
    idMunicipio: "Tepetitla de Lardizábal",
    value: "Francisco Ramírez Palma",
    label: "Francisco Ramírez Palma",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Sanctórum",
    label: "Sanctórum",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Estación Contadero",
    label: "Estación Contadero",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Francisco Villa",
    label: "Francisco Villa",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Guadalupe [Rancho]",
    label: "Guadalupe [Rancho]",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "La Providencia",
    label: "La Providencia",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "San Nicolás el Grande",
    label: "San Nicolás el Grande",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Los Alpinos",
    label: "Los Alpinos",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Esquibajas",
    label: "Esquibajas",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Pozuelos",
    label: "Pozuelos",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "La Esperanza",
    label: "La Esperanza",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Santa Cruz",
    label: "Santa Cruz",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "San Leonardo Arroyo",
    label: "San Leonardo Arroyo",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Amextitla",
    label: "Amextitla",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Balconcillos",
    label: "Balconcillos",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "San Bartolito",
    label: "San Bartolito",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "San Felipe (Ejido Álvaro Obregón)",
    label: "San Felipe (Ejido Álvaro Obregón)",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "El Jagüey",
    label: "El Jagüey",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "La Garita",
    label: "La Garita",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "La Presa de Pozuelos",
    label: "La Presa de Pozuelos",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "San Nicolás (Farfán)",
    label: "San Nicolás (Farfán)",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Juan Antonio",
    label: "Juan Antonio",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Julio Roldán",
    label: "Julio Roldán",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "La Loma",
    label: "La Loma",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Las Palomas",
    label: "Las Palomas",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "San Agustín Tres Cruces",
    label: "San Agustín Tres Cruces",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Doña Evelia (Los Alpinos)",
    label: "Doña Evelia (Los Alpinos)",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Pachuquilla [Rancho]",
    label: "Pachuquilla [Rancho]",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Artemio Torres Zavala",
    label: "Artemio Torres Zavala",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Parada de Sanctórum",
    label: "Parada de Sanctórum",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "El Sabino [Rancho]",
    label: "El Sabino [Rancho]",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "La Trinidad [Rancho]",
    label: "La Trinidad [Rancho]",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Santa Cecilia [Rancho]",
    label: "Santa Cecilia [Rancho]",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Campamento Ferrocarril 2",
    label: "Campamento Ferrocarril 2",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "La Curva Dos Hermanos [Rancho]",
    label: "La Curva Dos Hermanos [Rancho]",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Presa los Llorones",
    label: "Presa los Llorones",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Cuatro Vientos",
    label: "Cuatro Vientos",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Dos Potrillos",
    label: "Dos Potrillos",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "La Victoria",
    label: "La Victoria",
  },
  {
    idMunicipio: "Sanctórum de Lázaro Cárdenas",
    value: "Santa Teresa",
    label: "Santa Teresa",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Ciudad de Nanacamilpa",
    label: "Ciudad de Nanacamilpa",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Cruz Blanca",
    label: "Cruz Blanca",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Domingo Arenas (La Calera)",
    label: "Domingo Arenas (La Calera)",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Francisco I. Madero",
    label: "Francisco I. Madero",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Ixtafiayuca",
    label: "Ixtafiayuca",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Media Caballería",
    label: "Media Caballería",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Miguel Lira y Ortega",
    label: "Miguel Lira y Ortega",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "La Obra",
    label: "La Obra",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "San Felipe Hidalgo",
    label: "San Felipe Hidalgo",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "San Isidro",
    label: "San Isidro",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "La Soledad",
    label: "La Soledad",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Tepozontitla",
    label: "Tepozontitla",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Colonia Tepuente",
    label: "Colonia Tepuente",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "La Unión",
    label: "La Unión",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Agua Santa",
    label: "Agua Santa",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Amaxac",
    label: "Amaxac",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "El Carmen [Rancho]",
    label: "El Carmen [Rancho]",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Tebarroso (El Capitán) [Rancho]",
    label: "Tebarroso (El Capitán) [Rancho]",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "San Antonio",
    label: "San Antonio",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Asunción [Rancho]",
    label: "Asunción [Rancho]",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Caja de Agua",
    label: "Caja de Agua",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Cuatro Caminos",
    label: "Cuatro Caminos",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "El Mirador",
    label: "El Mirador",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Los Rosales",
    label: "Los Rosales",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Los Delgadillo",
    label: "Los Delgadillo",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "La Candelaria",
    label: "La Candelaria",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "El Mirador",
    label: "El Mirador",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Mifive",
    label: "Mifive",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Fracción la Venta",
    label: "Fracción la Venta",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "La Conchita",
    label: "La Conchita",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Guillermo Morales",
    label: "Guillermo Morales",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Buenavista Amaxac",
    label: "Buenavista Amaxac",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Nogales [Rancho]",
    label: "Nogales [Rancho]",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Agua Escondida",
    label: "Agua Escondida",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Los Ceibos",
    label: "Los Ceibos",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Las Maravillas",
    label: "Las Maravillas",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Rosendo Hernández",
    label: "Rosendo Hernández",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Cenobio Becerra",
    label: "Cenobio Becerra",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Techolulco",
    label: "Techolulco",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "La Loma de Enmedio (Axocopa)",
    label: "La Loma de Enmedio (Axocopa)",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "José Eduardo Hernández Fernández",
    label: "José Eduardo Hernández Fernández",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Ángela García",
    label: "Ángela García",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Aureliano Guzmán Hernández",
    label: "Aureliano Guzmán Hernández",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Constantino Zavala",
    label: "Constantino Zavala",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Entronque Nanacamilpa",
    label: "Entronque Nanacamilpa",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Las Flores",
    label: "Las Flores",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Los Reyes II [Granja]",
    label: "Los Reyes II [Granja]",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "El Cristo [Rancho]",
    label: "El Cristo [Rancho]",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Javier Olvera",
    label: "Javier Olvera",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Juan León",
    label: "Juan León",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Los Lazcano",
    label: "Los Lazcano",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Leonardo Vázquez",
    label: "Leonardo Vázquez",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Mario Espejel",
    label: "Mario Espejel",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Tebarroso",
    label: "Tebarroso",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "La Venta",
    label: "La Venta",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Hermanos Robles",
    label: "Hermanos Robles",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "El Refugio del Guerrero [Rancho]",
    label: "El Refugio del Guerrero [Rancho]",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Manuel Estrada",
    label: "Manuel Estrada",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Las Manzanas",
    label: "Las Manzanas",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "La Loma de Mazapa",
    label: "La Loma de Mazapa",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Pueblo Nuevo",
    label: "Pueblo Nuevo",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Santos Lozano",
    label: "Santos Lozano",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Los Colgados",
    label: "Los Colgados",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "La Loma de Enmedio",
    label: "La Loma de Enmedio",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Maximino Arroyo",
    label: "Maximino Arroyo",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "El Ciprés",
    label: "El Ciprés",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Los Sauza",
    label: "Los Sauza",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Piedra Canteada",
    label: "Piedra Canteada",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Santa Clara (El Corte)",
    label: "Santa Clara (El Corte)",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Axocopa",
    label: "Axocopa",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "San José",
    label: "San José",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Presa de Pozuelos",
    label: "Presa de Pozuelos",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Colonia Antorchista San Cayetano",
    label: "Colonia Antorchista San Cayetano",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Colonia la Unión",
    label: "Colonia la Unión",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "San Pedro el Carmen",
    label: "San Pedro el Carmen",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "El Sifón",
    label: "El Sifón",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "La Huerta [Rancho]",
    label: "La Huerta [Rancho]",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Villas del Bosque Santa Clara",
    label: "Villas del Bosque Santa Clara",
  },
  {
    idMunicipio: "Nanacamilpa de Mariano Arista",
    value: "Margarito [Rancho]",
    label: "Margarito [Rancho]",
  },
  {
    idMunicipio: "Acuamanala de Miguel Hidalgo",
    value: "Acuamanala",
    label: "Acuamanala",
  },
  {
    idMunicipio: "Acuamanala de Miguel Hidalgo",
    value: "Concepción Chimalpa",
    label: "Concepción Chimalpa",
  },
  {
    idMunicipio: "Acuamanala de Miguel Hidalgo",
    value: "Guadalupe Hidalgo",
    label: "Guadalupe Hidalgo",
  },
  {
    idMunicipio: "Acuamanala de Miguel Hidalgo",
    value: "Olextla de Juárez",
    label: "Olextla de Juárez",
  },
  {
    idMunicipio: "Natívitas",
    value: "Natívitas",
    label: "Natívitas",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Miguel Analco",
    label: "San Miguel Analco",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Bernabé Capula",
    label: "San Bernabé Capula",
  },
  {
    idMunicipio: "Natívitas",
    value: "Santo Tomás la Concordia",
    label: "Santo Tomás la Concordia",
  },
  {
    idMunicipio: "Natívitas",
    value: "Guadalupe Victoria",
    label: "Guadalupe Victoria",
  },
  {
    idMunicipio: "Natívitas",
    value: "Jesús Tepactepec",
    label: "Jesús Tepactepec",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Antonio Teacalco",
    label: "San Antonio Teacalco",
  },
  {
    idMunicipio: "Natívitas",
    value: "San José Atoyatenco",
    label: "San José Atoyatenco",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Miguel del Milagro",
    label: "San Miguel del Milagro",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Miguel Xochitecatitla",
    label: "San Miguel Xochitecatitla",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Rafael Tenanyecac",
    label: "San Rafael Tenanyecac",
  },
  {
    idMunicipio: "Natívitas",
    value: "Santa Clara Atoyac",
    label: "Santa Clara Atoyac",
  },
  {
    idMunicipio: "Natívitas",
    value: "Santiago Michac",
    label: "Santiago Michac",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Vicente Xiloxochitla",
    label: "San Vicente Xiloxochitla",
  },
  {
    idMunicipio: "Natívitas",
    value: "Ex-Hacienda de Segura Michac",
    label: "Ex-Hacienda de Segura Michac",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Francisco Tenexyecac",
    label: "San Francisco Tenexyecac",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Juan Tepactepec",
    label: "San Juan Tepactepec",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Miguel Tlale",
    label: "San Miguel Tlale",
  },
  {
    idMunicipio: "Natívitas",
    value: "Ex-Hacienda Santa Elena",
    label: "Ex-Hacienda Santa Elena",
  },
  {
    idMunicipio: "Natívitas",
    value: "Miguel Saloma",
    label: "Miguel Saloma",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Antonio",
    label: "San Antonio",
  },
  {
    idMunicipio: "Natívitas",
    value: "Loma Verde",
    label: "Loma Verde",
  },
  {
    idMunicipio: "Natívitas",
    value: "El Rosario [Rancho]",
    label: "El Rosario [Rancho]",
  },
  {
    idMunicipio: "Natívitas",
    value: "Matamoros",
    label: "Matamoros",
  },
  {
    idMunicipio: "Natívitas",
    value: "Rutilo Vázquez",
    label: "Rutilo Vázquez",
  },
  {
    idMunicipio: "Natívitas",
    value: "NCP Santa Elena Teacalco",
    label: "NCP Santa Elena Teacalco",
  },
  {
    idMunicipio: "Natívitas",
    value: "Zanja Real",
    label: "Zanja Real",
  },
  {
    idMunicipio: "Natívitas",
    value: "Colecturía",
    label: "Colecturía",
  },
  {
    idMunicipio: "Natívitas",
    value: "La Remolacha",
    label: "La Remolacha",
  },
  {
    idMunicipio: "Natívitas",
    value: "Tepenberna",
    label: "Tepenberna",
  },
  {
    idMunicipio: "Natívitas",
    value: "La Cabaña",
    label: "La Cabaña",
  },
  {
    idMunicipio: "Natívitas",
    value: "Jardines de Tepactepec",
    label: "Jardines de Tepactepec",
  },
  {
    idMunicipio: "Natívitas",
    value: "La Zanja",
    label: "La Zanja",
  },
  {
    idMunicipio: "Natívitas",
    value: "San Isidro",
    label: "San Isidro",
  },
  {
    idMunicipio: "Natívitas",
    value: "Los Rodríguez",
    label: "Los Rodríguez",
  },
  {
    idMunicipio: "Natívitas",
    value: "Cuanopala [Rancho]",
    label: "Cuanopala [Rancho]",
  },
  {
    idMunicipio: "Panotla",
    value: "Panotla",
    label: "Panotla",
  },
  {
    idMunicipio: "Panotla",
    value: "Jesús Acatitla",
    label: "Jesús Acatitla",
  },
  {
    idMunicipio: "Panotla",
    value: "San Ambrosio Texantla",
    label: "San Ambrosio Texantla",
  },
  {
    idMunicipio: "Panotla",
    value: "San Francisco Temetzontla",
    label: "San Francisco Temetzontla",
  },
  {
    idMunicipio: "Panotla",
    value: "San Jorge Tezoquipan",
    label: "San Jorge Tezoquipan",
  },
  {
    idMunicipio: "Panotla",
    value: "San Mateo Huexoyucan",
    label: "San Mateo Huexoyucan",
  },
  {
    idMunicipio: "Panotla",
    value: "Santa Catalina Apatlahco",
    label: "Santa Catalina Apatlahco",
  },
  {
    idMunicipio: "Panotla",
    value: "Santa Cruz Techachalco",
    label: "Santa Cruz Techachalco",
  },
  {
    idMunicipio: "Panotla",
    value: "San Tadeo Huiloapan",
    label: "San Tadeo Huiloapan",
  },
  {
    idMunicipio: "Panotla",
    value: "Nixtlalpa (Colonia las Palmas)",
    label: "Nixtlalpa (Colonia las Palmas)",
  },
  {
    idMunicipio: "Panotla",
    value: "El Nopal",
    label: "El Nopal",
  },
  {
    idMunicipio: "Panotla",
    value: "Santa Martha [Rancho]",
    label: "Santa Martha [Rancho]",
  },
  {
    idMunicipio: "Panotla",
    value: "La Plana (Los Volcanes)",
    label: "La Plana (Los Volcanes)",
  },
  {
    idMunicipio: "Panotla",
    value: "Raymundo Hernández Hernández",
    label: "Raymundo Hernández Hernández",
  },
  {
    idMunicipio: "Panotla",
    value: "Chichiapas",
    label: "Chichiapas",
  },
  {
    idMunicipio: "Panotla",
    value: "Fraccionamiento la Virgen",
    label: "Fraccionamiento la Virgen",
  },
  {
    idMunicipio: "Panotla",
    value: "La Hacienda Vieja",
    label: "La Hacienda Vieja",
  },
  {
    idMunicipio: "Panotla",
    value: "Los Pocitos",
    label: "Los Pocitos",
  },
  {
    idMunicipio: "Panotla",
    value: "Camino a San Miguel",
    label: "Camino a San Miguel",
  },
  {
    idMunicipio: "Panotla",
    value: "Fraccionamiento Santa Elena",
    label: "Fraccionamiento Santa Elena",
  },
  {
    idMunicipio: "Panotla",
    value: "Unidad Habitacional II",
    label: "Unidad Habitacional II",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Ciudad de San Pablo del Monte",
    label: "Ciudad de San Pablo del Monte",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "San Isidro Buen Suceso",
    label: "San Isidro Buen Suceso",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Ex-Hacienda Guadalupe Xaltelulco",
    label: "Ex-Hacienda Guadalupe Xaltelulco",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Acopilco",
    label: "Acopilco",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Ahuashuame",
    label: "Ahuashuame",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Ocopipila",
    label: "Ocopipila",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Tetzacual",
    label: "Tetzacual",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Xahuén Vargas",
    label: "Xahuén Vargas",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Hueyocotl",
    label: "Hueyocotl",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Cacamila San Antonio",
    label: "Cacamila San Antonio",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Calaquiotli",
    label: "Calaquiotli",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Ocoilacas",
    label: "Ocoilacas",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "San José Tecosahuatla",
    label: "San José Tecosahuatla",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Xahuentepizila",
    label: "Xahuentepizila",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Llepazotla",
    label: "Llepazotla",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Moxontitla",
    label: "Moxontitla",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Papanasno",
    label: "Papanasno",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Pilazol",
    label: "Pilazol",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Rancho Escondido",
    label: "Rancho Escondido",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Sais",
    label: "Sais",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Tectepetla",
    label: "Tectepetla",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Xahuentlatempa",
    label: "Xahuentlatempa",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Tequicuentla",
    label: "Tequicuentla",
  },
  {
    idMunicipio: "San Pablo del Monte",
    value: "Tlaxtlaxcantla",
    label: "Tlaxtlaxcantla",
  },
  {
    idMunicipio: "Santa Cruz Tlaxcala",
    value: "Santa Cruz Tlaxcala",
    label: "Santa Cruz Tlaxcala",
  },
  {
    idMunicipio: "Santa Cruz Tlaxcala",
    value: "Guadalupe Tlachco",
    label: "Guadalupe Tlachco",
  },
  {
    idMunicipio: "Santa Cruz Tlaxcala",
    value: "Jesús Huitznáhuac",
    label: "Jesús Huitznáhuac",
  },
  {
    idMunicipio: "Santa Cruz Tlaxcala",
    value: "San Lucas Tlacochcalco",
    label: "San Lucas Tlacochcalco",
  },
  {
    idMunicipio: "Santa Cruz Tlaxcala",
    value: "San Miguel Contla",
    label: "San Miguel Contla",
  },
  {
    idMunicipio: "Santa Cruz Tlaxcala",
    value: "Xamistla",
    label: "Xamistla",
  },
  {
    idMunicipio: "Santa Cruz Tlaxcala",
    value: "Alejandro Peña Conde",
    label: "Alejandro Peña Conde",
  },
  {
    idMunicipio: "Santa Cruz Tlaxcala",
    value: "El Tesoro (Bautista) [Rancho]",
    label: "El Tesoro (Bautista) [Rancho]",
  },
  {
    idMunicipio: "Santa Cruz Tlaxcala",
    value: "Luis Reyes",
    label: "Luis Reyes",
  },
  {
    idMunicipio: "Santa Cruz Tlaxcala",
    value: "San Diego [Rancho]",
    label: "San Diego [Rancho]",
  },
  {
    idMunicipio: "Tenancingo",
    value: "Tenancingo",
    label: "Tenancingo",
  },
  {
    idMunicipio: "Tenancingo",
    value: "Concepción Acopilco",
    label: "Concepción Acopilco",
  },
  {
    idMunicipio: "Tenancingo",
    value: "Tepepantlalpa (Magdalena Reyes)",
    label: "Tepepantlalpa (Magdalena Reyes)",
  },
  {
    idMunicipio: "Tenancingo",
    value: "Tecoac",
    label: "Tecoac",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Teolocholco",
    label: "Teolocholco",
  },
  {
    idMunicipio: "Teolocholco",
    value: "El Carmen Aztama",
    label: "El Carmen Aztama",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Acxotla del Monte",
    label: "Acxotla del Monte",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Cuaxinca",
    label: "Cuaxinca",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Popozotzi",
    label: "Popozotzi",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Ayocalco",
    label: "Ayocalco",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Caseta Forestal Número Dos Acxotla del Monte",
    label: "Caseta Forestal Número Dos Acxotla del Monte",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Cuahtenco",
    label: "Cuahtenco",
  },
  {
    idMunicipio: "Teolocholco",
    value: "San Miguel Hacienda",
    label: "San Miguel Hacienda",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Loma de Hueyhueyitecox",
    label: "Loma de Hueyhueyitecox",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Marcoxtla",
    label: "Marcoxtla",
  },
  {
    idMunicipio: "Teolocholco",
    value: "La Vuelta",
    label: "La Vuelta",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Texalepila",
    label: "Texalepila",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Zapahua",
    label: "Zapahua",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Alailazotl",
    label: "Alailazotl",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Tepochmetlatl",
    label: "Tepochmetlatl",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Tepizila",
    label: "Tepizila",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Apachco",
    label: "Apachco",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Campamento",
    label: "Campamento",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Caseta Forestal Número Uno San Isidro",
    label: "Caseta Forestal Número Uno San Isidro",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Tlalcoxpan",
    label: "Tlalcoxpan",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Cecehuayaxtla (Cuachextlan)",
    label: "Cecehuayaxtla (Cuachextlan)",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Diegotla",
    label: "Diegotla",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Román Zitlalpopocatl",
    label: "Román Zitlalpopocatl",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Tepetumayo",
    label: "Tepetumayo",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Xaxantila",
    label: "Xaxantila",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Cuatezón",
    label: "Cuatezón",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Sesehuatlán",
    label: "Sesehuatlán",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Ixtlahuac",
    label: "Ixtlahuac",
  },
  {
    idMunicipio: "Teolocholco",
    value: "Tlacahigualol",
    label: "Tlacahigualol",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "Tepeyanco",
    label: "Tepeyanco",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "Las Águilas [Colonia]",
    label: "Las Águilas [Colonia]",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "San Cosme Atlamaxac",
    label: "San Cosme Atlamaxac",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "La Aurora",
    label: "La Aurora",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "Colonia Guerrero",
    label: "Colonia Guerrero",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "Santiago Tlacochcalco",
    label: "Santiago Tlacochcalco",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "San Pedro Xalcaltzinco",
    label: "San Pedro Xalcaltzinco",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "Armando Sánchez",
    label: "Armando Sánchez",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "Gerardo Franco",
    label: "Gerardo Franco",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "Hilario Cuatepotzo",
    label: "Hilario Cuatepotzo",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "Antorcha Campesina",
    label: "Antorcha Campesina",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "Colonia Guadalupe",
    label: "Colonia Guadalupe",
  },
  {
    idMunicipio: "Tepeyanco",
    value: "El Potrero",
    label: "El Potrero",
  },
  {
    idMunicipio: "Terrenate",
    value: "Terrenate",
    label: "Terrenate",
  },
  {
    idMunicipio: "Terrenate",
    value: "Los Ameles (Acolco)",
    label: "Los Ameles (Acolco)",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Capulín [Colonia]",
    label: "El Capulín [Colonia]",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Capulín [Rancho]",
    label: "El Capulín [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Carpintería (El Carpintero)",
    label: "Carpintería (El Carpintero)",
  },
  {
    idMunicipio: "Terrenate",
    value: "Cerrito de Guadalupe",
    label: "Cerrito de Guadalupe",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Colonia [Rancho]",
    label: "La Colonia [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Cortesco",
    label: "Cortesco",
  },
  {
    idMunicipio: "Terrenate",
    value: "Guadalupe Victoria",
    label: "Guadalupe Victoria",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Noria [Rancho]",
    label: "La Noria [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Pilancón",
    label: "El Pilancón",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Rincón [Ranchería]",
    label: "El Rincón [Ranchería]",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Rosa [Rancho]",
    label: "La Rosa [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Nicolás Bravo",
    label: "Nicolás Bravo",
  },
  {
    idMunicipio: "Terrenate",
    value: "San José Villarreal",
    label: "San José Villarreal",
  },
  {
    idMunicipio: "Terrenate",
    value: "Tenexac",
    label: "Tenexac",
  },
  {
    idMunicipio: "Terrenate",
    value: "Tepeyahualco [Rancho]",
    label: "Tepeyahualco [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Tlalmotoca",
    label: "Tlalmotoca",
  },
  {
    idMunicipio: "Terrenate",
    value: "Toluca de Guadalupe",
    label: "Toluca de Guadalupe",
  },
  {
    idMunicipio: "Terrenate",
    value: "Comunidad Cuatro Caminos (El Rosario)",
    label: "Comunidad Cuatro Caminos (El Rosario)",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Mesa (La Cumbre) [Rancho]",
    label: "La Mesa (La Cumbre) [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Presa [Rancho]",
    label: "La Presa [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "San Juan Villarreal [Rancho]",
    label: "San Juan Villarreal [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Santa Ana [Rancho]",
    label: "Santa Ana [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Tlacaxolo",
    label: "Tlacaxolo",
  },
  {
    idMunicipio: "Terrenate",
    value: "Rancho Viejo",
    label: "Rancho Viejo",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Candelaria [Rancho]",
    label: "La Candelaria [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "San Pedro de Guadalupe",
    label: "San Pedro de Guadalupe",
  },
  {
    idMunicipio: "Terrenate",
    value: "Puerta Colorada [Rancho]",
    label: "Puerta Colorada [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Los Ameles [Rancho]",
    label: "Los Ameles [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "San Isidro [Rancho]",
    label: "San Isidro [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Santa Lucía (La Ascensión) [Rancho]",
    label: "Santa Lucía (La Ascensión) [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Galera",
    label: "La Galera",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Palomar [Rancho]",
    label: "El Palomar [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Capulín [Rancho]",
    label: "El Capulín [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Guadalupe [Rancho]",
    label: "Guadalupe [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Loma de Enmedio",
    label: "La Loma de Enmedio",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Pera",
    label: "La Pera",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Lobera [Rancho]",
    label: "La Lobera [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Las Sabinas [Rancho]",
    label: "Las Sabinas [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "San Nicolás Pitzocales",
    label: "San Nicolás Pitzocales",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Cañada",
    label: "La Cañada",
  },
  {
    idMunicipio: "Terrenate",
    value: "Clemente Fernández",
    label: "Clemente Fernández",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Dorado [Rancho]",
    label: "El Dorado [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Granjas SARH",
    label: "Granjas SARH",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Ocote (Las Pozas) [Rancho]",
    label: "El Ocote (Las Pozas) [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Los Capulines [Rancho]",
    label: "Los Capulines [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Mirador",
    label: "El Mirador",
  },
  {
    idMunicipio: "Terrenate",
    value: "José Melquiades Hernández Altamirano (Rancho el Barril)",
    label: "José Melquiades Hernández Altamirano (Rancho el Barril)",
  },
  {
    idMunicipio: "Terrenate",
    value: "Caja de Agua [Rancho]",
    label: "Caja de Agua [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Loma [Rancho]",
    label: "La Loma [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Las Sabinas (Rancho el Llorón)",
    label: "Las Sabinas (Rancho el Llorón)",
  },
  {
    idMunicipio: "Terrenate",
    value: "Rosendo Gómez Hernández",
    label: "Rosendo Gómez Hernández",
  },
  {
    idMunicipio: "Terrenate",
    value: "Teometitla",
    label: "Teometitla",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Edén [Rancho]",
    label: "El Edén [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Caldera",
    label: "La Caldera",
  },
  {
    idMunicipio: "Terrenate",
    value: "Cristalaco",
    label: "Cristalaco",
  },
  {
    idMunicipio: "Terrenate",
    value: "Mallórico de Fermín Altamirano (El Paraíso)",
    label: "Mallórico de Fermín Altamirano (El Paraíso)",
  },
  {
    idMunicipio: "Terrenate",
    value: "Paso Hondo",
    label: "Paso Hondo",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Cristo [Rancho]",
    label: "El Cristo [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "Cabaña del Bronco",
    label: "Cabaña del Bronco",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Llano",
    label: "El Llano",
  },
  {
    idMunicipio: "Terrenate",
    value: "Manuel Pérez Flores",
    label: "Manuel Pérez Flores",
  },
  {
    idMunicipio: "Terrenate",
    value: "Nuevo Milenio",
    label: "Nuevo Milenio",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Arracada [Rancho]",
    label: "El Arracada [Rancho]",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Ocote",
    label: "El Ocote",
  },
  {
    idMunicipio: "Terrenate",
    value: "El Sauce",
    label: "El Sauce",
  },
  {
    idMunicipio: "Terrenate",
    value: "La Estrella",
    label: "La Estrella",
  },
  {
    idMunicipio: "Terrenate",
    value: "Reforma",
    label: "Reforma",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Tetla",
    label: "Tetla",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Capulac",
    label: "Capulac",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Coaxamalucan",
    label: "Coaxamalucan",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Derramadero",
    label: "El Derramadero",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Ocote",
    label: "El Ocote",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San Bartolomé Matlalohcan",
    label: "San Bartolomé Matlalohcan",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San Francisco Atexcatzinco",
    label: "San Francisco Atexcatzinco",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San José Piedras Negras",
    label: "San José Piedras Negras",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Santa Fe la Troje",
    label: "Santa Fe la Troje",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "José María Morelos y Pavón",
    label: "José María Morelos y Pavón",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Plan de Ayala",
    label: "Plan de Ayala",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Prado",
    label: "El Prado",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San Isidro Piedras Negras",
    label: "San Isidro Piedras Negras",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Santa Rosa Mena [Colonia]",
    label: "Santa Rosa Mena [Colonia]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San Gregorio",
    label: "San Gregorio",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San Isidro",
    label: "San Isidro",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Santa Margarita",
    label: "Santa Margarita",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Los Abuelos [Rancho]",
    label: "Los Abuelos [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Ricardo Flores Magón",
    label: "Ricardo Flores Magón",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Santa Margarita",
    label: "Santa Margarita",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "La Concepción",
    label: "La Concepción",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "La Loma [Rancho]",
    label: "La Loma [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Don Rufino",
    label: "Don Rufino",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Santa María Zotoluca [Granja Porcina]",
    label: "Santa María Zotoluca [Granja Porcina]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Juan Lima Olivares",
    label: "Juan Lima Olivares",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Santa Rosa [Rancho]",
    label: "Santa Rosa [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Las Arenas",
    label: "Las Arenas",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Corazón",
    label: "El Corazón",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Ejido de Apizaquito",
    label: "Ejido de Apizaquito",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "La Escondida",
    label: "La Escondida",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Fracción el Derramadero",
    label: "Fracción el Derramadero",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Rancho de Gómez",
    label: "Rancho de Gómez",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Sacramento",
    label: "Sacramento",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "NCPE San Francisco Tepeyanco",
    label: "NCPE San Francisco Tepeyanco",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San Miguel [Rancho]",
    label: "San Miguel [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Alejandro López Ávila (Los Temontones)",
    label: "Alejandro López Ávila (Los Temontones)",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "La Avenida",
    label: "La Avenida",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Anastacio Lemus",
    label: "Anastacio Lemus",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Las Cabañas",
    label: "Las Cabañas",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Alvarado [Cartonera]",
    label: "Alvarado [Cartonera]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Argón [Cartonera]",
    label: "Argón [Cartonera]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Juan Antonio Gálvez [Cartonera]",
    label: "Juan Antonio Gálvez [Cartonera]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Buenos Aires [Rancho]",
    label: "Buenos Aires [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Cristo Rey",
    label: "Cristo Rey",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Felipe Gutiérrez",
    label: "Felipe Gutiérrez",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San Luis",
    label: "San Luis",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "La Tambora [Granja Porcina]",
    label: "La Tambora [Granja Porcina]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Guillermo Macías",
    label: "Guillermo Macías",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Rafael González",
    label: "Rafael González",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Campo David [Rancho]",
    label: "Campo David [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "La Caridad",
    label: "La Caridad",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "La Divina Providencia",
    label: "La Divina Providencia",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Grillo [Rancho]",
    label: "El Grillo [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Rodríguez [Restaurante]",
    label: "Rodríguez [Restaurante]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Cristian Báez Téllez",
    label: "Cristian Báez Téllez",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San Juan",
    label: "San Juan",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Rogelio Guerrero",
    label: "Rogelio Guerrero",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Cazador [Rancho]",
    label: "El Cazador [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Ricardo Aguirre Martínez [Cartonera]",
    label: "Ricardo Aguirre Martínez [Cartonera]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Fidel Castillo",
    label: "Fidel Castillo",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Santa Cruz [Rancho]",
    label: "Santa Cruz [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "La Loma",
    label: "La Loma",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Pedregal [Rancho]",
    label: "El Pedregal [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "La Cabaña del Indio [Rancho]",
    label: "La Cabaña del Indio [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Román Morales [Rancho]",
    label: "Román Morales [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Ahuatepec",
    label: "Ahuatepec",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Cristo Negro",
    label: "El Cristo Negro",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "María de la Luz Sánchez",
    label: "María de la Luz Sánchez",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Uriel Moreno",
    label: "Uriel Moreno",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Cuitzillo [Rancho]",
    label: "Cuitzillo [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Domingo Suárez",
    label: "Domingo Suárez",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "La Monera [Rancho]",
    label: "La Monera [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Las Cabañas del Abuelo",
    label: "Las Cabañas del Abuelo",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Xococateno",
    label: "Xococateno",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Arboledas Santa Fe",
    label: "Arboledas Santa Fe",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Braulio Noya",
    label: "Braulio Noya",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Ocote",
    label: "El Ocote",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Pedregal San Ángel",
    label: "El Pedregal San Ángel",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Solar",
    label: "El Solar",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Ernestina Báez Hernández",
    label: "Ernestina Báez Hernández",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Gabriel Méndez",
    label: "Gabriel Méndez",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Marco Antonio Dorantes Patiño",
    label: "Marco Antonio Dorantes Patiño",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Martín Piedras Hernández",
    label: "Martín Piedras Hernández",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Los Potrillos [Rancho]",
    label: "Los Potrillos [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Pakas de a Kilo [Rancho]",
    label: "Pakas de a Kilo [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San Isidro [Rancho]",
    label: "San Isidro [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Santa Teresa 3 Potrillos [Rancho]",
    label: "Santa Teresa 3 Potrillos [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Salomé Durán Mejia",
    label: "Salomé Durán Mejia",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Soni Gas [Gasera]",
    label: "Soni Gas [Gasera]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "El Retoñaje",
    label: "El Retoñaje",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Kilómetro Dos",
    label: "Kilómetro Dos",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Monte de Piedad [Rancho]",
    label: "Monte de Piedad [Rancho]",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San Judas Tadeo",
    label: "San Judas Tadeo",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "Las Mismas Mulas",
    label: "Las Mismas Mulas",
  },
  {
    idMunicipio: "Tetla de la Solidaridad",
    value: "San Juan",
    label: "San Juan",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Tetlatlahuca",
    label: "Tetlatlahuca",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "San Andrés Cuamilpa",
    label: "San Andrés Cuamilpa",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "San Bartolomé Tenango",
    label: "San Bartolomé Tenango",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Santa Ana Portales",
    label: "Santa Ana Portales",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Santa Cruz Aquiahuac",
    label: "Santa Cruz Aquiahuac",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Piedras Blancas  (La Cabaña)",
    label: "Piedras Blancas  (La Cabaña)",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Santa Cruz Capulinares",
    label: "Santa Cruz Capulinares",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Guetulio Suárez López",
    label: "Guetulio Suárez López",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Atenco",
    label: "Atenco",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Los Pinos",
    label: "Los Pinos",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "La Cachorra",
    label: "La Cachorra",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Santa Águeda",
    label: "Santa Águeda",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "El Columpio",
    label: "El Columpio",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Las Mesas",
    label: "Las Mesas",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Los Pinos",
    label: "Los Pinos",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Val'Quirico",
    label: "Val'Quirico",
  },
  {
    idMunicipio: "Tetlatlahuca",
    value: "Los Pirules",
    label: "Los Pirules",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "Tlaxcala de Xicohténcatl",
    label: "Tlaxcala de Xicohténcatl",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "Ocotlán",
    label: "Ocotlán",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "San Buenaventura Atempan",
    label: "San Buenaventura Atempan",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "San Diego Metepec",
    label: "San Diego Metepec",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "San Esteban Tizatlán",
    label: "San Esteban Tizatlán",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "San Gabriel Cuauhtla",
    label: "San Gabriel Cuauhtla",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "San Hipólito Chimalpa",
    label: "San Hipólito Chimalpa",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "San Lucas Cuauhtelulpan",
    label: "San Lucas Cuauhtelulpan",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "San Sebastián Atlahapa",
    label: "San Sebastián Atlahapa",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "Santa María Acuitlapilco",
    label: "Santa María Acuitlapilco",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "Santa María Ixtulco",
    label: "Santa María Ixtulco",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "Pueblo Heroico de la Trinidad Tepehitec",
    label: "Pueblo Heroico de la Trinidad Tepehitec",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "La Era",
    label: "La Era",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "Cruz Blanca",
    label: "Cruz Blanca",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "Potrero Grande",
    label: "Potrero Grande",
  },
  {
    idMunicipio: "Tlaxcala",
    value: "Potrero Chico (Colina del Sol)",
    label: "Potrero Chico (Colina del Sol)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Tlaxco",
    label: "Tlaxco",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Acopinalco del Peñón",
    label: "Acopinalco del Peñón",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Ex-Hacienda San Miguel Mimiahuapan",
    label: "Ex-Hacienda San Miguel Mimiahuapan",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San José Atotonilco",
    label: "San José Atotonilco",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Candelaria",
    label: "La Candelaria",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Capilla de Tepeyahualco",
    label: "Capilla de Tepeyahualco",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Capulines",
    label: "Los Capulines",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Casa Blanca",
    label: "Casa Blanca",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Cerro Azul",
    label: "Cerro Azul",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Ciénega",
    label: "La Ciénega",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Conzoquio",
    label: "Conzoquio",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Convento",
    label: "El Convento",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Corralillos",
    label: "Corralillos",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Charcos",
    label: "Los Charcos",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Antonio Chapultepec",
    label: "San Antonio Chapultepec",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Graciano Sánchez",
    label: "Graciano Sánchez",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Grullo",
    label: "El Grullo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Herradura",
    label: "La Herradura",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Antonio Huexotitla",
    label: "San Antonio Huexotitla",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Jardín",
    label: "El Jardín",
  },
  {
    idMunicipio: "Tlaxco",
    value: "José María Morelos Buenavista",
    label: "José María Morelos Buenavista",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Lagunilla",
    label: "Lagunilla",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Lomita",
    label: "La Lomita",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Magdalena Soltepec",
    label: "La Magdalena Soltepec",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Mariano Matamoros",
    label: "Mariano Matamoros",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Comisión Federal de Electricidad-La Martinica",
    label: "Comisión Federal de Electricidad-La Martinica",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Mazaquiáhuac",
    label: "Mazaquiáhuac",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Las Mesas",
    label: "Las Mesas",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Mirador",
    label: "El Mirador",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Tronconera",
    label: "La Tronconera",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Ojo de Agua",
    label: "Ojo de Agua",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Palma",
    label: "La Palma",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Paraíso",
    label: "El Paraíso",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Pardo",
    label: "El Pardo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Paz",
    label: "La Paz",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Providencia [Rancho]",
    label: "La Providencia [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Rancho Nuevo",
    label: "Rancho Nuevo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Rancho Seco",
    label: "Rancho Seco",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Rey",
    label: "El Rey",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Rosa",
    label: "La Rosa",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Rosario",
    label: "El Rosario",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Agustín [Rancho]",
    label: "San Agustín [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Antonio Quintanilla",
    label: "San Antonio Quintanilla",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Ex-Hacienda San Buenaventura",
    label: "Ex-Hacienda San Buenaventura",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Felipe Quintanilla",
    label: "San Felipe Quintanilla",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San José de las Delicias [Rancho Cinegético]",
    label: "San José de las Delicias [Rancho Cinegético]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Lorenzo Soltepec",
    label: "San Lorenzo Soltepec",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Pedro la Cueva",
    label: "San Pedro la Cueva",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa Fe",
    label: "Santa Fe",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa Rosa [Rancho]",
    label: "Santa Rosa [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa María Zotoluca",
    label: "Santa María Zotoluca",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Santísimo [Rancho]",
    label: "El Santísimo [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Estación Soltepec",
    label: "Estación Soltepec",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Ex-Hacienda San Lorenzo Soltepec",
    label: "Ex-Hacienda San Lorenzo Soltepec",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Tecomalucan",
    label: "Tecomalucan",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San José Tepeyahualco",
    label: "San José Tepeyahualco",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Ex-Hacienda San José Tepeyahualco",
    label: "Ex-Hacienda San José Tepeyahualco",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Teteles",
    label: "Los Teteles",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Tetzitzilica",
    label: "Tetzitzilica",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Toltecapa [Rancho]",
    label: "Toltecapa [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Las Tórtolas",
    label: "Las Tórtolas",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Trasquila",
    label: "La Trasquila",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Máximo Rojas Xalóstoc",
    label: "Máximo Rojas Xalóstoc",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Hacienda Xochuca",
    label: "Hacienda Xochuca",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Guadalupe Huexotitla",
    label: "Guadalupe Huexotitla",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa Rosa de Lima",
    label: "Santa Rosa de Lima",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Unión Ejidal Tierra y Libertad",
    label: "Unión Ejidal Tierra y Libertad",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Lobera",
    label: "La Lobera",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Diego Muñoz Camargo",
    label: "Diego Muñoz Camargo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Nacimiento",
    label: "El Nacimiento",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Las Palomas [Rancho]",
    label: "Las Palomas [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Diego Quintanilla",
    label: "San Diego Quintanilla",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Alta Luz",
    label: "Alta Luz",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Maguey Cenizo",
    label: "Maguey Cenizo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Al Final de la Senda",
    label: "Al Final de la Senda",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Agua Escondida",
    label: "Agua Escondida",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Carmen",
    label: "El Carmen",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Ex-Hacienda San Pedro Metla",
    label: "Ex-Hacienda San Pedro Metla",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Pato [Rancho]",
    label: "El Pato [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Titichilco",
    label: "Titichilco",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Ejido San José Atotonilco",
    label: "Ejido San José Atotonilco",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Tecorral",
    label: "El Tecorral",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Baltazar",
    label: "San Baltazar",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Colibrí [Rancho]",
    label: "El Colibrí [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Herradura",
    label: "La Herradura",
  },
  {
    idMunicipio: "Tlaxco",
    value: "NCPE la Herradura",
    label: "NCPE la Herradura",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Llanos de Teopa (Valle Escondido)",
    label: "Llanos de Teopa (Valle Escondido)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Loma de Enmedio",
    label: "Loma de Enmedio",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Las Maravillas [Rancho]",
    label: "Las Maravillas [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Matorral",
    label: "El Matorral",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Francisco Quintanilla",
    label: "San Francisco Quintanilla",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Pera",
    label: "La Pera",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Perpetuo Socorro [Rancho]",
    label: "El Perpetuo Socorro [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Rancho de la Providencia",
    label: "Rancho de la Providencia",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Juan",
    label: "San Juan",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Martín [Rancho]",
    label: "San Martín [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Ricardo",
    label: "San Ricardo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa Cruz [Rancho]",
    label: "Santa Cruz [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Sotol",
    label: "El Sotol",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Las Vigas",
    label: "Las Vigas",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Volador",
    label: "El Volador",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Zacatonal (Colonia Iturbide)",
    label: "Zacatonal (Colonia Iturbide)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Las Arandelas",
    label: "Las Arandelas",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Rueda",
    label: "La Rueda",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Portillo [Rancho]",
    label: "El Portillo [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Dos Barrancas",
    label: "Dos Barrancas",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Potrero Mazayolo [Rancho]",
    label: "El Potrero Mazayolo [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Vega",
    label: "La Vega",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Jazmín",
    label: "El Jazmín",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Potrero [Rancho]",
    label: "El Potrero [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Cuapexco",
    label: "Cuapexco",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Fresno",
    label: "El Fresno",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Soltepec (Rancho los Gemelos) [Granja Avícola]",
    label: "Soltepec (Rancho los Gemelos) [Granja Avícola]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Mateo",
    label: "San Mateo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Laureles",
    label: "Los Laureles",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Loma del Cortijo",
    label: "La Loma del Cortijo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Nuevo Quintanilla",
    label: "Nuevo Quintanilla",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Limón",
    label: "El Limón",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Cieneguilla",
    label: "La Cieneguilla",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Bonifacio Díaz",
    label: "Bonifacio Díaz",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Desierto",
    label: "El Desierto",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Las Ánimas",
    label: "Las Ánimas",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Jagüey",
    label: "El Jagüey",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Carlos Roldán",
    label: "Carlos Roldán",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Catorce",
    label: "El Catorce",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Ejido Río Blanco (El Doce)",
    label: "Ejido Río Blanco (El Doce)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Embarcadero",
    label: "El Embarcadero",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Escorpión",
    label: "El Escorpión",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Juan Antonio Burle Lomelí",
    label: "Juan Antonio Burle Lomelí",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Clemente Muñoz",
    label: "Clemente Muñoz",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Llano",
    label: "El Llano",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Valle",
    label: "El Valle",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Loma del Jardín",
    label: "La Loma del Jardín",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Mirador",
    label: "El Mirador",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Nacimiento",
    label: "El Nacimiento",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Ojo de Agua",
    label: "Ojo de Agua",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Parada del Conejo (La Cieneguilla)",
    label: "La Parada del Conejo (La Cieneguilla)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Paraje",
    label: "El Paraje",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Ingrid [Posada Alpina]",
    label: "Ingrid [Posada Alpina]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Rancho Escondido",
    label: "Rancho Escondido",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Ciruelos",
    label: "Los Ciruelos",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Serrano [Rancho]",
    label: "Los Serrano [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Pablo el Mirador",
    label: "San Pablo el Mirador",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa Cruz [Rancho]",
    label: "Santa Cruz [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa Inés [Rancho]",
    label: "Santa Inés [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Seco el Viejo [Rancho]",
    label: "Seco el Viejo [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Campo David [Rancho]",
    label: "Campo David [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Tintero",
    label: "El Tintero",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Tlaquexpa",
    label: "Tlaquexpa",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa María de El Carmen",
    label: "Santa María de El Carmen",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Aztecas [Rancho]",
    label: "Los Aztecas [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Troje",
    label: "La Troje",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Las Huertas",
    label: "Las Huertas",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Calvario",
    label: "El Calvario",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Lunada Park",
    label: "Lunada Park",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Lucía López",
    label: "Lucía López",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa María [Rancho]",
    label: "Santa María [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Agua Escondida Zotoluca",
    label: "Agua Escondida Zotoluca",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Biznaguera",
    label: "La Biznaguera",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Cruz Verde [Rancho Cinegético]",
    label: "Cruz Verde [Rancho Cinegético]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Cabaña Rivera",
    label: "Cabaña Rivera",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Chupadero",
    label: "El Chupadero",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Durazno (Media Luna)",
    label: "El Durazno (Media Luna)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Gabriel García",
    label: "Gabriel García",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Hermila González",
    label: "Hermila González",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Jorge Conde López",
    label: "Jorge Conde López",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Isidro (Media Luna)",
    label: "San Isidro (Media Luna)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Mesa del Cielo",
    label: "La Mesa del Cielo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Las Palomas",
    label: "Las Palomas",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Palma",
    label: "La Palma",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Panchos",
    label: "Los Panchos",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Metla (los Pinos)",
    label: "Metla (los Pinos)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Pedregal",
    label: "El Pedregal",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Piedra Blanca",
    label: "Piedra Blanca",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Polos",
    label: "Los Polos",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Fragoso [Rancho]",
    label: "Fragoso [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Piedad",
    label: "La Piedad",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Xochío",
    label: "El Xochío",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Tablón de Guadalupe Payuca [Rancho]",
    label: "Tablón de Guadalupe Payuca [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Potrillo",
    label: "El Potrillo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San José el Escombro",
    label: "San José el Escombro",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa Cruz Rancho Seco",
    label: "Santa Cruz Rancho Seco",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Mirador (Teresa Rivera)",
    label: "El Mirador (Teresa Rivera)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Tezoyo (La Vía)",
    label: "Tezoyo (La Vía)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Tierra Nueva",
    label: "Tierra Nueva",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Tliltepec",
    label: "Tliltepec",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Kilómetro 31",
    label: "Kilómetro 31",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Juan Arroyo (San Isidro)",
    label: "Juan Arroyo (San Isidro)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Emilio Guarneros Carrillo",
    label: "Emilio Guarneros Carrillo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "María Reyes Escobar",
    label: "María Reyes Escobar",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa Cecilia",
    label: "Santa Cecilia",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santo Tomás",
    label: "Santo Tomás",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Coyote",
    label: "El Coyote",
  },
  {
    idMunicipio: "Tlaxco",
    value: "José Fajardo Arroyo",
    label: "José Fajardo Arroyo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Antonio Rodríguez (La Rayuela)",
    label: "Antonio Rodríguez (La Rayuela)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Compadre",
    label: "El Compadre",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Marcelo Morales",
    label: "Marcelo Morales",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Nopalera",
    label: "La Nopalera",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Pinos (Israel Olvera de Jesús)",
    label: "Los Pinos (Israel Olvera de Jesús)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Ranchito Ávila",
    label: "Ranchito Ávila",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Rancho Alegre",
    label: "Rancho Alegre",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Rancho de los Domínguez",
    label: "Rancho de los Domínguez",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Tres Compadres [Rancho]",
    label: "Los Tres Compadres [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San José el Manantial (Rancho Villordo)",
    label: "San José el Manantial (Rancho Villordo)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Las Jacarandas [Rancho]",
    label: "Las Jacarandas [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "San Isidro",
    label: "San Isidro",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Casa Goyri",
    label: "Casa Goyri",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Ardilla",
    label: "La Ardilla",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Tronquera",
    label: "La Tronquera",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Totolixi",
    label: "Totolixi",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Pradera del Potro",
    label: "La Pradera del Potro",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Soledad",
    label: "La Soledad",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Olivos",
    label: "Los Olivos",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa Elena",
    label: "Santa Elena",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa María de Guadalupe",
    label: "Santa María de Guadalupe",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Loma",
    label: "La Loma",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Xicohténcatl III [Ciudad Industrial]",
    label: "Xicohténcatl III [Ciudad Industrial]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Soledad (El Carril)",
    label: "La Soledad (El Carril)",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Jacques Rivera [Rancho]",
    label: "Jacques Rivera [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Pacher [Rancho]",
    label: "Los Pacher [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Los Querubes [Rancho]",
    label: "Los Querubes [Rancho]",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Lindero",
    label: "El Lindero",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Cortijo",
    label: "El Cortijo",
  },
  {
    idMunicipio: "Tlaxco",
    value: "El Tejocote",
    label: "El Tejocote",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Nueva Loma",
    label: "La Nueva Loma",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Ponderosa",
    label: "La Ponderosa",
  },
  {
    idMunicipio: "Tlaxco",
    value: "La Providencia",
    label: "La Providencia",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Tres Potrillos",
    label: "Tres Potrillos",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Santa Cruz",
    label: "Santa Cruz",
  },
  {
    idMunicipio: "Tlaxco",
    value: "Soto",
    label: "Soto",
  },
  {
    idMunicipio: "Tocatlán",
    value: "Tocatlán",
    label: "Tocatlán",
  },
  {
    idMunicipio: "Tocatlán",
    value: "Acocotla",
    label: "Acocotla",
  },
  {
    idMunicipio: "Tocatlán",
    value: "La Cañada",
    label: "La Cañada",
  },
  {
    idMunicipio: "Tocatlán",
    value: "Santa Cruz [Rancho]",
    label: "Santa Cruz [Rancho]",
  },
  {
    idMunicipio: "Tocatlán",
    value: "El Sabinal",
    label: "El Sabinal",
  },
  {
    idMunicipio: "Totolac",
    value: "San Juan Totolac",
    label: "San Juan Totolac",
  },
  {
    idMunicipio: "Totolac",
    value: "Acxotla del Río",
    label: "Acxotla del Río",
  },
  {
    idMunicipio: "Totolac",
    value: "La Candelaria Teotlalpan",
    label: "La Candelaria Teotlalpan",
  },
  {
    idMunicipio: "Totolac",
    value: "Los Reyes Quiahuixtlán",
    label: "Los Reyes Quiahuixtlán",
  },
  {
    idMunicipio: "Totolac",
    value: "San Francisco Ocotelulco",
    label: "San Francisco Ocotelulco",
  },
  {
    idMunicipio: "Totolac",
    value: "San Miguel Tlamahuco",
    label: "San Miguel Tlamahuco",
  },
  {
    idMunicipio: "Totolac",
    value: "Santiago Tepeticpac",
    label: "Santiago Tepeticpac",
  },
  {
    idMunicipio: "Totolac",
    value: "La Trinidad Chimalpa",
    label: "La Trinidad Chimalpa",
  },
  {
    idMunicipio: "Totolac",
    value: "Cerro del Metecatzi",
    label: "Cerro del Metecatzi",
  },
  {
    idMunicipio: "Totolac",
    value: "Zaragoza",
    label: "Zaragoza",
  },
  {
    idMunicipio: "Totolac",
    value: "La Nopalera",
    label: "La Nopalera",
  },
  {
    idMunicipio: "Totolac",
    value: "El Trailero [Rancho]",
    label: "El Trailero [Rancho]",
  },
  {
    idMunicipio: "Ziltlaltépec de Trinidad Sánchez Santos",
    value: "Zitlaltépec",
    label: "Zitlaltépec",
  },
  {
    idMunicipio: "Ziltlaltépec de Trinidad Sánchez Santos",
    value: "Colonia Francisco Javier Mina",
    label: "Colonia Francisco Javier Mina",
  },
  {
    idMunicipio: "Ziltlaltépec de Trinidad Sánchez Santos",
    value: "San Juan Bautista Mier",
    label: "San Juan Bautista Mier",
  },
  {
    idMunicipio: "Ziltlaltépec de Trinidad Sánchez Santos",
    value: "Ex-Hacienda San Juan Bautista",
    label: "Ex-Hacienda San Juan Bautista",
  },
  {
    idMunicipio: "Ziltlaltépec de Trinidad Sánchez Santos",
    value: "El Lindero",
    label: "El Lindero",
  },
  {
    idMunicipio: "Ziltlaltépec de Trinidad Sánchez Santos",
    value: "San Bernardino [Rancho]",
    label: "San Bernardino [Rancho]",
  },
  {
    idMunicipio: "Ziltlaltépec de Trinidad Sánchez Santos",
    value: "Guadalupe los Capulines",
    label: "Guadalupe los Capulines",
  },
  {
    idMunicipio: "Ziltlaltépec de Trinidad Sánchez Santos",
    value: "Barranca de San Nicolás",
    label: "Barranca de San Nicolás",
  },
  {
    idMunicipio: "Ziltlaltépec de Trinidad Sánchez Santos",
    value: "Julián de la Cruz Ramírez (Rancho la Loma)",
    label: "Julián de la Cruz Ramírez (Rancho la Loma)",
  },
  {
    idMunicipio: "Ziltlaltépec de Trinidad Sánchez Santos",
    value: "Guadalupe (Ejido del Rincón Zitlaltépec) [Rancho]",
    label: "Guadalupe (Ejido del Rincón Zitlaltépec) [Rancho]",
  },
  {
    idMunicipio: "Ziltlaltépec de Trinidad Sánchez Santos",
    value: "Samvardhana Motherson Peguform [Fábrica]",
    label: "Samvardhana Motherson Peguform [Fábrica]",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "Tzompantepec",
    label: "Tzompantepec",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "San Andrés Ahuashuatepec",
    label: "San Andrés Ahuashuatepec",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "San Juan Quetzalcoapan",
    label: "San Juan Quetzalcoapan",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "San Mateo Inophil",
    label: "San Mateo Inophil",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "Xaltianquisco",
    label: "Xaltianquisco",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "Buena Vista [Rancho]",
    label: "Buena Vista [Rancho]",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "Amoltepec [Rancho]",
    label: "Amoltepec [Rancho]",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "La Providencia las Huertas [Rancho]",
    label: "La Providencia las Huertas [Rancho]",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "Cruztitla (Coyoeca) [Rancho]",
    label: "Cruztitla (Coyoeca) [Rancho]",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "El Llano",
    label: "El Llano",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "Mazapa (Rancho los Juanes)",
    label: "Mazapa (Rancho los Juanes)",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "Cuayecatl",
    label: "Cuayecatl",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "Lucas Flores",
    label: "Lucas Flores",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "Fraccionamiento el Divino Salvador",
    label: "Fraccionamiento el Divino Salvador",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "San José el Potrero",
    label: "San José el Potrero",
  },
  {
    idMunicipio: "Tzompantepec",
    value: "Simec Internacional [Fábrica]",
    label: "Simec Internacional [Fábrica]",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Xaloztoc",
    label: "Xaloztoc",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "San Pedro Tlacotepec",
    label: "San Pedro Tlacotepec",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Guadalupe Texmolac",
    label: "Guadalupe Texmolac",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Colonia Velazco",
    label: "Colonia Velazco",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Colonia Venustiano Carranza",
    label: "Colonia Venustiano Carranza",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Santa Cruz Zacatzontetla",
    label: "Santa Cruz Zacatzontetla",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Colonia José López Portillo",
    label: "Colonia José López Portillo",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "La Concepción",
    label: "La Concepción",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "La Colmena",
    label: "La Colmena",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Los Corrales",
    label: "Los Corrales",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "El Jagüey Prieto",
    label: "El Jagüey Prieto",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "San José Ocotitla",
    label: "San José Ocotitla",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Nicolás Bravo [Granja Avícola]",
    label: "Nicolás Bravo [Granja Avícola]",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Quimicho",
    label: "Quimicho",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "San Pedro [Rancho]",
    label: "San Pedro [Rancho]",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "San Isidro [Rancho]",
    label: "San Isidro [Rancho]",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Las Brisas",
    label: "Las Brisas",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "San Fernando [Rancho]",
    label: "San Fernando [Rancho]",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Florencia Sánchez Nava",
    label: "Florencia Sánchez Nava",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Celino Sánchez Conde",
    label: "Celino Sánchez Conde",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "Los Pinos [Rancho]",
    label: "Los Pinos [Rancho]",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "José Pilar [Rancho]",
    label: "José Pilar [Rancho]",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "R77 [Rancho]",
    label: "R77 [Rancho]",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "San Juan [Rancho]",
    label: "San Juan [Rancho]",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "El Señor de Chalma [Rancho]",
    label: "El Señor de Chalma [Rancho]",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "La Candelaria",
    label: "La Candelaria",
  },
  {
    idMunicipio: "Xaloztoc",
    value: "FDX [Rancho]",
    label: "FDX [Rancho]",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Xaltocan",
    label: "Xaltocan",
  },
  {
    idMunicipio: "Xaltocan",
    value: "La Ascensión Huitzcolotepec",
    label: "La Ascensión Huitzcolotepec",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Las Mesas [Colonia]",
    label: "Las Mesas [Colonia]",
  },
  {
    idMunicipio: "Xaltocan",
    value: "La Retama",
    label: "La Retama",
  },
  {
    idMunicipio: "Xaltocan",
    value: "San Simón Tlatlahuquitepec",
    label: "San Simón Tlatlahuquitepec",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Santa Bárbara Acuicuizcatepec",
    label: "Santa Bárbara Acuicuizcatepec",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Topilco de Juárez",
    label: "Topilco de Juárez",
  },
  {
    idMunicipio: "Xaltocan",
    value: "San José Texopa",
    label: "San José Texopa",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Cuatla",
    label: "Cuatla",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Atzacoalco",
    label: "Atzacoalco",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Agua Bendita",
    label: "Agua Bendita",
  },
  {
    idMunicipio: "Xaltocan",
    value: "San Joaquín",
    label: "San Joaquín",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Cinco Santos",
    label: "Cinco Santos",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Ernesto Ramírez Flores (Rancho Paraíso)",
    label: "Ernesto Ramírez Flores (Rancho Paraíso)",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Valentín Flores Hernández",
    label: "Valentín Flores Hernández",
  },
  {
    idMunicipio: "Xaltocan",
    value: "La Laguna",
    label: "La Laguna",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Nueva Ampliación Adolfo Ruiz Cortínez",
    label: "Nueva Ampliación Adolfo Ruiz Cortínez",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Nueva Ampliación Camino a Xaltocan",
    label: "Nueva Ampliación Camino a Xaltocan",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Orcasitas",
    label: "Orcasitas",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Alva [Rancho]",
    label: "Alva [Rancho]",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Zárate [Rancho]",
    label: "Zárate [Rancho]",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Roberto Durán",
    label: "Roberto Durán",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Rubén Sánchez",
    label: "Rubén Sánchez",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Cañada de Chipila",
    label: "Cañada de Chipila",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Rancho Alegre",
    label: "Rancho Alegre",
  },
  {
    idMunicipio: "Xaltocan",
    value: "San Pedro",
    label: "San Pedro",
  },
  {
    idMunicipio: "Xaltocan",
    value: "María Eugenia García (El Cerro)",
    label: "María Eugenia García (El Cerro)",
  },
  {
    idMunicipio: "Xaltocan",
    value: "San Isidro",
    label: "San Isidro",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Zozoquítla",
    label: "Zozoquítla",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Lomas de Rubí",
    label: "Lomas de Rubí",
  },
  {
    idMunicipio: "Xaltocan",
    value: "Los Arrieta [Rancho]",
    label: "Los Arrieta [Rancho]",
  },
  {
    idMunicipio: "Xaltocan",
    value: "La Tabla",
    label: "La Tabla",
  },
  {
    idMunicipio: "Xaltocan",
    value: "San Miguel la Presa",
    label: "San Miguel la Presa",
  },
  {
    idMunicipio: "Papalotla de Xicohténcatl",
    value: "Papalotla",
    label: "Papalotla",
  },
  {
    idMunicipio: "Papalotla de Xicohténcatl",
    value: "San Marcos Contla",
    label: "San Marcos Contla",
  },
  {
    idMunicipio: "Papalotla de Xicohténcatl",
    value: "El Piecito",
    label: "El Piecito",
  },
  {
    idMunicipio: "Papalotla de Xicohténcatl",
    value: "San Diego Buenavista",
    label: "San Diego Buenavista",
  },
  {
    idMunicipio: "Papalotla de Xicohténcatl",
    value: "Tepizila",
    label: "Tepizila",
  },
  {
    idMunicipio: "Papalotla de Xicohténcatl",
    value: "Xaltonac",
    label: "Xaltonac",
  },
  {
    idMunicipio: "Papalotla de Xicohténcatl",
    value: "Copachigia",
    label: "Copachigia",
  },
  {
    idMunicipio: "Papalotla de Xicohténcatl",
    value: "Zacatecoma",
    label: "Zacatecoma",
  },
  {
    idMunicipio: "Papalotla de Xicohténcatl",
    value: "El Pozo",
    label: "El Pozo",
  },
  {
    idMunicipio: "Xicohtzinco",
    value: "Xicohtzinco",
    label: "Xicohtzinco",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "San Dionisio Yauhquemehcan",
    label: "San Dionisio Yauhquemehcan",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "El Rosario Ocotoxco",
    label: "El Rosario Ocotoxco",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "San Benito Xaltocan",
    label: "San Benito Xaltocan",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "San Francisco Tlacuilohcan",
    label: "San Francisco Tlacuilohcan",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "San Lorenzo Tlacualoyan",
    label: "San Lorenzo Tlacualoyan",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "Santa María Atlihuetzian",
    label: "Santa María Atlihuetzian",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "Santa Úrsula Zimatepec",
    label: "Santa Úrsula Zimatepec",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "San José Tetel",
    label: "San José Tetel",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "Hualcaltzinco",
    label: "Hualcaltzinco",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "Cuarta Sección",
    label: "Cuarta Sección",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "Tlatelpa",
    label: "Tlatelpa",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "La Soledad (El Charco) [Rancho]",
    label: "La Soledad (El Charco) [Rancho]",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "Serafín Sánchez García",
    label: "Serafín Sánchez García",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "El Llano",
    label: "El Llano",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "Tepetexco",
    label: "Tepetexco",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "Ciudad Judicial",
    label: "Ciudad Judicial",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "El Llano Dos",
    label: "El Llano Dos",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "Las Rocas [Rancho]",
    label: "Las Rocas [Rancho]",
  },
  {
    idMunicipio: "Yauhquemehcan",
    value: "Jagüey Prieto",
    label: "Jagüey Prieto",
  },
  {
    idMunicipio: "Zacatelco",
    value: "Zacatelco",
    label: "Zacatelco",
  },
  {
    idMunicipio: "Zacatelco",
    value: "Domingo Arenas",
    label: "Domingo Arenas",
  },
  {
    idMunicipio: "Zacatelco",
    value: "Campo Dolores",
    label: "Campo Dolores",
  },
  {
    idMunicipio: "Zacatelco",
    value: "Santa Elena",
    label: "Santa Elena",
  },
  {
    idMunicipio: "Zacatelco",
    value: "Las Lamas",
    label: "Las Lamas",
  },
  {
    idMunicipio: "Zacatelco",
    value: "El Potrero",
    label: "El Potrero",
  },
  {
    idMunicipio: "Zacatelco",
    value: "Tlatelpa",
    label: "Tlatelpa",
  },
  {
    idMunicipio: "Zacatelco",
    value: "La Bolsa",
    label: "La Bolsa",
  },
  {
    idMunicipio: "Zacatelco",
    value: "Cuacualoya [Rancho]",
    label: "Cuacualoya [Rancho]",
  },
  {
    idMunicipio: "Benito Juárez",
    value: "Benito Juárez",
    label: "Benito Juárez",
  },
  {
    idMunicipio: "Benito Juárez",
    value: "Jagüey de Novillos",
    label: "Jagüey de Novillos",
  },
  {
    idMunicipio: "Benito Juárez",
    value: "Ampliación los Pinos",
    label: "Ampliación los Pinos",
  },
  {
    idMunicipio: "Benito Juárez",
    value: "Roberto Blancas",
    label: "Roberto Blancas",
  },
  {
    idMunicipio: "Benito Juárez",
    value: "La Loma",
    label: "La Loma",
  },
  {
    idMunicipio: "Benito Juárez",
    value: "Cinco Hilos",
    label: "Cinco Hilos",
  },
  {
    idMunicipio: "Benito Juárez",
    value: "Los Carlos [Rancho]",
    label: "Los Carlos [Rancho]",
  },
  {
    idMunicipio: "Benito Juárez",
    value: "Rancho Alegre (La Alborada)",
    label: "Rancho Alegre (La Alborada)",
  },
  {
    idMunicipio: "Benito Juárez",
    value: "NCP Álvaro Obregón",
    label: "NCP Álvaro Obregón",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Emiliano Zapata",
    label: "Emiliano Zapata",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Ahilitla",
    label: "Ahilitla",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "El Capulitic",
    label: "El Capulitic",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "El Ciprés",
    label: "El Ciprés",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Colonia Gustavo Díaz Ordaz",
    label: "Colonia Gustavo Díaz Ordaz",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Cuahutotol",
    label: "Cuahutotol",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Los Encinos",
    label: "Los Encinos",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Loma de Vacas",
    label: "Loma de Vacas",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "El Mirador",
    label: "El Mirador",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Atotonilco",
    label: "Atotonilco",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Coamila [Rancho]",
    label: "Coamila [Rancho]",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Cruz Verde [Rancho]",
    label: "Cruz Verde [Rancho]",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "El Epazote [Rancho]",
    label: "El Epazote [Rancho]",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Guardamonte [Rancho]",
    label: "Guardamonte [Rancho]",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Las Palomas [Rancho]",
    label: "Las Palomas [Rancho]",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Ojo de Agua [Rancho]",
    label: "Ojo de Agua [Rancho]",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "San Nicolás [Rancho]",
    label: "San Nicolás [Rancho]",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Tres Ríos [Rancho]",
    label: "Tres Ríos [Rancho]",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Vista Hermosa [Rancho]",
    label: "Vista Hermosa [Rancho]",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Tecoaquillo",
    label: "Tecoaquillo",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Tipitzila",
    label: "Tipitzila",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Tlaximaloya (La Amapola)",
    label: "Tlaximaloya (La Amapola)",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Antonio Díaz",
    label: "Antonio Díaz",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "Fracción el Durazno",
    label: "Fracción el Durazno",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "La Mancera",
    label: "La Mancera",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "El Tejocote",
    label: "El Tejocote",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "La Loma",
    label: "La Loma",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "La Venta",
    label: "La Venta",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "San Isidro [Rancho]",
    label: "San Isidro [Rancho]",
  },
  {
    idMunicipio: "Emiliano Zapata",
    value: "La Cañada",
    label: "La Cañada",
  },
  {
    idMunicipio: "Lázaro Cárdenas",
    value: "Lázaro Cárdenas",
    label: "Lázaro Cárdenas",
  },
  {
    idMunicipio: "Lázaro Cárdenas",
    value: "El Ciervo",
    label: "El Ciervo",
  },
  {
    idMunicipio: "Lázaro Cárdenas",
    value: "Cuatro Milpas",
    label: "Cuatro Milpas",
  },
  {
    idMunicipio: "Lázaro Cárdenas",
    value: "Ex-Hacienda la Laguna",
    label: "Ex-Hacienda la Laguna",
  },
  {
    idMunicipio: "Lázaro Cárdenas",
    value: "Atlixtaca [Rancho]",
    label: "Atlixtaca [Rancho]",
  },
  {
    idMunicipio: "Lázaro Cárdenas",
    value: "La Mancera [Rancho]",
    label: "La Mancera [Rancho]",
  },
  {
    idMunicipio: "Lázaro Cárdenas",
    value: "Maribel [Rancho]",
    label: "Maribel [Rancho]",
  },
  {
    idMunicipio: "Lázaro Cárdenas",
    value: "San José de la Laguna",
    label: "San José de la Laguna",
  },
  {
    idMunicipio: "Lázaro Cárdenas",
    value: "El Palomar",
    label: "El Palomar",
  },
  {
    idMunicipio: "Lázaro Cárdenas",
    value: "Rancho Escondido",
    label: "Rancho Escondido",
  },
  {
    idMunicipio: "Lázaro Cárdenas",
    value: "Víctor Carmona",
    label: "Víctor Carmona",
  },
  {
    idMunicipio: "La Magdalena Tlaltelulco",
    value: "La Magdalena Tlaltelulco",
    label: "La Magdalena Tlaltelulco",
  },
  {
    idMunicipio: "San Damián Texóloc",
    value: "San Damián Texóloc",
    label: "San Damián Texóloc",
  },
  {
    idMunicipio: "San Damián Texóloc",
    value: "Huixocola",
    label: "Huixocola",
  },
  {
    idMunicipio: "San Damián Texóloc",
    value: "Luis Echeverría Álvarez",
    label: "Luis Echeverría Álvarez",
  },
  {
    idMunicipio: "San Damián Texóloc",
    value: "Tlaxala",
    label: "Tlaxala",
  },
  {
    idMunicipio: "San Francisco Tetlanohcan",
    value: "San Francisco Tetlanohcan",
    label: "San Francisco Tetlanohcan",
  },
  {
    idMunicipio: "San Francisco Tetlanohcan",
    value: "Malintzi [Centro Vacacional]",
    label: "Malintzi [Centro Vacacional]",
  },
  {
    idMunicipio: "San Francisco Tetlanohcan",
    value: "Achichihualoya",
    label: "Achichihualoya",
  },
  {
    idMunicipio: "San Francisco Tetlanohcan",
    value: "Ex-Rancho de Guadalupe",
    label: "Ex-Rancho de Guadalupe",
  },
  {
    idMunicipio: "San Francisco Tetlanohcan",
    value: "Malintzi [Vivero]",
    label: "Malintzi [Vivero]",
  },
  {
    idMunicipio: "San Francisco Tetlanohcan",
    value: "Elizabeth Pacheco Romero",
    label: "Elizabeth Pacheco Romero",
  },
  {
    idMunicipio: "San Francisco Tetlanohcan",
    value: "San Miguel [Rancho]",
    label: "San Miguel [Rancho]",
  },
  {
    idMunicipio: "San Jerónimo Zacualpan",
    value: "San Jerónimo Zacualpan",
    label: "San Jerónimo Zacualpan",
  },
  {
    idMunicipio: "San Jerónimo Zacualpan",
    value: "La Herradura [Rancho]",
    label: "La Herradura [Rancho]",
  },
  {
    idMunicipio: "San Jerónimo Zacualpan",
    value: "Tecoac",
    label: "Tecoac",
  },
  {
    idMunicipio: "San Jerónimo Zacualpan",
    value: "Una Flor en el Pantano",
    label: "Una Flor en el Pantano",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "San José Teacalco",
    label: "San José Teacalco",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "El Encinal Dos de Mayo",
    label: "El Encinal Dos de Mayo",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "Ex-Rancho San Nicolás Cuatlapanga",
    label: "Ex-Rancho San Nicolás Cuatlapanga",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "San Rafael [Rancho]",
    label: "San Rafael [Rancho]",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "Los Pirules",
    label: "Los Pirules",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "Colonia Ecológica",
    label: "Colonia Ecológica",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "San Luis",
    label: "San Luis",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "San Pedro Mártir",
    label: "San Pedro Mártir",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "El Durazno",
    label: "El Durazno",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "Altos de Mesa",
    label: "Altos de Mesa",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "Dolores",
    label: "Dolores",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "El Tecolote [Rancho]",
    label: "El Tecolote [Rancho]",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "La Concepción",
    label: "La Concepción",
  },
  {
    idMunicipio: "San José Teacalco",
    value: "Yolican Malintzi",
    label: "Yolican Malintzi",
  },
  {
    idMunicipio: "San Juan Huactzinco",
    value: "San Juan Huactzinco",
    label: "San Juan Huactzinco",
  },
  {
    idMunicipio: "San Juan Huactzinco",
    value: "Milpa Alta",
    label: "Milpa Alta",
  },
  {
    idMunicipio: "San Juan Huactzinco",
    value: "El Cerrito Atlantepec",
    label: "El Cerrito Atlantepec",
  },
  {
    idMunicipio: "San Lorenzo Axocomanitla",
    value: "San Lorenzo Axocomanitla",
    label: "San Lorenzo Axocomanitla",
  },
  {
    idMunicipio: "San Lorenzo Axocomanitla",
    value: "El Rancho",
    label: "El Rancho",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "San Lucas Tecopilco",
    label: "San Lucas Tecopilco",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "Las Cañadas",
    label: "Las Cañadas",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "El Relicario",
    label: "El Relicario",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "San José Rosastitla",
    label: "San José Rosastitla",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "Loma Bonita",
    label: "Loma Bonita",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "Tlaxcantitla",
    label: "Tlaxcantitla",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "Toltecapa",
    label: "Toltecapa",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "Xilomantla",
    label: "Xilomantla",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "El Llano",
    label: "El Llano",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "Cauatla",
    label: "Cauatla",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "Las Cañadas",
    label: "Las Cañadas",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "Maximino (El Llano)",
    label: "Maximino (El Llano)",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "El Ranchito",
    label: "El Ranchito",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "Los Álamos",
    label: "Los Álamos",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "El Tejocote",
    label: "El Tejocote",
  },
  {
    idMunicipio: "San Lucas Tecopilco",
    value: "Los Magueyales",
    label: "Los Magueyales",
  },
  {
    idMunicipio: "Santa Ana Nopalucan",
    value: "Santa Ana Nopalucan",
    label: "Santa Ana Nopalucan",
  },
  {
    idMunicipio: "Santa Ana Nopalucan",
    value: "San Ignacio [Rancho]",
    label: "San Ignacio [Rancho]",
  },
  {
    idMunicipio: "Santa Apolonia Teacalco",
    value: "Santa Apolonia Teacalco",
    label: "Santa Apolonia Teacalco",
  },
  {
    idMunicipio: "Santa Apolonia Teacalco",
    value: "La Besana del Puente (Acuilco)",
    label: "La Besana del Puente (Acuilco)",
  },
  {
    idMunicipio: "Santa Apolonia Teacalco",
    value: "La Capilla",
    label: "La Capilla",
  },
  {
    idMunicipio: "Santa Catarina Ayometla",
    value: "Santa Catarina Ayometla",
    label: "Santa Catarina Ayometla",
  },
  {
    idMunicipio: "Santa Catarina Ayometla",
    value: "Palula",
    label: "Palula",
  },
  {
    idMunicipio: "Santa Cruz Quilehtla",
    value: "Santa Cruz Quilehtla",
    label: "Santa Cruz Quilehtla",
  },
  {
    idMunicipio: "Santa Cruz Quilehtla",
    value: "Ayometitla",
    label: "Ayometitla",
  },
  {
    idMunicipio: "Santa Cruz Quilehtla",
    value: "Potrero",
    label: "Potrero",
  },
  {
    idMunicipio: "Santa Cruz Quilehtla",
    value: "Schneider Electric México [Fábrica]",
    label: "Schneider Electric México [Fábrica]",
  },
  {
    idMunicipio: "Santa Cruz Quilehtla",
    value: "Chimalpa [Rancho]",
    label: "Chimalpa [Rancho]",
  },
  {
    idMunicipio: "Santa Isabel Xiloxoxtla",
    value: "Santa Isabel Xiloxoxtla",
    label: "Santa Isabel Xiloxoxtla",
  },
];

export const getComunidad = (municipio) => {
  const com = Comunidades.filter((comunidad) => {
    return comunidad.idMunicipio === municipio;
  });
  return com;
};