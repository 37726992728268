import React from 'react';
import Table from '../Components/Table';
import Layout from '../Components/Layout';

const Index = () => {
    return (
        <>
            <Layout>
                <div className="container mx-auto pb-20">
                    <Table />
                </div>
            </Layout>
        </>
    );
};

export default Index;