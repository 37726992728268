import React from "react";
import { Navigate, Outlet } from "react-router";
import useLogin from "../hook/useLogin";

export default function PrivateRoute(props) {
  const { token } = useLogin();
  if (token) {
    return <Outlet />;
  } else {
    return <Navigate to={"/login"} />;
  }
}
