/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
import React from "react";
import Layout from "./../Components/Layout";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "./../Components/Input";
import Select from "./../Components/Select";
import {
  HombreMujer,
  estadoCivil,
  heredo,
  herencia,
  ultimoGrado,
  vivienda,
  SINO,
  Municipios,
  getComunidad,
  viveCon,
  acudeBanco,
  alzanza,
  atencionMedica,
  ayuda,
  dispone65,
  horasTrabajo,
  inscritoPrograma,
  manejoCuentas,
  remuneracion,
  seguridadSocial,
  usaTarjeta,
  DATONUMEROS
} from "./../Utils/selectors";
import ErrorIcon from "@mui/icons-material/Error";
import { NumericFormat } from "react-number-format";
import { get } from "../service/get";
import { put } from "../service/put";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useLogin from "../hook/useLogin";
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import Swal from "sweetalert2";

const Edit = () => {
  const { token } = useLogin();
  const params = useParams();
  const [values, setvalues] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    get("denunciante-pam/" + params.id, token).then((res) => {
      setvalues(res);
    });
    return () => { };
  }, []);

  const validateSchema = Yup.object({
    familia: Yup.string().required("Requerido"),
    anonimato: Yup.string().when("familia", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    calle: Yup.string().when("anonimato", {
      is: "No",
      then: () => Yup.string().required("Requerido"),
    }),
    nombredenunciante: Yup.string().when("anonimato", {
      is: "No",
      then: () => Yup.string().required("Requerido"),
    }),
    numeroExterior: Yup.string().when("anonimato", {
      is: "No",
      then: () => Yup.string().required("Requerido"),
    }),
    // numeroInterior: Yup.string().when("anonimato", {
    //   is: "No",
    //   then: () => Yup.string().required("Requerido"),
    // }),
    // entreCalles: Yup.string().when("anonimato", {
    //   is: "No",
    //   then: () => Yup.string().required("Requerido"),
    // }),
    // colonia: Yup.string().when("anonimato", {
    //   is: "No",
    //   then: () => Yup.string().required("Requerido"),
    // }),
    // manzana: Yup.string().when("anonimato", {
    //   is: "No",
    //   then: () => Yup.string().required("Requerido"),
    // }),
    // lote: Yup.string().when("anonimato", {
    //   is: "No",
    //   then: () => Yup.string().required("Requerido"),
    // }),
    // codigoPostal: Yup.string().when("anonimato", {
    //   is: "No",
    //   then: () =>
    //     Yup.string()
    //       .required("Requerido")
    //       .matches(
    //         /^[0-9]{5}$/,
    //         "El código postal debe tener 5 dígitos y solo números"
    //       ),
    // }),
    municipio: Yup.string().when("anonimato", {
      is: "No",
      then: () => Yup.string().required("Requerido"),
    }),
    comunidad: Yup.string().when("anonimato", {
      is: "No",
      then: () => Yup.string().required("Requerido"),
    }),
    // emaildenunciante: Yup.string().when("anonimato", {
    //   is: "No",
    //   then: () =>
    //     Yup.string()
    //       .email("Inserta un correo electrónico valido")
    //       .required("Requerido"),
    // }),
    // teldenunciante: Yup.string().when("anonimato", {
    //   is: "No",
    //   then: () =>
    //     Yup.string()
    //       .required("Requerido")
    //       .matches(/^[0-9]{10}$/, "Inserta solo 10 números"),
    // }),
    // curpdenunciante: Yup.string().when("anonimato", {
    //   is: "No",
    //   then: () =>
    //     Yup.string()
    //       .min(18, "El CURP debe tener como mínimo 18 caracteres")
    //       .max(18, "El CURP debe tener como máximo 18 caracteres")
    //       .required("Requerido"),
    // }),
    pamviolencia: Yup.string().required("Requerido"),
    // callePam: Yup.string().required("Requerido"),
    // numeroExteriorPam: Yup.string().required("Requerido"),
    // numeroInteriorPam: Yup.string().required("Requerido"),
    // entreCallesPam: Yup.string().required("Requerido"),
    // coloniaPam: Yup.string().required("Requerido"),
    // manzanaPam: Yup.string().required("Requerido"),
    // lotePam: Yup.string().required("Requerido"),
    // codigoPostalPam: Yup.string()
    //   .required("Requerido")
    //   .matches(
    //     /^[0-9]{5}$/,
    //     "El código postal debe tener 5 dígitos y solo números"
    //   ),
    municipioPam: Yup.string().required("Requerido"),
    comunidadPam: Yup.string().required("Requerido"),
    // emailpamviolencia: Yup.string()
    //   .email("Inserta un correo electrónico valido")
    //   .required("Requerido"),
    // telpamviolencia: Yup.string()
    //   .required("Requerido")
    //   .matches(/^[0-9]{10}$/, "Inserta solo 10 números"),
    // curppamviolencia: Yup.string()
    //   .min(18, "El CURP debe tener como mínimo 18 caracteres")
    //   .max(18, "El CURP debe tener como máximo 18 caracteres")
    //   .required("Requerido"),
    sexo: Yup.string().required("Requerido"),
    muletasilla: Yup.string().required("Requerido"),
    edad: Yup.number()
      .required("Requerido")
      .min(60, "El/La PAM debe ser mayor de 60 años"),
    edocivil: Yup.string().required("Requerido"),
    ultimoescolar: Yup.string().required("Requerido"),
    leer: Yup.string().when("ultimoescolar", {
      is: "Ninguno",
      then: () => Yup.string().required("Requerido"),
    }),
    escribir: Yup.string().when("ultimoescolar", {
      is: "Ninguno",
      then: () => Yup.string().required("Requerido"),
    }),
    indigena: Yup.string().required("Requerido"),
    viveen: Yup.string().required("Requerido"),
    herenciacasa: Yup.string().when("viveen", {
      is: "Propio",
      then: () => Yup.string().required("Requerido"),
    }),
    heredo: Yup.string().required("Requerido"),
    vivecon: Yup.string().required("Requerido"),
    personas: Yup.number().typeError("Sólo números").required("Requerido"),
    menores: Yup.number().typeError("Sólo números").required("Requerido"),
    aliadulto1: Yup.string().required("Requerido"),
    aliadulto2: Yup.string().required("Requerido"),
    aliadulto3: Yup.string().required("Requerido"),
    aliadulto4: Yup.string().required("Requerido"),
    aliadulto5: Yup.string().required("Requerido"),
    aliadulto6: Yup.string().required("Requerido"),
    alimenor1: Yup.string().when("menores", {
      is: (menores) => menores > 0,
      then: () => Yup.string().required("Requerido"),
    }),
    alimenor2: Yup.string().when("menores", {
      is: (menores) => menores > 0,
      then: () => Yup.string().required("Requerido"),
    }),
    alimenor3: Yup.string().when("menores", {
      is: (menores) => menores > 0,
      then: () => Yup.string().required("Requerido"),
    }),
    alimenor4: Yup.string().when("menores", {
      is: (menores) => menores > 0,
      then: () => Yup.string().required("Requerido"),
    }),
    alimenor5: Yup.string().when("menores", {
      is: (menores) => menores > 0,
      then: () => Yup.string().required("Requerido"),
    }),
    alimenor6: Yup.string().when("menores", {
      is: (menores) => menores > 0,
      then: () => Yup.string().required("Requerido"),
    }),
    seguridadsocial: Yup.string().required("Requerido"),
    atnmedica: Yup.string().required("Requerido"),
    trabaja: Yup.string().required("Requerido"),
    horastrabajo: Yup.string().when("trabaja", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    remuneracion: Yup.string().when("trabaja", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    soloespecie: Yup.string().when("trabaja", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    otrosingresos: Yup.string().required("Requerido"),
    ahorro: Yup.string().required("Requerido"),
    deuda: Yup.string().required("Requerido"),
    necapoyoeconomico: Yup.string().required("Requerido"),
    recibeapoyoeconomico: Yup.string().required("Requerido"),
    cantidadapoyoeconomico: Yup.string().when("recibeapoyoeconomico", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    necapoyoespecie: Yup.string().required("Requerido"),
    recibeapoyoespecie: Yup.string().required("Requerido"),
    apoyoespeciealimentos: Yup.string().when("recibeapoyoespecie", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    apoyoespeciemedicinas: Yup.string().when("recibeapoyoespecie", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    apoyoespecietraslados: Yup.string().when("recibeapoyoespecie", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    apoyoespecietiempo: Yup.string().when("recibeapoyoespecie", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    apoyoespecieotro: Yup.string().when("recibeapoyoespecie", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    apoyasushijos: Yup.string().required("Requerido"),
    cuidonietos: Yup.string().when("apoyasushijos", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    llevoalaescuela: Yup.string().when("apoyasushijos", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    doydinero: Yup.string().when("apoyasushijos", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    vivenenmicasa: Yup.string().when("apoyasushijos", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    pagoservicios: Yup.string().when("apoyasushijos", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    ayudoenlacasa: Yup.string().when("apoyasushijos", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    alcanza: Yup.string().required("Requerido"),
    inscritoprograma65ymas: Yup.string().required("Requerido"),
    usatarjeta65ymas: Yup.string().when("inscritoprograma65ymas", {
      is: "Si, sin tarjeta",
      then: () => Yup.string().required("Requerido"),
    }),
    disponerecursos65ymas: Yup.string().when("inscritoprograma65ymas", {
      is: "Si, sin tarjeta",
      then: () => Yup.string().required("Requerido"),
    }),
    cuentabancaria: Yup.string().required("Requerido"),
    manejotrascuentas: Yup.string().when("cuentabancaria", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    atmyayuda: Yup.string().when("cuentabancaria", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    acudebancoayuda: Yup.string().when("cuentabancaria", {
      is: "Si",
      then: () => Yup.string().required("Requerido"),
    }),
    asma: Yup.string().required("Requerido"),
    colesterol: Yup.string().required("Requerido"),
    ecv: Yup.string().required("Requerido"),
    cardio: Yup.string().required("Requerido"),
    cancer: Yup.string().required("Requerido"),
    epoc: Yup.string().required("Requerido"),
    diabetes: Yup.string().required("Requerido"),
    hipertension: Yup.string().required("Requerido"),
    osteoporosis: Yup.string().required("Requerido"),
    artrosis: Yup.string().required("Requerido"),
    renal: Yup.string().required("Requerido"),
    dismotriz: Yup.string().required("Requerido"),
    disauditiva: Yup.string().required("Requerido"),
    disvisual: Yup.string().required("Requerido"),
    dishabla: Yup.string().required("Requerido"),
    disotra: Yup.string().required("Requerido"),
    vf: Yup.string().required("Campo requerido"),
    vfmeses: Yup.string().when("vf", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfhijos: Yup.string().when("vf", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    vfnietos: Yup.string().when("vf", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    vfpareja: Yup.string().when("vf", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    vfyernonuera: Yup.string().when("vf", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    vfotros: Yup.string().when("vf", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    vftestigo: Yup.string().when("vf", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfintoxicado: Yup.string().when(["vf", "familia"], {
      is: (vf, familia) => vf === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfdomicilio: Yup.string().when(["vf", "familia"], {
      is: (vf, familia) => vf === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfeconomico: Yup.string().when(["vf", "familia"], {
      is: (vf, familia) => vf === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfdefensa:
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfdefensahijos:
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().when("vfdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vfdefensanietos:
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().when("vfdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vfdefensapareja:
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().when("vfdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vfdefensayerno:
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().when("vfdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vfdefensaotros:
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().when("vfdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vfinforma: Yup.string().when(["vf", "familia"], {
      is: (vf, familia) => vf === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfinformafamiliares: Yup.string().when(["vf", "familia"], {
      is: (vf, familia) => vf === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfinformaamigos: Yup.string().when(["vf", "familia"], {
      is: (vf, familia) => vf === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfinformasacerdote: Yup.string().when(["vf", "familia"], {
      is: (vf, familia) => vf === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfinformahospital: Yup.string().when(["vf", "familia"], {
      is: (vf, familia) => vf === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfinformabienestar: Yup.string().when(["vf", "familia"], {
      is: (vf, familia) => vf === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfinformapolicia: Yup.string().when(["vf", "familia"], {
      is: (vf, familia) => vf === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfnoinformanosabe: Yup.string().when(["vf", "familia", "vfinforma"], {
      is: (vf, familia, vfinforma) => vf === "Si" && familia === "Si" && vfinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfnoinformanoapoyo: Yup.string().when(["vf", "familia", "vfinforma"], {
      is: (vf, familia, vfinforma) => vf === "Si" && familia === "Si" && vfinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfnoinformanoperjudicar: Yup.string().when(["vf", "familia", "vfinforma"], {
      is: (vf, familia, vfinforma) => vf === "Si" && familia === "Si" && vfinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfnoinformaempeoraria: Yup.string().when(["vf", "familia", "vfinforma"], {
      is: (vf, familia, vfinforma) => vf === "Si" && familia === "Si" && vfinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    mal: Yup.string().required("Campo requerido"),
    malmeses: Yup.string().when("mal", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malhijos: Yup.string().when("mal", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malnietos: Yup.string().when("mal", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malpareja: Yup.string().when("mal", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malyernonuera: Yup.string().when("mal", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malotros: Yup.string().when("mal", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    maltestigo: Yup.string().when("mal", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malintoxicado: Yup.string().when(["mal", "familia"], {
      is: (mal, familia) => mal === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    maleconomico: Yup.string().when(["mal", "familia"], {
      is: (mal, familia) => mal === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    maldomicilio: Yup.string().when(["mal", "familia"], {
      is: (mal, familia) => mal === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    maldefensa:
      Yup.string().when("mal", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    maldefensahijos:
      Yup.string().when("mal", {
        is: "Si",
        then: () => Yup.string().when("maldefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    maldefensanietos:
      Yup.string().when("mal", {
        is: "Si",
        then: () => Yup.string().when("maldefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    maldefensapareja:
      Yup.string().when("mal", {
        is: "Si",
        then: () => Yup.string().when("maldefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    maldefensayerno:
      Yup.string().when("mal", {
        is: "Si",
        then: () => Yup.string().when("maldefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    maldefensaotros:
      Yup.string().when("mal", {
        is: "Si",
        then: () => Yup.string().when("maldefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    malinforma: Yup.string().when(["mal", "familia"], {
      is: (mal, familia) => mal === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malinformafamiliares: Yup.string().when(["mal", "familia"], {
      is: (mal, familia) => mal === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malinformaamigos: Yup.string().when(["mal", "familia"], {
      is: (mal, familia) => mal === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malinformasacerdote: Yup.string().when(["mal", "familia"], {
      is: (mal, familia) => mal === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malinformahospital: Yup.string().when(["mal", "familia"], {
      is: (mal, familia) => mal === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malinformabienestar: Yup.string().when(["mal", "familia"], {
      is: (mal, familia) => mal === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malinformapolicia: Yup.string().when(["mal", "familia"], {
      is: (mal, familia) => mal === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malnoinformanosabe: Yup.string().when(["mal", "familia", "malinforma"], {
      is: (mal, familia, malinforma) => mal === "Si" && familia === "Si" && malinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malnoinformanoapoyo: Yup.string().when(["mal", "familia", "malinforma"], {
      is: (mal, familia, malinforma) => mal === "Si" && familia === "Si" && malinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malnoinformanoperjudicar: Yup.string().when(["mal", "familia", "malinforma"], {
      is: (mal, familia, malinforma) => mal === "Si" && familia === "Si" && malinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    malnoinformaempeoraria: Yup.string().when(["mal", "familia", "malinforma"], {
      is: (mal, familia, malinforma) => mal === "Si" && familia === "Si" && malinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    aband: Yup.string().required("Campo requerido"),
    abandmeses: Yup.string().when("aband", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandhijos: Yup.string().when("aband", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    abandnietos: Yup.string().when("aband", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    abandpareja: Yup.string().when("aband", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    abandyernonuera: Yup.string().when("aband", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    abandotros: Yup.string().when("aband", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    abandtestigo: Yup.string().when("aband", {
      is: "Si",
      then: () =>
        Yup.string().required("Campo requerido"),
    }),
    abandintoxicado: Yup.string().when(["aband", "familia"], {
      is: (aband, familia) => aband === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandeconomico: Yup.string().when(["aband", "familia"], {
      is: (aband, familia) => aband === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abanddomicilio:
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    abanddefensa:
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    abanddefensahijos:
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().when("abanddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    abanddefensanietos:
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().when("abanddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    abanddefensapareja:
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().when("abanddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    abanddefensayerno:
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().when("abanddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    abanddefensaotros:
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().when("abanddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    abandinforma: Yup.string().when(["aband", "familia"], {
      is: (aband, familia) => aband === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandinformasacerdote: Yup.string().when(["aband", "familia"], {
      is: (aband, familia) => aband === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandinformahospital: Yup.string().when(["aband", "familia"], {
      is: (aband, familia) => aband === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandinformabienestar: Yup.string().when(["aband", "familia"], {
      is: (aband, familia) => aband === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandinformapolicia: Yup.string().when(["aband", "familia"], {
      is: (aband, familia) => aband === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandnoinformanosabe: Yup.string().when(["aband", "familia", "abandinforma"], {
      is: (aband, familia, abandinforma) => aband === "Si" && familia === "Si" && abandinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandnoinformanoapoyo: Yup.string().when(["aband", "familia", "abandinforma"], {
      is: (aband, familia, abandinforma) => aband === "Si" && familia === "Si" && abandinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandnoinformanoperjudicar: Yup.string().when(["aband", "familia", "abandinforma"], {
      is: (aband, familia, abandinforma) => aband === "Si" && familia === "Si" && abandinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandnoinformaempeoraria: Yup.string().when(["aband", "familia", "abandinforma"], {
      is: (aband, familia, abandinforma) => aband === "Si" && familia === "Si" && abandinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vp: Yup.string().required("Campo requerido"),
    vpmeses: Yup.string().when("vp", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vphijos: Yup.string().when("vp", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    vpnietos: Yup.string().when("vp", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    vppareja: Yup.string().when("vp", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    vpyernonuera: Yup.string().when("vp", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    vpotros: Yup.string().when("vp", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    vptestigo: Yup.string().when("vp", {
      is: "Si",
      then: () =>
        Yup.string().required("Campo requerido"),
    }),
    vpintoxicado: Yup.string().when(["vp", "familia"], {
      is: (vp, familia) => vp === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpdomicilio: Yup.string().when(["vp", "familia"], {
      is: (vp, familia) => vp === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpeconomico: Yup.string().when(["vp", "familia"], {
      is: (vp, familia) => vp === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpdefensa:
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpdefensahijos:
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().when("vpdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vpdefensanietos:
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().when("vpdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vpdefensapareja:
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().when("vpdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vpdefensayerno:
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().when("vpdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vpdefensaotros:
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().when("vpdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vpinforma: Yup.string().when(["vp", "familia"], {
      is: (vp, familia) => vp === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpinformafamiliares: Yup.string().when(["vp", "familia"], {
      is: (vp, familia) => vp === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpinformaamigos: Yup.string().when(["vp", "familia"], {
      is: (vp, familia) => vp === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpinformasacerdote: Yup.string().when(["vp", "familia"], {
      is: (vp, familia) => vp === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpinformahospital: Yup.string().when(["vp", "familia"], {
      is: (vp, familia) => vp === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpinformabienestar: Yup.string().when(["vp", "familia"], {
      is: (vp, familia) => vp === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpinformapolicia: Yup.string().when(["vp", "familia"], {
      is: (vp, familia) => vp === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpnoinformanosabe: Yup.string().when(["vp", "familia", "vpinforma"], {
      is: (vp, familia, vpinforma) => vp === "Si" && familia === "Si" && vpinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpnoinformanoapoyo: Yup.string().when(["vp", "familia", "vpinforma"], {
      is: (vp, familia, vpinforma) => vp === "Si" && familia === "Si" && vpinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpnoinformanoperjudicar: Yup.string().when(["vp", "familia", "vpinforma"], {
      is: (vp, familia, vpinforma) => vp === "Si" && familia === "Si" && vpinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpnoinformaempeoraria: Yup.string().when(["vp", "familia", "vpinforma"], {
      is: (vp, familia, vpinforma) => vp === "Si" && familia === "Si" && vpinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexual: Yup.string().required("Campo requerido"),
    sexualmeses: Yup.string().when("sexual", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualhijos: Yup.string().when("sexual", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    sexualnietos: Yup.string().when("sexual", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    sexualpareja: Yup.string().when("sexual", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    sexualyernonuera: Yup.string().when("sexual", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    sexualotros: Yup.string().when("sexual", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    sexualtestigo: Yup.string().when("sexual", {
      is: "Si",
      then: () =>
        Yup.string().required("Campo requerido"),
    }),
    sexualintoxicado: Yup.string().when(["sexual", "familia"], {
      is: (sexual, familia) => sexual === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualdomicilio: Yup.string().when(["sexual", "familia"], {
      is: (sexual, familia) => sexual === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualeconomico: Yup.string().when(["sexual", "familia"], {
      is: (sexual, familia) => sexual === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualdefensa:
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualdefensahijos:
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().when("sexualdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    sexualdefensanietos:
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().when("sexualdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    sexualdefensapareja:
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().when("sexualdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    sexualdefensayerno:
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().when("sexualdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    sexualdefensaotros:
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().when("sexualdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    sexualinforma: Yup.string().when(["sexual", "familia"], {
      is: (sexual, familia) => sexual === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualinformafamiliares: Yup.string().when(["sexual", "familia"], {
      is: (sexual, familia) => sexual === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualinformaamigos: Yup.string().when(["sexual", "familia"], {
      is: (sexual, familia) => sexual === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualinformasacerdote: Yup.string().when(["sexual", "familia"], {
      is: (sexual, familia) => sexual === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualinformahospital: Yup.string().when(["sexual", "familia"], {
      is: (sexual, familia) => sexual === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualinformabienestar: Yup.string().when(["sexual", "familia"], {
      is: (sexual, familia) => sexual === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualinformapolicia: Yup.string().when(["sexual", "familia"], {
      is: (sexual, familia) => sexual === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualnoinformanosabe: Yup.string().when(["sexual", "familia", "sexualinforma"], {
      is: (sexual, familia, sexualinforma) => sexual === "Si" && familia === "Si" && sexualinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualnoinformanoapoyo: Yup.string().when(["sexual", "familia", "sexualinforma"], {
      is: (sexual, familia, sexualinforma) => sexual === "Si" && familia === "Si" && sexualinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualnoinformanoperjudicar: Yup.string().when(["sexual", "familia", "sexualinforma"], {
      is: (sexual, familia, sexualinforma) => sexual === "Si" && familia === "Si" && sexualinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualnoinformaempeoraria: Yup.string().when(["sexual", "familia", "sexualinforma"], {
      is: (sexual, familia, sexualinforma) => sexual === "Si" && familia === "Si" && sexualinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dinero: Yup.string().required("Campo requerido"),
    dineromeses: Yup.string().when("dinero", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dinerohijos: Yup.string().when("dinero", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    dineronietos: Yup.string().when("dinero", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    dineropareja: Yup.string().when("dinero", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    dineroyernonuera: Yup.string().when("dinero", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    dinerootros: Yup.string().when("dinero", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    dinerotestigo: Yup.string().when("dinero", {
      is: "Si",
      then: () =>
        Yup.string().required("Campo requerido"),
    }),
    dinerointoxicado: Yup.string().when(["dinero", "familia"], {
      is: (dinero, familia) => dinero === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dinerodomicilio: Yup.string().when(["dinero", "familia"], {
      is: (dinero, familia) => dinero === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dineroeconomico: Yup.string().when(["dinero", "familia"], {
      is: (dinero, familia) => dinero === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dinerodefensa:
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dinerodefensahijos:
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().when("dinerodefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    dinerodefensanietos:
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().when("dinerodefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    dinerodefensapareja:
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().when("dinerodefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    dinerodefensayerno:
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().when("dinerodefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    dinerodefensaotros:
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().when("dinerodefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    dineroinforma: Yup.string().when(["dinero", "familia"], {
      is: (dinero, familia) => dinero === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dineroinformafamiliares: Yup.string().when(["dinero", "familia"], {
      is: (dinero, familia) => dinero === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dineroinformaamigos: Yup.string().when(["dinero", "familia"], {
      is: (dinero, familia) => dinero === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dineroinformasacerdote: Yup.string().when(["dinero", "familia"], {
      is: (dinero, familia) => dinero === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dineroinformahospital: Yup.string().when(["dinero", "familia"], {
      is: (dinero, familia) => dinero === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dineroinformabienestar: Yup.string().when(["dinero", "familia"], {
      is: (dinero, familia) => dinero === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dineroinformapolicia: Yup.string().when(["dinero", "familia"], {
      is: (dinero, familia) => dinero === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dineronoinformanosabe: Yup.string().when(["dinero", "familia", "dineroinforma"], {
      is: (dinero, familia, dineroinforma) => dinero === "Si" && familia === "Si" && dineroinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dineronoinformanoapoyo: Yup.string().when(["dinero", "familia", "dineroinforma"], {
      is: (dinero, familia, dineroinforma) => dinero === "Si" && familia === "Si" && dineroinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dineronoinformanoperjudicar: Yup.string().when(["dinero", "familia", "dineroinforma"], {
      is: (dinero, familia, dineroinforma) => dinero === "Si" && familia === "Si" && dineroinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dineronoinformaempeoraria: Yup.string().when(["dinero", "familia", "dineroinforma"], {
      is: (dinero, familia, dineroinforma) => dinero === "Si" && familia === "Si" && dineroinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidad: Yup.string().required("Campo requerido"),
    movilidadmeses: Yup.string().when("movilidad", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadhijos: Yup.string().when("movilidad", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    movilidadnietos: Yup.string().when("movilidad", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    movilidadpareja: Yup.string().when("movilidad", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    movilidadyernonuera: Yup.string().when("movilidad", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    movilidadotros: Yup.string().when("movilidad", {
      is: "Si",
      then: () =>
        Yup.number().typeError("Sólo números").required("Campo requerido"),
    }),
    movilidadtestigo: Yup.string().when("movilidad", {
      is: "Si",
      then: () =>
        Yup.string().required("Campo requerido"),
    }),
    movilidadintoxicado: Yup.string().when(["movilidad", "familia"], {
      is: (movilidad, familia) => movilidad === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidaddomicilio: Yup.string().when(["movilidad", "familia"], {
      is: (movilidad, familia) => movilidad === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadeconomico: Yup.string().when(["movilidad", "familia"], {
      is: (movilidad, familia) => movilidad === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidaddefensa:
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidaddefensahijos:
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().when("movilidaddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    movilidaddefensanietos:
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().when("movilidaddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    movilidaddefensapareja:
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().when("movilidaddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    movilidaddefensayerno:
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().when("movilidaddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    movilidaddefensaotros:
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().when("movilidaddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    movilidadinforma: Yup.string().when(["movilidad", "familia"], {
      is: (movilidad, familia) => movilidad === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadinformafamiliares: Yup.string().when(["movilidad", "familia"], {
      is: (movilidad, familia) => movilidad === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadinformaamigos: Yup.string().when(["movilidad", "familia"], {
      is: (movilidad, familia) => movilidad === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadinformasacerdote: Yup.string().when(["movilidad", "familia"], {
      is: (movilidad, familia) => movilidad === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadinformahospital: Yup.string().when(["movilidad", "familia"], {
      is: (movilidad, familia) => movilidad === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadinformabienestar: Yup.string().when(["movilidad", "familia"], {
      is: (movilidad, familia) => movilidad === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadinformapolicia: Yup.string().when(["movilidad", "familia"], {
      is: (movilidad, familia) => movilidad === "Si" && familia === "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadnoinformanosabe: Yup.string().when(["movilidad", "familia", "movilidadinforma"], {
      is: (movilidad, familia, movilidadinforma) => movilidad === "Si" && familia === "Si" && movilidadinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadnoinformanoapoyo: Yup.string().when(["movilidad", "familia", "movilidadinforma"], {
      is: (movilidad, familia, movilidadinforma) => movilidad === "Si" && familia === "Si" && movilidadinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadnoinformanoperjudicar: Yup.string().when(["movilidad", "familia", "movilidadinforma"], {
      is: (movilidad, familia, movilidadinforma) => movilidad === "Si" && familia === "Si" && movilidadinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadnoinformaempeoraria: Yup.string().when(["movilidad", "familia", "movilidadinforma"], {
      is: (movilidad, familia, movilidadinforma) => movilidad === "Si" && familia === "Si" && movilidadinforma === "No",
      then: () => Yup.string().required("Campo requerido"),
    }),
  });

  return (
    <Layout>
      <div className="container mx-auto pb-20 pt-36">
        <div className="border-b border-t border-gray-900/10 col-span-4 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
          <h2 className="text-base font-bold leading-7 text-gray-900">
            Datos del denunciante
          </h2>
        </div>
        <Formik
          initialValues={{
            //_id: values?._id || "", Hubo actualizacion entonces ya no se debe de enviar el id al update solo en la url
            primerapam: values?.primerapam || "",
            denunciaspreviaspam: values?.denunciaspreviaspam || "",
            id_denunciante: values?.id_denunciante?._id || "",
            familia: values?.id_denunciante?.familia || "",
            anonimato: values?.id_denunciante?.anonimato || "",
            nombredenunciante: values?.id_denunciante?.nombredenunciante || "",
            calle: values?.id_denunciante?.calle || "",
            numeroExterior: values?.id_denunciante?.numeroExterior || "",
            numeroInterior: values?.id_denunciante?.numeroInterior || "",
            entreCalles: values?.id_denunciante?.entreCalles || "",
            colonia: values?.id_denunciante?.colonia || "",
            manzana: values?.id_denunciante?.manzana || "",
            lote: values?.id_denunciante?.lote || "",
            codigoPostal: values?.id_denunciante?.codigoPostal || "",
            municipio: values?.id_denunciante?.municipio || "",
            comunidad: values?.id_denunciante?.comunidad || "",
            emaildenunciante: values?.id_denunciante?.emaildenunciante || "",
            teldenunciante: values?.id_denunciante?.teldenunciante || "",
            curpdenunciante: values?.id_denunciante?.curpdenunciante || "",
            pamviolencia: values?.pamviolencia || "",
            callePam: values?.callePam || "",
            numeroExteriorPam: values?.numeroExteriorPam || "",
            numeroInteriorPam: values?.numeroInteriorPam || "",
            entreCallesPam: values?.entreCallesPam || "",
            coloniaPam: values?.coloniaPam || "",
            manzanaPam: values?.manzanaPam || "",
            lotePam: values?.lotePam || "",
            codigoPostalPam: values?.codigoPostalPam || "",
            municipioPam: values?.municipioPam || "",
            comunidadPam: values?.comunidadPam || "",
            emailpamviolencia: values?.emailpamviolencia || "",
            telpamviolencia: values?.telpamviolencia || "",
            curppamviolencia: values?.curppamviolencia || "",
            sexo: values?.sexo || "",
            muletasilla: values?.muletasilla || "",
            edad: values?.edad || "",
            edocivil: values?.edocivil || "",
            ultimoescolar: values?.ultimoescolar || "",
            leer: values?.leer || "",
            escribir: values?.escribir || "",
            indigena: values?.indigena || "",
            viveen: values?.viveen || "",
            herenciacasa: values?.herenciacasa || "",
            heredo: values?.heredo || "",
            vivecon: values?.vivecon || "",
            personas: values?.personas || "",
            menores: values?.menores || "",
            aliadulto1: values?.aliadulto1 || "",
            aliadulto2: values?.aliadulto2 || "",
            aliadulto3: values?.aliadulto3 || "",
            aliadulto4: values?.aliadulto4 || "",
            aliadulto5: values?.aliadulto5 || "",
            aliadulto6: values?.aliadulto6 || "",
            alimenor1: values?.alimenor1 || "",
            alimenor2: values?.alimenor2 || "",
            alimenor3: values?.alimenor3 || "",
            alimenor4: values?.alimenor4 || "",
            alimenor5: values?.alimenor5 || "",
            alimenor6: values?.alimenor6 || "",
            seguridadsocial: values?.seguridadsocial || "",
            atnmedica: values?.atnmedica || "",
            trabaja: values?.trabaja || "",
            horastrabajo: values?.horastrabajo || "",
            remuneracion: values?.remuneracion || "",
            soloespecie: values?.soloespecie || "",
            otrosingresos: values?.otrosingresos || "",
            ahorro: values?.ahorro || "",
            deuda: values?.deuda || "",
            necapoyoeconomico: values?.necapoyoeconomico || "",
            recibeapoyoeconomico: values?.recibeapoyoeconomico || "",
            cantidadapoyoeconomico: values?.cantidadapoyoeconomico || "",
            necapoyoespecie: values?.necapoyoespecie || "",
            recibeapoyoespecie: values?.recibeapoyoespecie || "",
            apoyoespeciealimentos: values?.apoyoespeciealimentos || "",
            apoyoespeciemedicinas: values?.apoyoespeciemedicinas || "",
            apoyoespecietraslados: values?.apoyoespecietraslados || "",
            apoyoespecietiempo: values?.apoyoespecietiempo || "",
            apoyoespecieotro: values?.apoyoespecieotro || "",
            apoyasushijos: values?.apoyasushijos || "",
            cuidonietos: values?.cuidonietos || "",
            llevoalaescuela: values?.llevoalaescuela || "",
            doydinero: values?.doydinero || "",
            vivenenmicasa: values?.vivenenmicasa || "",
            pagoservicios: values?.pagoservicios || "",
            ayudoenlacasa: values?.ayudoenlacasa || "",
            alcanza: values?.alcanza || "",
            inscritoprograma65ymas: values?.inscritoprograma65ymas || "",
            usatarjeta65ymas: values?.usatarjeta65ymas || "",
            disponerecursos65ymas: values?.disponerecursos65ymas || "",
            cuentabancaria: values?.cuentabancaria || "",
            manejotrascuentas: values?.manejotrascuentas || "",
            atmyayuda: values?.atmyayuda || "",
            acudebancoayuda: values?.acudebancoayuda || "",
            asma: values?.asma || "",
            colesterol: values?.colesterol || "",
            ecv: values?.ecv || "",
            cardio: values?.cardio || "",
            hipertension: values?.hipertension || "",
            cancer: values?.cancer || "",
            epoc: values?.epoc || "",
            diabetes: values?.diabetes || "",
            osteoporosis: values?.osteoporosis || "",
            artrosis: values?.artrosis || "",
            renal: values?.renal || "",
            dismotriz: values?.dismotriz || "",
            disauditiva: values?.disauditiva || "",
            disvisual: values?.disvisual || "",
            dishabla: values?.dishabla || "",
            disotra: values?.disotra || "",
            vf: values?.vf || "",
            vfmeses: values?.vfmeses || "",
            vfhijos: values?.vfhijos || "",
            vfnietos: values?.vfnietos || "",
            vfpareja: values?.vfpareja || "",
            vfyernonuera: values?.vfyernonuera || "",
            vfotros: values?.vfotros || "",
            vftestigo: values?.vftestigo || "",
            vfintoxicado: values?.vfintoxicado || "",
            vfdomicilio: values?.vfdomicilio || "",
            vfeconomico: values?.vfeconomico || "",
            vfdefensa: values?.vfdefensa || "",
            vfdefensahijos: values?.vfdefensahijos || "",
            vfdefensanietos: values?.vfdefensanietos || "",
            vfdefensapareja: values?.vfdefensapareja || "",
            vfdefensayerno: values?.vfdefensayerno || "",
            vfdefensaotros: values?.vfdefensaotros || "",
            vfinforma: values?.vfinforma || "",
            vfinformafamiliares: values?.vfinformafamiliares || "",
            vfinformaamigos: values?.vfinformaamigos || "",
            vfinformasacerdote: values?.vfinformasacerdote || "",
            vfinformahospital: values?.vfinformahospital || "",
            vfinformabienestar: values?.vfinformabienestar || "",
            vfinformapolicia: values?.vfinformapolicia || "",
            vfnoinformanosabe: values?.vfnoinformanosabe || "",
            vfnoinformanoapoyo: values?.vfnoinformanoapoyo || "",
            vfnoinformanoperjudicar: values?.vfnoinformanoperjudicar || "",
            vfnoinformaempeoraria: values?.vfnoinformaempeoraria || "",
            mal: values?.mal || "",
            malmeses: values?.malmeses || "",
            malhijos: values?.malhijos || "",
            malnietos: values?.malnietos || "",
            malpareja: values?.malpareja || "",
            malyernonuera: values?.malyernonuera || "",
            malotros: values?.malotros || "",
            maltestigo: values?.maltestigo || "",
            malintoxicado: values?.malintoxicado || "",
            maleconomico: values?.maleconomico || "",
            maldomicilio: values?.maldomicilio || "",
            maldefensa: values?.maldefensa || "",
            maldefensahijos: values?.maldefensahijos || "",
            maldefensanietos: values?.maldefensanietos || "",
            maldefensapareja: values?.maldefensapareja || "",
            maldefensayerno: values?.maldefensayerno || "",
            maldefensaotros: values?.maldefensaotros || "",
            malinforma: values?.malinforma || "",
            malinformafamiliares: values?.malinformafamiliares || "",
            malinformaamigos: values?.malinformaamigos || "",
            malinformasacerdote: values?.malinformasacerdote || "",
            malinformahospital: values?.malinformahospital || "",
            malinformabienestar: values?.malinformabienestar || "",
            malinformapolicia: values?.malinformapolicia || "",
            malnoinformanosabe: values?.malnoinformanosabe || "",
            malnoinformanoapoyo: values?.malnoinformanoapoyo || "",
            malnoinformanoperjudicar: values?.malnoinformanoperjudicar || "",
            malnoinformaempeoraria: values?.malnoinformaempeoraria || "",
            aband: values?.aband || "",
            abandmeses: values?.abandmeses || "",
            abandhijos: values?.abandhijos || "",
            abandnietos: values?.abandnietos || "",
            abandpareja: values?.abandpareja || "",
            abandyernonuera: values?.abandyernonuera || "",
            abandotros: values?.abandotros || "",
            abandtestigo: values?.abandtestigo || "",
            abandintoxicado: values?.abandintoxicado || "",
            abandeconomico: values?.abandeconomico || "",
            abanddomicilio: values?.abanddomicilio || "",
            abanddefensa: values?.abanddefensa || "",
            abanddefensahijos: values?.abanddefensahijos || "",
            abanddefensanietos: values?.abanddefensanietos || "",
            abanddefensapareja: values?.abanddefensapareja || "",
            abanddefensayerno: values?.abanddefensayerno || "",
            abanddefensaotros: values?.abanddefensaotros || "",
            abandinforma: values?.abandinforma || "",
            abandinformasacerdote: values?.abandinformasacerdote || "",
            abandinformahospital: values?.abandinformahospital || "",
            abandinformabienestar: values?.abandinformabienestar || "",
            abandinformapolicia: values?.abandinformapolicia || "",
            abandnoinformanosabe: values?.abandnoinformanosabe || "",
            abandnoinformanoapoyo: values?.abandnoinformanoapoyo || "",
            abandnoinformanoperjudicar:
              values?.abandnoinformanoperjudicar || "",
            abandnoinformaempeoraria: values?.abandnoinformaempeoraria || "",
            vp: values?.vp || "",
            vpmeses: values?.vpmeses || "",
            vphijos: values?.vphijos || "",
            vpnietos: values?.vpnietos || "",
            vppareja: values?.vppareja || "",
            vpyernonuera: values?.vpyernonuera || "",
            vpotros: values?.vpotros || "",
            vptestigo: values?.vptestigo || "",
            vpintoxicado: values?.vpintoxicado || "",
            vpeconomico: values?.vpeconomico || "",
            vpdomicilio: values?.vpdomicilio || "",
            vpdefensa: values?.vpdefensa || "",
            vpdefensahijos: values?.vpdefensahijos || "",
            vpdefensanietos: values?.vpdefensanietos || "",
            vpdefensapareja: values?.vpdefensapareja || "",
            vpdefensayerno: values?.vpdefensayerno || "",
            vpdefensaotros: values?.vpdefensaotros || "",
            vpinforma: values?.vpinforma || "",
            vpinformafamiliares: values?.vpinformafamiliares || "",
            vpinformaamigos: values?.vpinformaamigos || "",
            vpinformasacerdote: values?.vpinformasacerdote || "",
            vpinformahospital: values?.vpinformahospital || "",
            vpinformabienestar: values?.vpinformabienestar || "",
            vpinformapolicia: values?.vpinformapolicia || "",
            vpnoinformanosabe: values?.vpnoinformanosabe || "",
            vpnoinformanoapoyo: values?.vpnoinformanoapoyo || "",
            vpnoinformanoperjudicar: values?.vpnoinformanoperjudicar || "",
            vpnoinformaempeoraria: values?.vpnoinformaempeoraria || "",
            sexual: values?.sexual || "",
            sexualmeses: values?.sexualmeses || "",
            sexualhijos: values?.sexualhijos || "",
            sexualnietos: values?.sexualnietos || "",
            sexualpareja: values?.sexualpareja || "",
            sexualyernonuera: values?.sexualyernonuera || "",
            sexualotros: values?.sexualotros || "",
            sexualtestigo: values?.sexualtestigo || "",
            sexualintoxicado: values?.sexualintoxicado || "",
            sexualeconomico: values?.sexualeconomico || "",
            sexualdomicilio: values?.sexualdomicilio || "",
            sexualdefensa: values?.sexualdefensa || "",
            sexualdefensahijos: values?.sexualdefensahijos || "",
            sexualdefensanietos: values?.sexualdefensanietos || "",
            sexualdefensapareja: values?.sexualdefensapareja || "",
            sexualdefensayerno: values?.sexualdefensayerno || "",
            sexualdefensaotros: values?.sexualdefensaotros || "",
            sexualinforma: values?.sexualinforma || "",
            sexualinformafamiliares: values?.sexualinformafamiliares || "",
            sexualinformaamigos: values?.sexualinformaamigos || "",
            sexualinformasacerdote: values?.sexualinformasacerdote || "",
            sexualinformahospital: values?.sexualinformahospital || "",
            sexualinformabienestar: values?.sexualinformabienestar || "",
            sexualinformapolicia: values?.sexualinformapolicia || "",
            sexualnoinformanosabe: values?.sexualnoinformanosabe || "",
            sexualnoinformanoapoyo: values?.sexualnoinformanoapoyo || "",
            sexualnoinformanoperjudicar:
              values?.sexualnoinformanoperjudicar || "",
            sexualnoinformaempeoraria: values?.sexualnoinformaempeoraria || "",
            dinero: values?.dinero || "",
            dineromeses: values?.dineromeses || "",
            dinerohijos: values?.dinerohijos || "",
            dineronietos: values?.dineronietos || "",
            dineropareja: values?.dineropareja || "",
            dineroyernonuera: values?.dineroyernonuera || "",
            dinerootros: values?.dinerootros || "",
            dinerotestigo: values?.dinerotestigo || "",
            dinerointoxicado: values?.dinerointoxicado || "",
            dineroeconomico: values?.dineroeconomico || "",
            dinerodomicilio: values?.dinerodomicilio || "",
            dinerodefensa: values?.dinerodefensa || "",
            dinerodefensahijos: values?.dinerodefensahijos || "",
            dinerodefensanietos: values?.dinerodefensanietos || "",
            dinerodefensapareja: values?.dinerodefensapareja || "",
            dinerodefensayerno: values?.dinerodefensayerno || "",
            dinerodefensaotros: values?.dinerodefensaotros || "",
            dineroinforma: values?.dineroinforma || "",
            dineroinformafamiliares: values?.dineroinformafamiliares || "",
            dineroinformaamigos: values?.dineroinformaamigos || "",
            dineroinformasacerdote: values?.dineroinformasacerdote || "",
            dineroinformahospital: values?.dineroinformahospital || "",
            dineroinformabienestar: values?.dineroinformabienestar || "",
            dineroinformapolicia: values?.dineroinformapolicia || "",
            dineronoinformanosabe: values?.dineronoinformanosabe || "",
            dineronoinformanoapoyo: values?.dineronoinformanoapoyo || "",
            dineronoinformanoperjudicar:
              values?.dineronoinformanoperjudicar || "",
            dineronoinformaempeoraria: values?.dineronoinformaempeoraria || "",
            movilidad: values?.movilidad || "",
            movilidadmeses: values?.movilidadmeses || "",
            movilidadhijos: values?.movilidadhijos || "",
            movilidadnietos: values?.movilidadnietos || "",
            movilidadpareja: values?.movilidadpareja || "",
            movilidadyernonuera: values?.movilidadyernonuera || "",
            movilidadotros: values?.movilidadotros || "",
            movilidadtestigo: values?.movilidadtestigo || "",
            movilidadintoxicado: values?.movilidadintoxicado || "",
            movilidaddomicilio: values?.movilidaddomicilio || "",
            movilidaddefensa: values?.movilidaddefensa || "",
            movilidaddefensahijos: values?.movilidaddefensahijos || "",
            movilidaddefensanietos: values?.movilidaddefensanietos || "",
            movilidaddefensapareja: values?.movilidaddefensapareja || "",
            movilidaddefensayerno: values?.movilidaddefensayerno || "",
            movilidaddefensaotros: values?.movilidaddefensaotros || "",
            movilidadeconomico: values?.movilidadeconomico || "",
            movilidadinforma: values?.movilidadinforma || "",
            movilidadinformafamiliares:
              values?.movilidadinformafamiliares || "",
            movilidadinformaamigos: values?.movilidadinformaamigos || "",
            movilidadinformasacerdote: values?.movilidadinformasacerdote || "",
            movilidadinformahospital: values?.movilidadinformahospital || "",
            movilidadinformabienestar: values?.movilidadinformabienestar || "",
            movilidadinformapolicia: values?.movilidadinformapolicia || "",
            movilidadnoinformanosabe: values?.movilidadnoinformanosabe || "",
            movilidadnoinformanoapoyo: values?.movilidadnoinformanoapoyo || "",
            movilidadnoinformanoperjudicar:
              values?.movilidadnoinformanoperjudicar || "",
            movilidadnoinformaempeoraria:
              values?.movilidadnoinformaempeoraria || "",
          }}
          enableReinitialize={true}
          onSubmit={(data) => {
            setLoading(true)
            put("pam/" + values._id, data, token).then((res) => {
              setLoading(false)
              Swal.fire({
                icon: "success",
                title: "¡Registro actualizado exitosamente!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
          }}
          validationSchema={validateSchema}
        >
          {({
            values,
            handleChange,
            touched,
            errors,
            setFieldValue,
            dirty
          }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="sm:col-span-12">
                  <Select
                    label="¿La persona que denuncia es familiar de la víctima?"
                    name="familia"
                    value={values.familia}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value === "No") {
                        setFieldValue("familia", "No");
                        setFieldValue("anonimato", "");
                        setFieldValue("nombredenunciante", "");
                        setFieldValue("calle", "");
                        setFieldValue("numeroExterior", "");
                        setFieldValue("numeroInterior", "");
                        setFieldValue("entreCalles", "");
                        setFieldValue("colonia", "");
                        setFieldValue("manzana", "");
                        setFieldValue("lote", "");
                        setFieldValue("codigoPostal", "");
                        setFieldValue("municipio", "");
                        setFieldValue("comunidad", "");
                        setFieldValue("emaildenunciante", "");
                        setFieldValue("teldenunciante", "");
                        setFieldValue("curpdenunciante", "");
                      }
                    }}
                    array={SINO}
                  />
                  {touched.familia && errors.familia ? (
                    <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.familia}
                    </div>
                  ) : null}
                </div>
                {values.familia === "Si" && (
                  <div className="sm:col-span-12">
                    <Select
                      label="¿Desea hacer este reporte de manera anonima?"
                      name="anonimato"
                      value={values.anonimato}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value === "Si") {
                          setFieldValue("nombredenunciante", "");
                          setFieldValue("calle", "");
                          setFieldValue("numeroExterior", "");
                          setFieldValue("numeroInterior", "");
                          setFieldValue("entreCalles", "");
                          setFieldValue("colonia", "");
                          setFieldValue("manzana", "");
                          setFieldValue("lote", "");
                          setFieldValue("codigoPostal", "");
                          setFieldValue("municipio", "");
                          setFieldValue("comunidad", "");
                          setFieldValue("emaildenunciante", "");
                          setFieldValue("teldenunciante", "");
                          setFieldValue("curpdenunciante", "");
                        }
                      }}
                      array={SINO}
                    />
                    {touched.anonimato && errors.anonimato ? (
                      <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.anonimato}
                      </div>
                    ) : null}
                  </div>
                )}

                <div className="sm:col-span-12">
                  <Select
                    label="¿Esta es la primera denuncia que se relaciona con esta pam?"
                    name="primerapam"
                    value={values.primerapam}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    array={SINO}
                  />
                  {touched.primerapam && errors.primerapam ? (
                    <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.primerapam}
                    </div>
                  ) : null}
                </div>

                <div className="sm:col-span-12">
                  <Select
                    label="¿Esta es la primera denuncia que se relaciona con esta pam?"
                    name="denunciaspreviaspam"
                    value={values.denunciaspreviaspam}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    array={DATONUMEROS}
                  />
                  {touched.denunciaspreviaspam && errors.denunciaspreviaspam ? (
                    <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.denunciaspreviaspam}
                    </div>
                  ) : null}
                </div>

                {values.familia === "Si" && values.anonimato === "No" && (
                  <>
                    <div className="sm:col-span-6">
                      <Input
                        label="Nombre y apellidos"
                        name="nombredenunciante"
                        type="text"
                        placeholder="En caso de ser anonimo, escribe la palabra Anonimo"
                        value={values.nombredenunciante}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <small className="text-xs text-gray-400">
                        En caso de ser anonimo, escribe la palabra "Anonimo"
                      </small>
                      {touched.nombredenunciante && errors.nombredenunciante ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.nombredenunciante}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Input
                        label="Calle"
                        name="calle"
                        type="text"
                        placeholder="Calle del domicilio"
                        value={values.calle}
                        onChange={handleChange}
                      />
                      {touched.calle && errors.calle ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.calle}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Input
                        label="Número exterior"
                        name="numeroExterior"
                        type="text"
                        placeholder="Número exterior"
                        value={values.numeroExterior}
                        onChange={handleChange}
                      />
                      {touched.numeroExterior && errors.numeroExterior ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.numeroExterior}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Input
                        label="Número interior"
                        name="numeroInterior"
                        type="text"
                        placeholder="Número interior"
                        value={values.numeroInterior}
                        onChange={handleChange}
                      />
                      {touched.numeroInterior && errors.numeroInterior ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.numeroInterior}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Input
                        label="Entre calles"
                        name="entreCalles"
                        type="text"
                        placeholder="Entre que calles"
                        value={values.entreCalles}
                        onChange={handleChange}
                      />
                      {touched.entreCalles && errors.entreCalles ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.entreCalles}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Input
                        label="Colonia"
                        name="colonia"
                        type="text"
                        placeholder="Colonia"
                        value={values.colonia}
                        onChange={handleChange}
                      />
                      {touched.colonia && errors.colonia ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.colonia}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Input
                        label="Manzana"
                        name="manzana"
                        type="text"
                        placeholder="Manzana"
                        value={values.manzana}
                        onChange={handleChange}
                      />
                      {touched.manzana && errors.manzana ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.manzana}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Input
                        label="Lote"
                        name="lote"
                        type="text"
                        placeholder="Lote"
                        value={values.lote}
                        onChange={handleChange}
                      />
                      {touched.lote && errors.lote ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.lote}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Input
                        label="Código postal"
                        name="codigoPostal"
                        type="text"
                        maxLength="5"
                        placeholder="Código postal"
                        value={values.codigoPostal}
                        onChange={handleChange}
                      />
                      {touched.codigoPostal && errors.codigoPostal ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.codigoPostal}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Select
                        label="Municipio"
                        name="municipio"
                        value={values.municipio}
                        onChange={handleChange}
                        array={Municipios}
                      />
                      {touched.municipio && errors.municipio ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.municipio}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Select
                        label="Comunidad"
                        name="comunidad"
                        placeholder="comunidad"
                        value={values.comunidad}
                        onChange={handleChange}
                        array={getComunidad(values.municipio)}
                      />
                      {touched.comunidad && errors.comunidad ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.comunidad}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Input
                        label="Email del denunciante"
                        name="emaildenunciante"
                        type="text"
                        placeholder="Email del denunciante"
                        value={values.emaildenunciante}
                        onChange={handleChange}
                      />
                      {touched.emaildenunciante && errors.emaildenunciante ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.emaildenunciante}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Input
                        label="Teléfono del denunciante"
                        name="teldenunciante"
                        type="text"
                        maxLength="10"
                        placeholder="Teléfono del denunciante"
                        value={values.teldenunciante}
                        onChange={handleChange}
                      />
                      {touched.teldenunciante && errors.teldenunciante ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.teldenunciante}
                        </div>
                      ) : null}
                    </div>
                    <div className="sm:col-span-6">
                      <Input
                        label="CURP del denunciante"
                        name="curpdenunciante"
                        type="text"
                        placeholder="CURP del denunciante"
                        maxLength="18"
                        value={values.curpdenunciante}
                        onChange={handleChange}
                      />
                      {touched.curpdenunciante && errors.curpdenunciante ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.curpdenunciante}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
                <div className="border-b border-t border-gray-900/10 col-span-12 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    Información PAM
                  </h2>
                </div>
                <div className="col-span-12">
                  <Input
                    label="Nombre y apellidos de la PAM objeto de violencia"
                    name="pamviolencia"
                    type="text"
                    placeholder="Nombre y apellidos de la PAM objeto de violencia"
                    value={values.pamviolencia}
                    onChange={handleChange}
                  />
                  {touched.pamviolencia && errors.pamviolencia ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.pamviolencia}
                    </div>
                  ) : null}
                </div>
                <div className="border-b border-t pt-6 border-gray-900/10 pb-6 col-span-12">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Dirección
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Dirección de la PAM objeto de violencia
                  </p>
                </div>
                <div className="col-span-12 lg:col-span-4">
                  <Input
                    label="Calle"
                    name="callePam"
                    type="text"
                    placeholder="Calle del domicilio"
                    value={values.callePam}
                    onChange={handleChange}
                  />
                  {touched.callePam && errors.callePam ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.callePam}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 lg:col-span-4">
                  <Input
                    label="Número exterior"
                    name="numeroExteriorPam"
                    type="text"
                    placeholder="Número exterior"
                    value={values.numeroExteriorPam}
                    onChange={handleChange}
                  />
                  {touched.numeroExteriorPam && errors.numeroExteriorPam ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.numeroExteriorPam}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 lg:col-span-4">
                  <Input
                    label="Número interior"
                    name="numeroInteriorPam"
                    type="text"
                    placeholder="Número interior"
                    value={values.numeroInteriorPam}
                    onChange={handleChange}
                  />
                  {touched.numeroInteriorPam && errors.numeroInteriorPam ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.numeroInteriorPam}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 lg:col-span-4">
                  <Input
                    label="Entre calles"
                    name="entreCallesPam"
                    type="text"
                    placeholder="Entre que calles"
                    value={values.entreCallesPam}
                    onChange={handleChange}
                  />
                  {touched.entreCallesPam && errors.entreCallesPam ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.entreCallesPam}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 lg:col-span-4">
                  <Input
                    label="Colonia"
                    name="coloniaPam"
                    type="text"
                    placeholder="Colonia"
                    value={values.coloniaPam}
                    onChange={handleChange}
                  />
                  {touched.coloniaPam && errors.coloniaPam ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.coloniaPam}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 lg:col-span-4">
                  <Input
                    label="Manzana"
                    name="manzanaPam"
                    type="text"
                    placeholder="Manzana"
                    value={values.manzanaPam}
                    onChange={handleChange}
                  />
                  {touched.manzanaPam && errors.manzanaPam ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.manzanaPam}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 sm:col-span-3">
                  <Input
                    label="Lote"
                    name="lotePam"
                    type="text"
                    placeholder="Lote"
                    value={values.lote}
                    onChange={handleChange}
                  />
                  {touched.lotePam && errors.lotePam ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.lotePam}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 sm:col-span-3">
                  <Input
                    label="Código postal"
                    name="codigoPostalPam"
                    type="text"
                    maxLength="5"
                    placeholder="Código postal"
                    value={values.codigoPostalPam}
                    onChange={handleChange}
                  />
                  {touched.codigoPostalPam && errors.codigoPostalPam ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.codigoPostalPam}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <Select
                    label="Municipio"
                    name="municipioPam"
                    value={values.municipioPam}
                    onChange={handleChange}
                    array={Municipios}
                  />
                  {touched.municipioPam && errors.municipioPam ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.municipioPam}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <Select
                    label="Comunidad"
                    name="comunidadPam"
                    placeholder="comunidad"
                    value={values.comunidadPam}
                    onChange={handleChange}
                    array={getComunidad(values.municipioPam)}
                  />
                  {touched.comunidadPam && errors.comunidadPam ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.comunidadPam}
                    </div>
                  ) : null}
                </div>
                {values.familia === "Si" && (
                  <>
                    <div className="col-span-12 border-t"></div>
                    <div className="col-span-12 md:col-span-6">
                      <Input
                        label="Correo electrónico de la PAM objeto de violencia"
                        name="emailpamviolencia"
                        type="text"
                        placeholder="Correo electrónico de la PAM objeto de violencia"
                        value={values.emailpamviolencia}
                        onChange={handleChange}
                      />
                      {touched.emailpamviolencia && errors.emailpamviolencia ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.emailpamviolencia}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Input
                        label="Teléfono de la PAM objeto de violencia"
                        name="telpamviolencia"
                        type="text"
                        maxLength="10"
                        placeholder="Teléfono de la PAM objeto de violencia"
                        value={values.telpamviolencia}
                        onChange={handleChange}
                      />
                      {touched.telpamviolencia && errors.telpamviolencia ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.telpamviolencia}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Input
                        label="CURP de la PAM objeto de violencia"
                        name="curppamviolencia"
                        type="text"
                        maxLength="18"
                        placeholder="CURP de la PAM objeto de violencia"
                        value={values.curppamviolencia}
                        onChange={handleChange}
                      />
                      {touched.curppamviolencia && errors.curppamviolencia ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.curppamviolencia}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
                <div className="col-span-12 md:col-span-6">
                  <Select
                    label="Sexo de la PAM objeto de violencia"
                    name="sexo"
                    value={values.sexo}
                    onChange={handleChange}
                    array={HombreMujer}
                  />
                  {touched.sexo && errors.sexo ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.sexo}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 lg:col-span-6">
                  <Select
                    label="La PAM usa muletas, bastón o silla de ruedas"
                    name="muletasilla"
                    value={values.muletasilla}
                    onChange={handleChange}
                    array={SINO}
                  />
                  {touched.muletasilla && errors.muletasilla ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.muletasilla}
                    </div>
                  ) : null}
                </div>
                {values.familia === "Si" && (
                  <>
                    <div className="col-span-12 md:col-span-6">
                      <Input
                        label="Edad"
                        name="edad"
                        type="text"
                        placeholder="Edad"
                        value={values.edad}
                        onChange={handleChange}
                      />
                      {touched.edad && errors.edad ? (
                        <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.edad}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Estado civil"
                        name="edocivil"
                        value={values.edocivil}
                        onChange={handleChange}
                        array={estadoCivil}
                      />
                      {touched.edocivil && errors.edocivil ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.edocivil}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Último grado de estudios"
                        name="ultimoescolar"
                        value={values.ultimoescolar}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value == 0) {
                            setFieldValue("leer", "");
                            setFieldValue("escribir", "");
                          }
                        }}
                        array={ultimoGrado}
                      />
                      {touched.ultimoescolar && errors.ultimoescolar ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.ultimoescolar}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-t col-span-12"></div>
                    {values.ultimoescolar === "Ninguno" &&
                      values.ultimoescolar !== "" && (
                        <>
                          <div className="col-span-12 md:col-span-6">
                            <Select
                              label="¿Sabe leer?"
                              name="leer"
                              value={values.leer}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.leer && errors.leer ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.leer}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <Select
                              label="¿Sabe escribir?"
                              name="escribir"
                              value={values.escribir}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.escribir && errors.escribir ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.escribir}
                              </div>
                            ) : null}
                          </div>
                          <div className="border-t col-span-12"></div>
                        </>
                      )}
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="¿La PAM se identifica como perteneciente a algún pueblo originario?"
                        name="indigena"
                        value={values.indigena}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.indigena && errors.indigena ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.indigena}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Habitualmente, La PAM vive en una casa/departamento"
                        name="viveen"
                        value={values.viveen}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value == 0) {
                            setFieldValue("herenciacasa", "");
                          }
                        }}
                        array={vivienda}
                      />
                      {touched.viveen && errors.viveen ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.viveen}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-t col-span-12"></div>
                    {values.viveen === "Propio" && values.viveen !== "" && (
                      <>
                        <div className="col-span-12">
                          <Select
                            label="¿Ha dado en herencia la casa/departamento en la cual La PAM actualmente vive?"
                            name="herenciacasa"
                            value={values.herenciacasa}
                            onChange={handleChange}
                            array={herencia}
                          />
                          {touched.herenciacasa && errors.herenciacasa ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.herenciacasa}
                            </div>
                          ) : null}
                        </div>
                        <div className="border-t col-span-12"></div>
                      </>
                    )}
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="¿La PAM ya heredó (otros bienes) a sus hijos/hijas?"
                        name="heredo"
                        value={values.heredo}
                        onChange={handleChange}
                        array={heredo}
                      />
                      {touched.heredo && errors.heredo ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.heredo}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Habitualmente, La PAM vive con…"
                        name={"vivecon"}
                        value={values.vivecon}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value == "Cónyuge") {
                            setFieldValue("personas", "");
                            setFieldValue("menores", "");
                            setFieldValue("aliadulto1", "");
                            setFieldValue("aliadulto2", "");
                            setFieldValue("aliadulto3", "");
                            setFieldValue("aliadulto4", "");
                            setFieldValue("aliadulto5", "");
                            setFieldValue("aliadulto6", "");
                          }
                        }}
                        array={viveCon}
                      />
                      {touched.vivecon && errors.vivecon ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vivecon}
                        </div>
                      ) : null}
                    </div>

                    {values.viveen != "Comunidad/asilo" && (
                      <>
                        <div className="col-span-12 md:col-span-6">
                          <Input
                            label="Incluyéndolo a La PAM, en el lugar en el cual habitualmente La PAM vive. ¿Cuántos personas residen?"
                            name="personas"
                            placeholder="Solo números"
                            type="text"
                            value={values.personas}
                            onChange={handleChange}
                          />
                          {touched.personas && errors.personas ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.personas}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Input
                            label="De éstos, ¿cuántos son menores de edad?"
                            name="menores"
                            type="text"
                            placeholder="Solo números"
                            value={values.menores}
                            onChange={handleChange}
                          />
                          {touched.menores && errors.menores ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.menores}
                            </div>
                          ) : null}
                        </div>

                        <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                          <h2 className="text-base font-bold leading-7 text-gray-900">
                            En los últimos tres meses, La PAM o algún adulto en
                            la vivienda en la cual La PAM habitualmente vive
                            TODAS LAS QUE APLIQUEN
                          </h2>
                        </div>

                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Dejó de desayunar, comer o cenar por falta de dinero?"
                            name="aliadulto1"
                            value={values.aliadulto1}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.aliadulto1 && errors.aliadulto1 ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.aliadulto1}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="¿Comió menos de lo que debería comer por falta de dinero?"
                            name="aliadulto2"
                            value={values.aliadulto2}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.aliadulto2 && errors.aliadulto2 ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.aliadulto2}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="¿Tuvo poca variedad en sus alimentos por falta de dinero?"
                            name="aliadulto3"
                            value={values.aliadulto3}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.aliadulto3 && errors.aliadulto3 ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.aliadulto3}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Comió sólo una vez al día o dejó de comer todo un día por falta de dinero?"
                            name="aliadulto4"
                            value={values.aliadulto4}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.aliadulto4 && errors.aliadulto4 ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.aliadulto4}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="¿Sintió hambre, pero por falta de dinero no comió?"
                            name="aliadulto5"
                            value={values.aliadulto5}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.aliadulto5 && errors.aliadulto5 ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.aliadulto5}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="¿Se quedó sin comida por falta de dinero?"
                            name="aliadulto6"
                            value={values.aliadulto6}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value == "No") {
                                setFieldValue("alimenor1", "");
                                setFieldValue("alimenor2", "");
                                setFieldValue("alimenor3", "");
                                setFieldValue("alimenor4", "");
                                setFieldValue("alimenor5", "");
                                setFieldValue("alimenor6", "");
                              }
                            }}
                            array={SINO}
                          />
                          {touched.aliadulto6 && errors.aliadulto6 ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.aliadulto6}
                            </div>
                          ) : null}
                        </div>

                        {values.menores > 0 && (
                          <>
                            <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                              <h2 className="text-base font-bold leading-7 text-gray-900">
                                En los últimos tres meses, algún menor … TODAS
                                LAS QUE APLIQUEN
                              </h2>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <Select
                                label="Dejó de desayunar, comer o cenar por falta de dinero?"
                                name="alimenor1"
                                value={values.alimenor1}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.alimenor1 && errors.alimenor1 ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" />{" "}
                                  {errors.alimenor1}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <Select
                                label="¿Comió menos de lo que debería comer por falta de dinero?"
                                name="alimenor2"
                                value={values.alimenor2}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.alimenor2 && errors.alimenor2 ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" />{" "}
                                  {errors.alimenor2}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <Select
                                label="¿Tuvo poca variedad en sus alimentos por falta de dinero?"
                                name="alimenor3"
                                value={values.alimenor3}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.alimenor3 && errors.alimenor3 ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" />{" "}
                                  {errors.alimenor3}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <Select
                                label="Comió sólo una vez al día o dejó de comer todo un día por falta de dinero?"
                                name="alimenor4"
                                value={values.alimenor4}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.alimenor4 && errors.alimenor4 ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" />{" "}
                                  {errors.alimenor4}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <Select
                                label="¿Sintió hambre, pero por falta de dinero no comió?"
                                name="alimenor5"
                                value={values.alimenor5}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.alimenor5 && errors.alimenor5 ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" />{" "}
                                  {errors.alimenor5}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <Select
                                label="¿Se quedó sin comida por falta de dinero?"
                                name="alimenor6"
                                value={values.alimenor6}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.alimenor6 && errors.alimenor6 ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" />{" "}
                                  {errors.alimenor6}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <div className="border-t col-span-12"></div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="La PAM tiene seguridad social por parte de:"
                        name="seguridadsocial"
                        value={values.seguridadsocial}
                        onChange={handleChange}
                        array={seguridadSocial}
                      />
                      {touched.seguridadsocial && errors.seguridadsocial ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.seguridadsocial}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Si se enferma, ¿a quién acude?"
                        name="atnmedica"
                        value={values.atnmedica}
                        onChange={handleChange}
                        array={atencionMedica}
                      />
                      {touched.atnmedica && errors.atnmedica ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.atnmedica}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="¿La PAM trabaja?"
                        name="trabaja"
                        value={values.trabaja}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value == "No") {
                            setFieldValue("horastrabajo", "");
                            setFieldValue("remuneracion", "");
                            setFieldValue("soloespecie", "");
                          }
                        }}
                        array={SINO}
                      />
                      {touched.trabaja && errors.trabaja ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.trabaja}
                        </div>
                      ) : null}
                    </div>
                    {values.trabaja == "Si" && (
                      <>
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Por semana, apróximadamente, ¿cuántas horas dedica al trabajo?"
                            name="horastrabajo"
                            value={values.horastrabajo}
                            onChange={handleChange}
                            array={horasTrabajo}
                          />
                          {touched.horastrabajo && errors.horastrabajo ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.horastrabajo}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="¿Qué remuneración/ingresos recibe cada quincena por su trabajo?"
                            name="remuneracion"
                            value={values.remuneracion}
                            onChange={handleChange}
                            array={remuneracion}
                          />
                          {touched.remuneracion && errors.remuneracion ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.remuneracion}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Me pagan en especie (ej. comida, hospedaje)"
                            name="soloespecie"
                            value={values.soloespecie}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.soloespecie && errors.soloespecie ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.soloespecie}
                            </div>
                          ) : null}
                        </div>
                        <div className="border-t col-span-12"></div>
                      </>
                    )}
                    <div className="col-span-12 md:col-span-6">
                      <div className="py-1">
                        <label
                          htmlFor="otrosingresos"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Si tiene otros ingresos monetarios, ¿Apróximadamente
                          qué cantidad recibe cada mes? (en pesos)
                        </label>
                        <div className="mt-2">
                          <NumericFormat
                            label=""
                            name="otrosingresos"
                            thousandSeparator=","
                            prefix={"$"}
                            decimalScale={2}
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#621132] sm:text-sm sm:leading-6"
                            placeholder="$"
                            value={values.otrosingresos}
                            onChange={(e) => {
                              setFieldValue("otrosingresos", e.target.value);
                            }}
                          />
                        </div>
                        {touched.otrosingresos && errors.otrosingresos ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.otrosingresos}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div className="py-1">
                        <label
                          htmlFor="otrosingresos"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          ¿Qué cantidad tiene ahorrada? (en pesos)
                        </label>
                        <div className="mt-2">
                          <NumericFormat
                            name="otrosingresos"
                            thousandSeparator=","
                            prefix={"$"}
                            decimalScale={2}
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#621132] sm:text-sm sm:leading-6"
                            placeholder="$"
                            value={values.ahorro}
                            onChange={(e) => {
                              setFieldValue("ahorro", e.target.value);
                            }}
                          />
                        </div>
                        {touched.ahorro && errors.ahorro ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.ahorro}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div className="py-1">
                        <label
                          htmlFor="deuda"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          ¿Qué cantidad debe en este momento (deuda)? (en pesos)
                        </label>
                        <div className="mt-2">
                          <NumericFormat
                            name="deuda"
                            thousandSeparator=","
                            prefix={"$"}
                            decimalScale={2}
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#621132] sm:text-sm sm:leading-6"
                            placeholder="$"
                            value={values.deuda}
                            onChange={(e) => {
                              setFieldValue("deuda", e.target.value);
                            }}
                          />
                        </div>
                        {touched.deuda && errors.deuda ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.deuda}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="¿La PAM considera necesitar apoyo económico?"
                        name="necapoyoeconomico"
                        value={values.necapoyoeconomico}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.necapoyoeconomico && errors.necapoyoeconomico ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.necapoyoeconomico}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="¿Alguien de su familia lo apoya economicamente?"
                        name="recibeapoyoeconomico"
                        value={values.recibeapoyoeconomico}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value == 0) {
                            setFieldValue("cantidadapoyoeconomico", "");
                          }
                        }}
                        array={SINO}
                      />
                      {touched.recibeapoyoeconomico &&
                        errors.recibeapoyoeconomico ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.recibeapoyoeconomico}
                        </div>
                      ) : null}
                    </div>
                    {values.recibeapoyoeconomico == "Si" && (
                      <>
                        <div className="col-span-12 md:col-span-6">
                          <div className="py-1">
                            <label
                              htmlFor="cantidadapoyoeconomico"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Apróximadamente, ¿Con qué cantidad lo apoyan
                              mensualmente? (en pesos)
                            </label>
                            <div className="mt-2">
                              <NumericFormat
                                name="cantidadapoyoeconomico"
                                thousandSeparator=","
                                prefix={"$"}
                                decimalScale={2}
                                type="text"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#621132] sm:text-sm sm:leading-6"
                                placeholder="$"
                                value={values.cantidadapoyoeconomico}
                                onChange={(e) => {
                                  setFieldValue(
                                    "cantidadapoyoeconomico",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            {touched.cantidadapoyoeconomico &&
                              errors.cantidadapoyoeconomico ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.cantidadapoyoeconomico}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="¿La PAM considera necesitar apoyo en especie?"
                        name="necapoyoespecie"
                        value={values.necapoyoespecie}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.necapoyoespecie && errors.necapoyoespecie ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.necapoyoespecie}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="¿Alguien de su familia lo apoya en especie?"
                        name="recibeapoyoespecie"
                        value={values.recibeapoyoespecie}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value == "No") {
                            setFieldValue("apoyoespeciealimentos", "");
                            setFieldValue("apoyoespeciearticulos", "");
                            setFieldValue("apoyoespecietraslados", "");
                            setFieldValue("apoyoespecietiempo", "");
                            setFieldValue("apoyoespecieotro", "");
                          }
                        }}
                        array={SINO}
                      />
                      {touched.recibeapoyoespecie &&
                        errors.recibeapoyoespecie ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.recibeapoyoespecie}
                        </div>
                      ) : null}
                    </div>
                    {values.recibeapoyoespecie == "Si" && (
                      <>
                        <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                          <h2 className="text-base font-bold leading-7 text-gray-900">
                            ¿Con qué lo apoyan mensualmente?
                          </h2>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Alimentos/ropa"
                            name="apoyoespeciealimentos"
                            value={values.apoyoespeciealimentos}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.apoyoespeciealimentos &&
                            errors.apoyoespeciealimentos ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.apoyoespeciealimentos}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Medicinas/pañales/artículos higiene personal"
                            name="apoyoespeciemedicinas"
                            value={values.apoyoespeciemedicinas}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.apoyoespeciemedicinas &&
                            errors.apoyoespeciemedicinas ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.apoyoespeciemedicinas}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Traslados/transporte"
                            name="apoyoespecietraslados"
                            value={values.apoyoespecietraslados}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.apoyoespecietraslados &&
                            errors.apoyoespecietraslados ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.apoyoespecietraslados}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Me dedican tiempo/Están conmigo"
                            name="apoyoespecietiempo"
                            value={values.apoyoespecietiempo}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.apoyoespecietiempo &&
                            errors.apoyoespecietiempo ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.apoyoespecietiempo}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12">
                          <Select
                            label="Otro (cualquiera distinto de los anteriores)"
                            name="apoyoespecieotro"
                            value={values.apoyoespecieotro}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.apoyoespecieotro &&
                            errors.apoyoespecieotro ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.apoyoespecieotro}
                            </div>
                          ) : null}
                        </div>
                        <div className="border-t col-span-12"></div>
                      </>
                    )}
                    <div className="col-span-12">
                      <Select
                        label="¿La PAM proporciona algún apoyo a sus hijos?"
                        name="apoyasushijos"
                        value={values.apoyasushijos}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value == "No") {
                            setFieldValue("cuidonietos", "");
                            setFieldValue("llevoalaescuela", "");
                            setFieldValue("doydinero", "");
                            setFieldValue("pagoservicios", "");
                            setFieldValue("ayudoenlacasa", "");
                          }
                        }}
                        array={SINO}
                      />
                      {touched.apoyasushijos && errors.apoyasushijos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.apoyasushijos}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-t col-span-12"></div>
                    {values.apoyasushijos == "Si" && (
                      <>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Cuido los nietos"
                            name="cuidonietos"
                            value={values.cuidonietos}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.cuidonietos && errors.cuidonietos ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.cuidonietos}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Llevo los nietos a la escuela"
                            name="llevoalaescuela"
                            value={values.llevoalaescuela}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.llevoalaescuela && errors.llevoalaescuela ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.llevoalaescuela}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Les doy dinero (ej. para la comida)"
                            name="doydinero"
                            value={values.doydinero}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.doydinero && errors.doydinero ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.doydinero}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Pago los servicios (ej. luz, agua, gas)"
                            name="pagoservicios"
                            value={values.pagoservicios}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.pagoservicios && errors.pagoservicios ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.pagoservicios}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12">
                          <Select
                            label="Ayudo en la casa (ej. lavo trastes)"
                            name="ayudoenlacasa"
                            value={values.ayudoenlacasa}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.ayudoenlacasa && errors.ayudoenlacasa ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.ayudoenlacasa}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12">
                          <Select
                            label="Viven en mi casa"
                            name="vivenenmicasa"
                            value={values.vivenenmicasa}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            array={SINO}
                          />
                          {touched.vivenenmicasa && errors.vivenenmicasa ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vivenenmicasa}
                            </div>
                          ) : null}
                        </div>
                        <div className="border-t col-span-12"></div>
                      </>
                    )}

                    <div className="col-span-12">
                      <Select
                        label="Si no recibiera ayuda (en especie o monetaria) por parte de familiares, ¿sus ingresos alcanzan para cubrir sus necesidades?"
                        name="alcanza"
                        value={values.alcanza}
                        onChange={handleChange}
                        array={alzanza}
                      />
                      {touched.alcanza && errors.alcanza ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.alcanza}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-t col-span-12"></div>
                    <div className="col-span-12">
                      <Select
                        label="¿La PAM está inscrito en el Programa Pensión para el Bienestar y más y tiene tarjeta del mismo?"
                        name="inscritoprograma65ymas"
                        value={values.inscritoprograma65ymas}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value == "No estoy inscrito") {
                            setFieldValue("usatarjeta65ymas", "");
                            setFieldValue("disponerecursos65ymas", "");
                          }
                        }}
                        array={inscritoPrograma}
                      />
                      {touched.inscritoprograma65ymas &&
                        errors.inscritoprograma65ymas ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.inscritoprograma65ymas}
                        </div>
                      ) : null}
                    </div>
                    {(values.inscritoprograma65ymas == "Si, sin tarjeta" ||
                      values.inscritoprograma65ymas == "Si, con tarjeta") && (
                        <>
                          <div className="border-t col-span-12"></div>
                          <div className="col-span-12 md:col-span-6">
                            <Select
                              label="¿La PAM usa la tarjeta del Programa Pensión para el Bienestar ?"
                              name="usatarjeta65ymas"
                              value={values.usatarjeta65ymas}
                              onChange={handleChange}
                              array={usaTarjeta}
                            />
                            {touched.usatarjeta65ymas &&
                              errors.usatarjeta65ymas ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.usatarjeta65ymas}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <Select
                              label="¿La PAM decide cómo se utilizan los recursos del Programa Pensión para el Bienestar?"
                              name="disponerecursos65ymas"
                              value={values.disponerecursos65ymas}
                              onChange={handleChange}
                              array={dispone65}
                            />
                            {touched.disponerecursos65ymas &&
                              errors.disponerecursos65ymas ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.disponerecursos65ymas}
                              </div>
                            ) : null}
                          </div>
                          <div className="border-t col-span-12"></div>
                        </>
                      )}
                    <div className="col-span-12">
                      <Select
                        label="Además de la tarjeta del Programa Pensión para el Bienestar, ¿La PAM tiene alguna otra tarjeta/cuenta bancaria?"
                        name="cuentabancaria"
                        value={values.cuentabancaria}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value == "No") {
                            setFieldValue("manejotrascuentas", "");
                            setFieldValue("atmyayuda", "");
                            setFieldValue("acudebancoayuda", "");
                          }
                        }}
                        array={SINO}
                      />
                      {touched.cuentabancaria && errors.cuentabancaria ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.cuentabancaria}
                        </div>
                      ) : null}
                    </div>
                    {values.cuentabancaria == "Si" && (
                      <>
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Si tiene otras tarjetas o cuentas bancarias, además del Programa Pensión para el Bienestar, generalmente ¿las maneja La PAM?"
                            name="manejotrascuentas"
                            value={values.manejotrascuentas}
                            onChange={handleChange}
                            array={manejoCuentas}
                          />
                          {touched.manejotrascuentas &&
                            errors.manejotrascuentas ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.manejotrascuentas}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="Si tiene tarjeta/cuenta bancaria (programa pensión para el bienestar u otra), cuando va al cajero automático…"
                            name="atmyayuda"
                            value={values.atmyayuda}
                            onChange={handleChange}
                            array={ayuda}
                          />
                          {touched.atmyayuda && errors.atmyayuda ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.atmyayuda}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12">
                          <Select
                            label="Si tiene tarjeta/cuenta bancaria (programa pensión para el bienestar u otra), ¿cuándo acude al banco, ¿necesita ayuda?"
                            name="acudebancoayuda"
                            value={values.acudebancoayuda}
                            onChange={handleChange}
                            array={acudeBanco}
                          />
                          {touched.acudebancoayuda && errors.acudebancoayuda ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.acudebancoayuda}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}

                    <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                      <h2 className="text-base font-bold leading-7 text-gray-900">
                        ¿La PAM padece alguna enfermedad de la lista a
                        continuación? Indique todas las que padece:
                      </h2>
                    </div>

                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Asma bronquial"
                        name="asma"
                        value={values.asma}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.asma && errors.asma ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.asma}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Hipercolesterolemia (colesterol alto)"
                        name="colesterol"
                        value={values.colesterol}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.colesterol && errors.colesterol ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.colesterol}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Enfermedades cerebrovasculares"
                        name="ecv"
                        value={values.ecv}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.ecv && errors.ecv ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.ecv}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Cardiopatía isquémica (problemas cardíacos)"
                        name="cardio"
                        value={values.cardio}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.cardio && errors.cardio ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.cardio}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Hipertensión arterial (presión alta)"
                        name="hipertension"
                        value={values.hipertension}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.hipertension && errors.hipertension ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.hipertension}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Cáncer"
                        name="cancer"
                        value={values.cancer}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.cancer && errors.cancer ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.cancer}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="EPOC-Enfermedad pulmonar obstructiva crónica"
                        name="epoc"
                        value={values.epoc}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.epoc && errors.epoc ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.epoc}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Diabetes "
                        name="diabetes"
                        value={values.diabetes}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.diabetes && errors.diabetes ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.diabetes}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Osteoporosis"
                        name="osteoporosis"
                        value={values.osteoporosis}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.osteoporosis && errors.osteoporosis ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.osteoporosis}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Artrosis"
                        name="artrosis"
                        value={values.artrosis}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.artrosis && errors.artrosis ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.artrosis}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Select
                        label="Insuficiencia renal"
                        name="renal"
                        value={values.renal}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.renal && errors.renal ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.renal}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}

                <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    ¿Tiene alguna discapacidad?
                  </h2>
                </div>

                <div className="col-span-12 md:col-span-6">
                  <Select
                    label="Motriz"
                    name="dismotriz"
                    value={values.dismotriz}
                    onChange={handleChange}
                    array={SINO}
                  />
                  {touched.dismotriz && errors.dismotriz ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.dismotriz}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 md:col-span-6">
                  <Select
                    label="Auditiva"
                    name="disauditiva"
                    value={values.disauditiva}
                    onChange={handleChange}
                    array={SINO}
                  />
                  {touched.disauditiva && errors.disauditiva ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.disauditiva}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 md:col-span-6">
                  <Select
                    label="Visual"
                    name="disvisual"
                    value={values.disvisual}
                    onChange={handleChange}
                    array={SINO}
                  />
                  {touched.disvisual && errors.disvisual ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.disvisual}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 md:col-span-6">
                  <Select
                    label="Del habla"
                    name="dishabla"
                    value={values.dishabla}
                    onChange={handleChange}
                    array={SINO}
                  />
                  {touched.dishabla && errors.dishabla ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.dishabla}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-12 md:col-span-6">
                  <Select
                    label="Otra"
                    name="disotra"
                    value={values.disotra}
                    onChange={handleChange}
                    array={SINO}
                  />
                  {touched.disotra && errors.disotra ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.disotra}
                    </div>
                  ) : null}
                </div>
                <div className="border-b border-t border-gray-900/10 col-span-12 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    Información VF
                  </h2>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
                <div className="col-span-12">
                  <div className="rounded-md bg-yellow-50 p-2">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Atención</h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>
                            Si el valor se encuentra en "Si" y los demás campos tienen valores, cuando usted cambie a "No", los valores de los siguientes campos, serán limpiados, en caso de que se no quiera eliminar esos datos, no guarde la edición de esta denuncia.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Select
                    label="1. Recientemente...¿alguien le ha dado golpes, empujones o cachetadas?"
                    name="vf"
                    value={values.vf}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value === "No") {
                        const vf = e.target.value;
                        setFieldValue("vf", vf);
                        setFieldValue("vfmeses", '');
                        setFieldValue("vfhijos", '');
                        setFieldValue("vfnietos", '');
                        setFieldValue("vfpareja", '');
                        setFieldValue("vfyernonuera", '');
                        setFieldValue("vfotros", '');
                        setFieldValue("vftestigo", '');
                        setFieldValue("vfintoxicado", '');
                        setFieldValue("vfdomicilio", '');
                        setFieldValue("vfeconomico", '');
                        setFieldValue("vfdefensa", '');
                        setFieldValue("vfdefensahijos", '');
                        setFieldValue("vfdefensanietos", '');
                        setFieldValue("vfdefensapareja", '');
                        setFieldValue("vfdefensayerno", '');
                        setFieldValue("vfdefensaotros", '');
                        setFieldValue("vfinforma", '');
                        setFieldValue("vfinformafamiliares", '');
                        setFieldValue("vfinformaamigos", '');
                        setFieldValue("vfinformasacerdote", '');
                        setFieldValue("vfinformahospital", '');
                        setFieldValue("vfinformabienestar", '');
                        setFieldValue("vfinformapolicia", '');
                        setFieldValue("vfnoinformanosabe", '');
                        setFieldValue("vfnoinformanoapoyo", '');
                        setFieldValue("vfnoinformanoperjudicar", '');
                        setFieldValue("vfnoinformaempeoraria", '');
                      }
                    }}
                    array={SINO}
                  />
                  {touched.vf && errors.vf ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.vf}
                    </div>
                  ) : null}
                </div>
                {values.vf === "Si" && (
                  <>
                    <div className="col-span-12">
                      <Input
                        label="1.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                        name="vfmeses"
                        type="text"
                        placeholder="Inserta cuanto tiempo"
                        value={values.vfmeses}
                        onChange={handleChange}
                      />
                      {touched.vfmeses && errors.vfmeses ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vfmeses}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                      <h2 className="text-base font-bold leading-7 text-gray-900">
                        ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                      </h2>
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Hijos/Hijas"
                        name="vfhijos"
                        placeholder="Hijos"
                        value={values.vfhijos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.vfhijos && errors.vfhijos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vfhijos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Nietos/Nietas"
                        name="vfnietos"
                        placeholder="Nietos"
                        value={values.vfnietos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.vfnietos && errors.vfnietos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vfnietos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Pareja"
                        name="vfpareja"
                        placeholder="Pareja"
                        value={values.vfpareja}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.vfpareja && errors.vfpareja ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vfpareja}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Yerno/Nuera"
                        name="vfyernonuera"
                        placeholder="Yerno"
                        value={values.vfyernonuera}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.vfyernonuera && errors.vfyernonuera ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vfyernonuera}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Otros"
                        name="vfotros"
                        placeholder="Otros"
                        value={values.vfotros}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.vfotros && errors.vfotros ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vfotros}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Select
                        label="No sabe, pero lo atestiguó"
                        name="vftestigo"
                        value={values.vftestigo}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.vftestigo && errors.vftestigo ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vftestigo}
                        </div>
                      ) : null}
                    </div>
                    {values.familia === "Si" && (
                      <>
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="1.3 ¿Estos episodios ocurren cuando el agresor se encuentra intoxicado (tomó alcohol, alguna droga)?"
                            name="vfintoxicado"
                            value={values.vfintoxicado}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vfintoxicado && errors.vfintoxicado ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vfintoxicado}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿El/la/los agresores comparten el domicilio que la PAM objeto de violencia?"
                            name="vfdomicilio"
                            value={values.vfdomicilio}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vfdomicilio && errors.vfdomicilio ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vfdomicilio}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                            name="vfeconomico"
                            value={values.vfeconomico}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vfeconomico && errors.vfeconomico ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vfeconomico}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-12">
                          <Select
                            label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                            name="vfdefensa"
                            value={values.vfdefensa}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value === "No") {
                                setFieldValue('vfdefensahijos', "")
                                setFieldValue('vfdefensanietos', "")
                                setFieldValue('vfdefensapareja', "")
                                setFieldValue('vfdefensayerno', "")
                                setFieldValue('vfdefensaotros', "")
                              }
                            }}
                            array={SINO}
                          />
                          {touched.vfdefensa && errors.vfdefensa ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.vfdefensa}
                            </div>
                          ) : null}
                        </div>
                        {values.vfdefensa == "Si" && (
                          <>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Hijos/Hijas"
                                name="vfdefensahijos"
                                placeholder="Hijos"
                                value={values.vfdefensahijos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.vfdefensahijos && errors.vfdefensahijos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vfdefensahijos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Nietos/Nietas"
                                name="vfdefensanietos"
                                placeholder="Nietos"
                                value={values.vfdefensanietos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.vfdefensanietos && errors.vfdefensanietos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vfdefensanietos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Pareja/esposo/esposa"
                                name="vfdefensapareja"
                                placeholder="Pareja/esposo/esposa"
                                value={values.vfdefensapareja}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.vfdefensapareja && errors.vfdefensapareja ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vfdefensapareja}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Yerno/Nuera"
                                name="vfdefensayerno"
                                placeholder="Yerno"
                                value={values.vfdefensayerno}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.vfdefensayerno && errors.vfdefensayerno ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vfdefensayerno}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Otras personas que no son familiares directos"
                                name="vfdefensaotros"
                                placeholder="Otros"
                                value={values.vfdefensaotros}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.vfdefensaotros && errors.vfdefensaotros ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vfdefensaotros}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="1.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta situación? (indique todos los casos que apliquen)"
                            name="vfinforma"
                            value={values.vfinforma}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vfinforma && errors.vfinforma ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.vfinforma}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Familiares"
                            name="vfinformafamiliares"
                            value={values.vfinformafamiliares}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vfinformafamiliares &&
                            errors.vfinformafamiliares ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vfinformafamiliares}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Amigos"
                            name="vfinformaamigos"
                            value={values.vfinformaamigos}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vfinformaamigos && errors.vfinformaamigos ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vfinformaamigos}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Sacerdote"
                            name="vfinformasacerdote"
                            value={values.vfinformasacerdote}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vfinformasacerdote &&
                            errors.vfinformasacerdote ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vfinformasacerdote}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Hospital"
                            name="vfinformahospital"
                            value={values.vfinformahospital}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vfinformahospital &&
                            errors.vfinformahospital ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vfinformahospital}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Bienestar Social"
                            name="vfinformabienestar"
                            value={values.vfinformabienestar}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vfinformabienestar &&
                            errors.vfinformabienestar ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vfinformabienestar}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Policia"
                            name="vfinformapolicia"
                            value={values.vfinformapolicia}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vfinformapolicia &&
                            errors.vfinformapolicia ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vfinformapolicia}
                            </div>
                          ) : null}
                        </div>
                        {
                          values.vfinforma === "No" && (
                            <>
                              <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                                <h2 className="text-base font-bold leading-7 text-gray-900">
                                  Si no informó, ¿por qué no lo hizo?
                                </h2>
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No sabía a quién acudir"
                                  name="vfnoinformanosabe"
                                  value={values.vfnoinformanosabe}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.vfnoinformanosabe &&
                                  errors.vfnoinformanosabe ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.vfnoinformanosabe}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                  name="vfnoinformanoapoyo"
                                  value={values.vfnoinformanoapoyo}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.vfnoinformanoapoyo &&
                                  errors.vfnoinformanoapoyo ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.vfnoinformanoapoyo}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No quiero perjudicar a mis familiares"
                                  name="vfnoinformanoperjudicar"
                                  value={values.vfnoinformanoperjudicar}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.vfnoinformanoperjudicar &&
                                  errors.vfnoinformanoperjudicar ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.vfnoinformanoperjudicar}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Empeoraría la situación"
                                  name="vfnoinformaempeoraria"
                                  value={values.vfnoinformaempeoraria}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.vfnoinformaempeoraria &&
                                  errors.vfnoinformaempeoraria ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.vfnoinformaempeoraria}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )
                        }
                      </>
                    )}
                  </>
                )}
                <div className="border-b border-t border-gray-900/10 col-span-12 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    Información MALNO
                  </h2>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
                <div className="col-span-12">
                  <div className="rounded-md bg-yellow-50 p-2">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Atención</h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>
                            Si el valor se encuentra en "Si" y los demás campos tienen valores, cuando usted cambie a "No", los valores de los siguientes campos, serán limpiados, en caso de que se no quiera eliminar esos datos, no guarde la edición de esta denuncia.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Select
                    label="2.  Algunos de sus familiares directos/personas cercanas a La PAM... ¿no le hablan y/o no le apoyan, o lo/la dejan solo/a por mucho tiempo?"
                    name="mal"
                    value={values.mal}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value == "No") {
                        const mal = e.target.value;
                        setFieldValue("mal", mal);
                        setFieldValue("malmeses", '');
                        setFieldValue("malhijos", '');
                        setFieldValue("malnietos", '');
                        setFieldValue("malpareja", '');
                        setFieldValue("malyernonuera", '');
                        setFieldValue("malotros", '');
                        setFieldValue("maltestigo", '');
                        setFieldValue("malintoxicado", '');
                        setFieldValue("maleconomico", '');
                        setFieldValue("maldomicilio", '');
                        setFieldValue("maldefensa", '');
                        setFieldValue("maldefensahijos", '');
                        setFieldValue("maldefensanietos", '');
                        setFieldValue("maldefensapareja", '');
                        setFieldValue("maldefensayerno", '');
                        setFieldValue("maldefensaotros", '');
                        setFieldValue("malinforma", '');
                        setFieldValue("malinformafamiliares", '');
                        setFieldValue("malinformaamigos", '');
                        setFieldValue("malinformasacerdote", '');
                        setFieldValue("malinformahospital", '');
                        setFieldValue("malinformabienestar", '');
                        setFieldValue("malinformapolicia", '');
                        setFieldValue("malnoinformanosabe", '');
                        setFieldValue("malnoinformanoapoyo", '');
                        setFieldValue("malnoinformanoperjudicar", '');
                        setFieldValue("malnoinformaempeoraria", '');
                      }
                    }}
                    array={SINO}
                  />
                  {touched.mal && errors.mal ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.mal}
                    </div>
                  ) : null}
                </div>
                {values.mal == "Si" && (
                  <>
                    <div className="col-span-12">
                      <Input
                        label="2.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                        name="malmeses"
                        type="text"
                        placeholder="Inserta cuanto tiempo"
                        value={values.malmeses}
                        onChange={handleChange}
                      />
                      {touched.malmeses && errors.malmeses ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.malmeses}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                      <h2 className="text-base font-bold leading-7 text-gray-900">
                        ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                      </h2>
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Hijos/Hijas"
                        name="malhijos"
                        placeholder="Hijos"
                        value={values.malhijos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.malhijos && errors.malhijos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.malhijos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Nietos/Nietas"
                        placeholder="Nietos"
                        name="malnietos"
                        value={values.malnietos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.malnietos && errors.malnietos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.malnietos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Pareja"
                        name="malpareja"
                        placeholder="Pareja"
                        value={values.malpareja}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.malpareja && errors.malpareja ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.malpareja}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Yerno/Nuera"
                        name="malyernonuera"
                        placeholder="Yerno/Nuera"
                        value={values.malyernonuera}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.malyernonuera && errors.malyernonuera ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.malyernonuera}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Otros"
                        name="malotros"
                        placeholder="Otros"
                        value={values.malotros}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.malotros && errors.malotros ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.malotros}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Select
                        label="No sabe, pero lo atestiguó"
                        name="maltestigo"
                        value={values.maltestigo}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.maltestigo && errors.maltestigo ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.maltestigo}
                        </div>
                      ) : null}
                    </div>
                    {values.familia === "Si" && (
                      <>
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="2.3 ¿Estos episodios ocurren cuando el agresor se encuentra intoxicado (tomó alcohol, alguna droga)?"
                            name="malintoxicado"
                            value={values.malintoxicado}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.malintoxicado && errors.malintoxicado ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.malintoxicado}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿El/la/los agresores viven en el mismo domicilio que la PAM?"
                            name="maldomicilio"
                            value={values.maldomicilio}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.maldomicilio && errors.maldomicilio ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.maldomicilio}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                            name="maleconomico"
                            value={values.maleconomico}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.maleconomico && errors.maleconomico ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.maleconomico}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-12">
                          <Select
                            label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                            name="maldefensa"
                            value={values.maldefensa}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value === "No") {
                                setFieldValue('maldefensahijos', "")
                                setFieldValue('maldefensanietos', "")
                                setFieldValue('maldefensapareja', "")
                                setFieldValue('maldefensayerno', "")
                                setFieldValue('maldefensaotros', "")
                              }
                            }}
                            array={SINO}
                          />
                          {touched.maldefensa && errors.maldefensa ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.maldefensa}
                            </div>
                          ) : null}
                        </div>
                        {values.maldefensa == "Si" && (
                          <>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Hijos/Hijas"
                                name="maldefensahijos"
                                placeholder="Hijos"
                                value={values.maldefensahijos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.maldefensahijos && errors.maldefensahijos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.maldefensahijos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Nietos/Nietas"
                                name="maldefensanietos"
                                placeholder="Nietos"
                                value={values.maldefensanietos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.maldefensanietos && errors.maldefensanietos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.maldefensanietos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Pareja/esposo/esposa"
                                name="maldefensapareja"
                                placeholder="Pareja/esposo/esposa"
                                value={values.maldefensapareja}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.maldefensapareja && errors.maldefensapareja ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.maldefensapareja}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Yerno/Nuera"
                                name="maldefensayerno"
                                placeholder="Yerno"
                                value={values.maldefensayerno}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.maldefensayerno && errors.maldefensayerno ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.maldefensayerno}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Otras personas que no son familiares directos"
                                name="maldefensaotros"
                                placeholder="Otros"
                                value={values.maldefensaotros}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.maldefensaotros && errors.maldefensaotros ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.maldefensaotros}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="2.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta situación? (indique todos los casos que apliquen)"
                            name="malinforma"
                            value={values.malinforma}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.malinforma && errors.malinforma ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.malinforma}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Familiares"
                            name="malinformafamiliares"
                            value={values.malinformafamiliares}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.malinformafamiliares &&
                            errors.malinformafamiliares ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.malinformafamiliares}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Amigos"
                            name="malinformaamigos"
                            value={values.malinformaamigos}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.malinformaamigos &&
                            errors.malinformaamigos ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.malinformaamigos}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Sacerdote"
                            name="malinformasacerdote"
                            value={values.malinformasacerdote}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.malinformasacerdote &&
                            errors.malinformasacerdote ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.malinformasacerdote}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Hospital"
                            name="malinformahospital"
                            value={values.malinformahospital}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.malinformahospital &&
                            errors.malinformahospital ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.malinformahospital}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Bienestar Social"
                            name="malinformabienestar"
                            value={values.malinformabienestar}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.malinformabienestar &&
                            errors.malinformabienestar ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.malinformabienestar}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Policia"
                            name="malinformapolicia"
                            value={values.malinformapolicia}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.malinformapolicia &&
                            errors.malinformapolicia ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.malinformapolicia}
                            </div>
                          ) : null}
                        </div>
                        {
                          values.malinforma === "No" && (
                            <>
                              <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                                <h2 className="text-base font-bold leading-7 text-gray-900">
                                  Si no informó, ¿por qué no lo hizo?
                                </h2>
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No sabía a quién acudir"
                                  name="malnoinformanosabe"
                                  value={values.malnoinformanosabe}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.malnoinformanosabe &&
                                  errors.malnoinformanosabe ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.malnoinformanosabe}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                  name="malnoinformanoapoyo"
                                  value={values.malnoinformanoapoyo}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.malnoinformanoapoyo &&
                                  errors.malnoinformanoapoyo ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.malnoinformanoapoyo}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No quiero perjudicar a mis familiares"
                                  name="malnoinformanoperjudicar"
                                  value={values.malnoinformanoperjudicar}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.malnoinformanoperjudicar &&
                                  errors.malnoinformanoperjudicar ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.malnoinformanoperjudicar}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Empeoraría la situación"
                                  name="malnoinformaempeoraria"
                                  value={values.malnoinformaempeoraria}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.malnoinformaempeoraria &&
                                  errors.malnoinformaempeoraria ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.malnoinformaempeoraria}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )
                        }
                      </>
                    )}
                  </>
                )}
                <div className="border-b border-t border-gray-900/10 col-span-12 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    Información ABANDONA
                  </h2>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
                <div className="col-span-12">
                  <div className="rounded-md bg-yellow-50 p-2">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Atención</h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>
                            Si el valor se encuentra en "Si" y los demás campos tienen valores, cuando usted cambie a "No", los valores de los siguientes campos, serán limpiados, en caso de que se no quiera eliminar esos datos, no guarde la edición de esta denuncia.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Select
                    label="3. ¿La PAM ha sido expulsado/a del hogar por parte de alguno de sus familiares (hijos, nietos, pareja)?"
                    name="aband"
                    value={values.aband}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value == "No") {
                        const aband = e.target.value;
                        setFieldValue("aband", aband);
                        setFieldValue("abandmeses", '');
                        setFieldValue("abandhijos", '');
                        setFieldValue("abandnietos", '');
                        setFieldValue("abandpareja", '');
                        setFieldValue("abandyernonuera", '');
                        setFieldValue("abandotros", '');
                        setFieldValue("abandtestigo", '');
                        setFieldValue("abandintoxicado", '');
                        setFieldValue("abandeconomico", '');
                        setFieldValue("abanddefensa", '');
                        setFieldValue("abanddefensahijos", '');
                        setFieldValue("abanddefensanietos", '');
                        setFieldValue("abanddefensapareja", '');
                        setFieldValue("abanddefensayerno", '');
                        setFieldValue("abanddefensaotros", '');
                        setFieldValue("abandinforma", '');
                        setFieldValue("abandinformasacerdote", '');
                        setFieldValue("abandinformahospital", '');
                        setFieldValue("abandinformabienestar", '');
                        setFieldValue("abandinformapolicia", '');
                        setFieldValue("abandnoinformanosabe", '');
                        setFieldValue("abandnoinformanoapoyo", '');
                        setFieldValue("abandnoinformanoperjudicar", '');
                        setFieldValue("abandnoinformaempeoraria", '');
                      }
                    }}
                    array={SINO}
                  />
                  {touched.aband && errors.aband ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.aband}
                    </div>
                  ) : null}
                </div>
                {values.aband == "Si" && (
                  <>
                    <div className="col-span-12">
                      <Input
                        label="3.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                        name="abandmeses"
                        type="text"
                        placeholder="Inserta cuanto tiempo"
                        value={values.abandmeses}
                        onChange={handleChange}
                      />
                      {touched.abandmeses && errors.abandmeses ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.abandmeses}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                      <h2 className="text-base font-bold leading-7 text-gray-900">
                        ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                      </h2>
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Hijos/Hijas"
                        name="abandhijos"
                        placeholder="Hijos"
                        value={values.abandhijos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.abandhijos && errors.abandhijos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.abandhijos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Nietos/Nietas"
                        name="abandnietos"
                        placeholder="Nietos"
                        value={values.abandnietos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.abandnietos && errors.abandnietos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.abandnietos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Pareja"
                        name="abandpareja"
                        placeholder="Pareja"
                        value={values.abandpareja}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.abandpareja && errors.abandpareja ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.abandpareja}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Yerno/Nuera"
                        name="abandyernonuera"
                        placeholder="Yerno/Nuera"
                        value={values.abandyernonuera}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.abandyernonuera && errors.abandyernonuera ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.abandyernonuera}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Otros"
                        name="abandotros"
                        placeholder="Otros"
                        value={values.abandotros}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.abandotros && errors.abandotros ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.abandotros}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Select
                        label="No sabe, pero lo atestiguó"
                        name="abandtestigo"
                        value={values.abandtestigo}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.abandtestigo && errors.abandtestigo ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.abandtestigo}
                        </div>
                      ) : null}
                    </div>
                    {values.familia === "Si" && (
                      <>
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="3.3 ¿Estos episodios ocurren cuando el agresor se encuentra intoxicado (tomó alcohol, alguna droga)?"
                            name="abandintoxicado"
                            value={values.abandintoxicado}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.abandintoxicado && errors.abandintoxicado ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.abandintoxicado}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿El/la/los agresores viven en el mismo domicilio que la PAM?"
                            name="abanddomicilio"
                            value={values.abanddomicilio}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.abanddomicilio && errors.abanddomicilio ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.abanddomicilio}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                            name="abandeconomico"
                            value={values.abandeconomico}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.abandeconomico && errors.abandeconomico ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.abandeconomico}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-12">
                          <Select
                            label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                            name="abanddefensa"
                            value={values.abanddefensa}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value === "No") {
                                setFieldValue('abanddefensahijos', "")
                                setFieldValue('abanddefensanietos', "")
                                setFieldValue('abanddefensapareja', "")
                                setFieldValue('abanddefensayerno', "")
                                setFieldValue('abanddefensaotros', "")
                              }
                            }}
                            array={SINO}
                          />
                          {touched.abanddefensa && errors.abanddefensa ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.abanddefensa}
                            </div>
                          ) : null}
                        </div>
                        {values.abanddefensa == "Si" && (
                          <>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Hijos/Hijas"
                                name="abanddefensahijos"
                                placeholder="Hijos"
                                value={values.abanddefensahijos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.abanddefensahijos && errors.abanddefensahijos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.abanddefensahijos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Nietos/Nietas"
                                name="abanddefensanietos"
                                placeholder="Nietos"
                                value={values.abanddefensanietos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.abanddefensanietos && errors.abanddefensanietos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.abanddefensanietos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Pareja/esposo/esposa"
                                name="abanddefensapareja"
                                placeholder="Pareja/esposo/esposa"
                                value={values.abanddefensapareja}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.abanddefensapareja && errors.abanddefensapareja ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.abanddefensapareja}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Yerno/Nuera"
                                name="abanddefensayerno"
                                placeholder="Yerno"
                                value={values.abanddefensayerno}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.abanddefensayerno && errors.abanddefensayerno ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.abanddefensayerno}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Otras personas que no son familiares directos"
                                name="abanddefensaotros"
                                placeholder="Otros"
                                value={values.abanddefensaotros}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.abanddefensaotros && errors.abanddefensaotros ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.abanddefensaotros}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="3.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta situación? (indique todos los casos que apliquen)"
                            name="abandinforma"
                            value={values.abandinforma}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.abandinforma && errors.abandinforma ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.abandinforma}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Sacerdote"
                            name="abandinformasacerdote"
                            value={values.abandinformasacerdote}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.abandinformasacerdote &&
                            errors.abandinformasacerdote ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.abandinformasacerdote}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Hospital"
                            name="abandinformahospital"
                            value={values.abandinformahospital}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.abandinformahospital &&
                            errors.abandinformahospital ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.abandinformahospital}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Bienestar Social"
                            name="abandinformabienestar"
                            value={values.abandinformabienestar}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.abandinformabienestar &&
                            errors.abandinformabienestar ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.abandinformabienestar}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Policia"
                            name="abandinformapolicia"
                            value={values.abandinformapolicia}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.abandinformapolicia &&
                            errors.abandinformapolicia ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.abandinformapolicia}
                            </div>
                          ) : null}
                        </div>
                        {
                          values.abandinforma === "No" && (
                            <>
                              <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                                <h2 className="text-base font-bold leading-7 text-gray-900">
                                  Si no informó, ¿por qué no lo hizo?
                                </h2>
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No sabía a quién acudir"
                                  name="abandnoinformanosabe"
                                  value={values.abandnoinformanosabe}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.abandnoinformanosabe &&
                                  errors.abandnoinformanosabe ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.abandnoinformanosabe}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                  name="abandnoinformanoapoyo"
                                  value={values.abandnoinformanoapoyo}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.abandnoinformanoapoyo &&
                                  errors.abandnoinformanoapoyo ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.abandnoinformanoapoyo}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No quiero perjudicar a mis familiares"
                                  name="abandnoinformanoperjudicar"
                                  value={values.abandnoinformanoperjudicar}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.abandnoinformanoperjudicar &&
                                  errors.abandnoinformanoperjudicar ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.abandnoinformanoperjudicar}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Empeoraría la situación"
                                  name="abandnoinformaempeoraria"
                                  value={values.abandnoinformaempeoraria}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.abandnoinformaempeoraria &&
                                  errors.abandnoinformaempeoraria ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.abandnoinformaempeoraria}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )
                        }
                      </>
                    )}
                  </>
                )}
                <div className="border-b border-t border-gray-900/10 col-span-12 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    Información VP
                  </h2>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
                <div className="col-span-12">
                  <div className="rounded-md bg-yellow-50 p-2">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Atención</h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>
                            Si el valor se encuentra en "Si" y los demás campos tienen valores, cuando usted cambie a "No", los valores de los siguientes campos, serán limpiados, en caso de que se no quiera eliminar esos datos, no guarde la edición de esta denuncia.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Select
                    label="4. Recientemente…alguien con quien habitualmente convive... ¿le ha gritado en repetidas ocasiones, lo humilla/hace sentir menos, lo trata como niño?"
                    name="vp"
                    value={values.vp}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value == "No") {
                        const vp = e.target.value;
                        setFieldValue("vp", vp);
                        setFieldValue("vpmeses", '');
                        setFieldValue("vphijos", '');
                        setFieldValue("vpnietos", '');
                        setFieldValue("vppareja", '');
                        setFieldValue("vpyernonuera", '');
                        setFieldValue("vpotros", '');
                        setFieldValue("vptestigo", '');
                        setFieldValue("vpintoxicado", '');
                        setFieldValue("vpeconomico", '');
                        setFieldValue("vpdomicilio", '');
                        setFieldValue("vpdefensa", '');
                        setFieldValue("vpdefensahijos", '');
                        setFieldValue("vpdefensanietos", '');
                        setFieldValue("vpdefensapareja", '');
                        setFieldValue("vpdefensayerno", '');
                        setFieldValue("vpdefensaotros", '');
                        setFieldValue("vpinforma", '');
                        setFieldValue("vpinformafamiliares", '');
                        setFieldValue("vpinformaamigos", '');
                        setFieldValue("vpinformasacerdote", '');
                        setFieldValue("vpinformahospital", '');
                        setFieldValue("vpinformabienestar", '');
                        setFieldValue("vpinformapolicia", '');
                        setFieldValue("vpnoinformanosabe", '');
                        setFieldValue("vpnoinformanoapoyo", '');
                        setFieldValue("vpnoinformanoperjudicar", '');
                        setFieldValue("vpnoinformaempeoraria", '');
                      }
                    }}
                    array={SINO}
                  />
                  {touched.vp && errors.vp ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.vp}
                    </div>
                  ) : null}
                </div>
                {values.vp == "Si" && (
                  <>
                    <div className="col-span-12">
                      <Input
                        label="4.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                        name="vpmeses"
                        type="text"
                        placeholder="Inserta cuanto tiempo"
                        value={values.vpmeses}
                        onChange={handleChange}
                      />
                      {touched.vpmeses && errors.vpmeses ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vpmeses}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                      <h2 className="text-base font-bold leading-7 text-gray-900">
                        ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                      </h2>
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Hijos/Hijas"
                        name="vphijos"
                        placeholder="Hijos"
                        value={values.vphijos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.vphijos && errors.vphijos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vphijos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Nietos/Nietas"
                        name="vpnietos"
                        placeholder="Nietos"
                        value={values.vpnietos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.vpnietos && errors.vpnietos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vpnietos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Pareja"
                        name="vppareja"
                        placeholder="Pareja"
                        value={values.vppareja}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.vppareja && errors.vppareja ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vppareja}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Yerno/Nuera"
                        name="vpyernonuera"
                        placeholder="Yerno"
                        value={values.vpyernonuera}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.vpyernonuera && errors.vpyernonuera ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vpyernonuera}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Otros"
                        name="vpotros"
                        placeholder="Otros"
                        value={values.vpotros}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.vpotros && errors.vpotros ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vpotros}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Select
                        label="No sabe, pero lo atestiguó"
                        name="vptestigo"
                        value={values.vptestigo}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.vptestigo && errors.vptestigo ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.vptestigo}
                        </div>
                      ) : null}
                    </div>
                    {values.familia === "Si" && (
                      <>
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="4.3 ¿Los gritos, hacerlo sentir menos, el trato infantil, ocurre cuando el agresor se encuentra intoxicado (tomó alcohol, alguna droga)?"
                            name="vpintoxicado"
                            value={values.vpintoxicado}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vpintoxicado && errors.vpintoxicado ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vpintoxicado}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿El/la/los agresores comparten el domicilio que la PAM objeto de violencia?"
                            name="vpdomicilio"
                            value={values.vpdomicilio}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vpdomicilio && errors.vpdomicilio ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vpdomicilio}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                            name="vpeconomico"
                            value={values.vpeconomico}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vpeconomico && errors.vpeconomico ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vpeconomico}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-12">
                          <Select
                            label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                            name="vpdefensa"
                            value={values.vpdefensa}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value === "No") {
                                setFieldValue('vpdefensahijos', "")
                                setFieldValue('vpdefensanietos', "")
                                setFieldValue('vpdefensapareja', "")
                                setFieldValue('vpdefensayerno', "")
                                setFieldValue('vpdefensaotros', "")
                              }
                            }}
                            array={SINO}
                          />
                          {touched.vpdefensa && errors.vpdefensa ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.vpdefensa}
                            </div>
                          ) : null}
                        </div>
                        {values.vpdefensa == "Si" && (
                          <>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Hijos/Hijas"
                                name="vpdefensahijos"
                                placeholder="Hijos"
                                value={values.vpdefensahijos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.vpdefensahijos && errors.vpdefensahijos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vpdefensahijos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Nietos/Nietas"
                                name="vpdefensanietos"
                                placeholder="Nietos"
                                value={values.vpdefensanietos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.vpdefensanietos && errors.vpdefensanietos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vpdefensanietos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Pareja/esposo/esposa"
                                name="vpdefensapareja"
                                placeholder="Pareja/esposo/esposa"
                                value={values.vpdefensapareja}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.vpdefensapareja && errors.vpdefensapareja ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vpdefensapareja}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Yerno/Nuera"
                                name="vpdefensayerno"
                                placeholder="Yerno"
                                value={values.vpdefensayerno}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.vpdefensayerno && errors.vpdefensayerno ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vpdefensayerno}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Otras personas que no son familiares directos"
                                name="vpdefensaotros"
                                placeholder="Otros"
                                value={values.vpdefensaotros}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.vpdefensaotros && errors.vpdefensaotros ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vpdefensaotros}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="4.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta situación? (indique todos los casos que apliquen)"
                            name="vpinforma"
                            value={values.vpinforma}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vpinforma && errors.vpinforma ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.vpinforma}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Familiares"
                            name="vpinformafamiliares"
                            value={values.vpinformafamiliares}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vpinformafamiliares &&
                            errors.vpinformafamiliares ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vpinformafamiliares}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Amigos"
                            name="vpinformaamigos"
                            value={values.vpinformaamigos}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vpinformaamigos && errors.vpinformaamigos ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vpinformaamigos}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Sacerdote"
                            name="vpinformasacerdote"
                            value={values.vpinformasacerdote}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vpinformasacerdote &&
                            errors.vpinformasacerdote ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vpinformasacerdote}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Hospital"
                            name="vpinformahospital"
                            value={values.vpinformahospital}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vpinformahospital &&
                            errors.vpinformahospital ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vpinformahospital}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Bienestar Social"
                            name="vpinformabienestar"
                            value={values.vpinformabienestar}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vpinformabienestar &&
                            errors.vpinformabienestar ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vpinformabienestar}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Policia"
                            name="vpinformapolicia"
                            value={values.vpinformapolicia}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.vpinformapolicia &&
                            errors.vpinformapolicia ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.vpinformapolicia}
                            </div>
                          ) : null}
                        </div>
                        {
                          values.vpinforma === "No" && (
                            <>
                              <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                                <h2 className="text-base font-bold leading-7 text-gray-900">
                                  Si no informó, ¿por qué no lo hizo?
                                </h2>
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No sabía a quién acudir"
                                  name="vpnoinformanosabe"
                                  value={values.vpnoinformanosabe}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.vpnoinformanosabe &&
                                  errors.vpnoinformanosabe ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.vpnoinformanosabe}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                  name="vpnoinformanoapoyo"
                                  value={values.vpnoinformanoapoyo}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.vpnoinformanoapoyo &&
                                  errors.vpnoinformanoapoyo ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.vpnoinformanoapoyo}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No quiero perjudicar a mis familiares"
                                  name="vpnoinformanoperjudicar"
                                  value={values.vpnoinformanoperjudicar}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.vpnoinformanoperjudicar &&
                                  errors.vpnoinformanoperjudicar ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.vpnoinformanoperjudicar}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Empeoraría la situación"
                                  name="vpnoinformaempeoraria"
                                  value={values.vpnoinformaempeoraria}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.vpnoinformaempeoraria &&
                                  errors.vpnoinformaempeoraria ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.vpnoinformaempeoraria}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )
                        }
                      </>
                    )}
                  </>
                )}
                <div className="border-b border-t border-gray-900/10 col-span-12 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    Información SEXUAL
                  </h2>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
                <div className="col-span-12">
                  <div className="rounded-md bg-yellow-50 p-2">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Atención</h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>
                            Si el valor se encuentra en "Si" y los demás campos tienen valores, cuando usted cambie a "No", los valores de los siguientes campos, serán limpiados, en caso de que se no quiera eliminar esos datos, no guarde la edición de esta denuncia.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Select
                    label="5. ¿Alguien lo/la ha estado tocando en sus partes íntimas y/o lo/la ha obligado/a a tener algún contacto sexual sin su consentimiento?"
                    name="sexual"
                    value={values.sexual}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value == "No") {
                        const sexual = e.target.value;
                        setFieldValue("sexual", sexual);
                        setFieldValue("sexualmeses", '');
                        setFieldValue("sexualhijos", '');
                        setFieldValue("sexualnietos", '');
                        setFieldValue("sexualpareja", '');
                        setFieldValue("sexualyernonuera", '');
                        setFieldValue("sexualotros", '');
                        setFieldValue("sexualtestigo", '');
                        setFieldValue("sexualintoxicado", '');
                        setFieldValue("sexualeconomico", '');
                        setFieldValue("sexualdomicilio", '');
                        setFieldValue("sexualdefensa", '');
                        setFieldValue("sexualdefensahijos", '');
                        setFieldValue("sexualdefensanietos", '');
                        setFieldValue("sexualdefensapareja", '');
                        setFieldValue("sexualdefensayerno", '');
                        setFieldValue("sexualdefensaotros", '');
                        setFieldValue("sexualinforma", '');
                        setFieldValue("sexualinformafamiliares", '');
                        setFieldValue("sexualinformaamigos", '');
                        setFieldValue("sexualinformasacerdote", '');
                        setFieldValue("sexualinformahospital", '');
                        setFieldValue("sexualinformabienestar", '');
                        setFieldValue("sexualinformapolicia", '');
                        setFieldValue("sexualnoinformanosabe", '');
                        setFieldValue("sexualnoinformanoapoyo", '');
                        setFieldValue("sexualnoinformanoperjudicar", '');
                        setFieldValue("sexualnoinformaempeoraria", '');
                      }
                    }}
                    array={SINO}
                  />
                  {touched.sexual && errors.sexual ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.sexual}
                    </div>
                  ) : null}
                </div>
                {values.sexual === "Si" && (
                  <>
                    <div className="col-span-12">
                      <Input
                        label="5.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                        name="sexualmeses"
                        type="text"
                        placeholder="Inserta cuanto tiempo"
                        value={values.sexualmeses}
                        onChange={handleChange}
                      />
                      {touched.sexualmeses && errors.sexualmeses ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.sexualmeses}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                      <h2 className="text-base font-bold leading-7 text-gray-900">
                        ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                      </h2>
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Hijos/Hijas"
                        placeholder="Hijos"
                        name="sexualhijos"
                        value={values.sexualhijos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.sexualhijos && errors.sexualhijos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.sexualhijos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Nietos/Nietas"
                        name="sexualnietos"
                        placeholder="Nietos"
                        value={values.sexualnietos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.sexualnietos && errors.sexualnietos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.sexualnietos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Pareja"
                        placeholder="Pareja"
                        name="sexualpareja"
                        value={values.sexualpareja}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.sexualpareja && errors.sexualpareja ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.sexualpareja}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Yerno/Nuera"
                        name="sexualyernonuera"
                        placeholder="Yerno / Nuera"
                        value={values.sexualyernonuera}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.sexualyernonuera && errors.sexualyernonuera ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.sexualyernonuera}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Otros"
                        name="sexualotros"
                        placeholder="Otros"
                        value={values.sexualotros}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.sexualotros && errors.sexualotros ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.sexualotros}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Select
                        label="No sabe, pero lo atestiguó"
                        name="sexualtestigo"
                        value={values.sexualtestigo}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.sexualtestigo && errors.sexualtestigo ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.sexualtestigo}
                        </div>
                      ) : null}
                    </div>
                    {values.familia === "Si" && (
                      <>
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="5.3 ¿Estos episodios ocurren cuando el agresor se encuentra intoxicado (tomó alcohol, alguna droga)?"
                            name="sexualintoxicado"
                            value={values.sexualintoxicado}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.sexualintoxicado &&
                            errors.sexualintoxicado ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.sexualintoxicado}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿El/la/los agresores viven en el mismo domicilio que la PAM?"
                            name="sexualdomicilio"
                            value={values.sexualdomicilio}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.sexualdomicilio && errors.sexualdomicilio ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.sexualdomicilio}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                            name="sexualeconomico"
                            value={values.sexualeconomico}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.sexualeconomico && errors.sexualeconomico ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.sexualeconomico}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                            name="sexualeconomico"
                            value={values.sexualeconomico}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.sexualeconomico && errors.sexualeconomico ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.sexualeconomico}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-12">
                          <Select
                            label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                            name="sexualdefensa"
                            value={values.sexualdefensa}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value === "No") {
                                setFieldValue('sexualdefensahijos', "")
                                setFieldValue('sexualdefensanietos', "")
                                setFieldValue('sexualdefensapareja', "")
                                setFieldValue('sexualdefensayerno', "")
                                setFieldValue('sexualdefensaotros', "")
                              }
                            }}
                            array={SINO}
                          />
                          {touched.sexualdefensa && errors.sexualdefensa ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.sexualdefensa}
                            </div>
                          ) : null}
                        </div>
                        {values.sexualdefensa == "Si" && (
                          <>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Hijos/Hijas"
                                name="sexualdefensahijos"
                                placeholder="Hijos"
                                value={values.sexualdefensahijos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.sexualdefensahijos && errors.sexualdefensahijos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.sexualdefensahijos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Nietos/Nietas"
                                name="sexualdefensanietos"
                                placeholder="Nietos"
                                value={values.sexualdefensanietos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.sexualdefensanietos && errors.sexualdefensanietos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.sexualdefensanietos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Pareja/esposo/esposa"
                                name="sexualdefensapareja"
                                placeholder="Pareja/esposo/esposa"
                                value={values.sexualdefensapareja}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.sexualdefensapareja && errors.sexualdefensapareja ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.sexualdefensapareja}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Yerno/Nuera"
                                name="sexualdefensayerno"
                                placeholder="Yerno"
                                value={values.sexualdefensayerno}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.sexualdefensayerno && errors.sexualdefensayerno ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.sexualdefensayerno}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Otras personas que no son familiares directos"
                                name="sexualdefensaotros"
                                placeholder="Otros"
                                value={values.sexualdefensaotros}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.sexualdefensaotros && errors.sexualdefensaotros ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.sexualdefensaotros}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="5.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta situación? (indique todos los casos que apliquen)"
                            name="sexualinforma"
                            value={values.sexualinforma}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.sexualinforma && errors.sexualinforma ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.sexualinforma}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Familiares"
                            name="sexualinformafamiliares"
                            value={values.sexualinformafamiliares}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.sexualinformafamiliares &&
                            errors.sexualinformafamiliares ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.sexualinformafamiliares}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Amigos"
                            name="sexualinformaamigos"
                            value={values.sexualinformaamigos}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.sexualinformaamigos &&
                            errors.sexualinformaamigos ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.sexualinformaamigos}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Sacerdote"
                            name="sexualinformasacerdote"
                            value={values.sexualinformasacerdote}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.sexualinformasacerdote &&
                            errors.sexualinformasacerdote ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.sexualinformasacerdote}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Hospital"
                            name="sexualinformahospital"
                            value={values.sexualinformahospital}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.sexualinformahospital &&
                            errors.sexualinformahospital ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.sexualinformahospital}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Bienestar Social"
                            name="sexualinformabienestar"
                            value={values.sexualinformabienestar}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.sexualinformabienestar &&
                            errors.sexualinformabienestar ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.sexualinformabienestar}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Policia"
                            name="sexualinformapolicia"
                            value={values.sexualinformapolicia}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.sexualinformapolicia &&
                            errors.sexualinformapolicia ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.sexualinformapolicia}
                            </div>
                          ) : null}
                        </div>
                        {
                          values.sexualinforma === "No" && (
                            <>
                              <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                                <h2 className="text-base font-bold leading-7 text-gray-900">
                                  Si no informó, ¿por qué no lo hizo?
                                </h2>
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No sabía a quién acudir"
                                  name="sexualnoinformanosabe"
                                  value={values.sexualnoinformanosabe}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.sexualnoinformanosabe &&
                                  errors.sexualnoinformanosabe ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.sexualnoinformanosabe}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                  name="sexualnoinformanoapoyo"
                                  value={values.sexualnoinformanoapoyo}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.sexualnoinformanoapoyo &&
                                  errors.sexualnoinformanoapoyo ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.sexualnoinformanoapoyo}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No quiero perjudicar a mis familiares"
                                  name="sexualnoinformanoperjudicar"
                                  value={values.sexualnoinformanoperjudicar}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.sexualnoinformanoperjudicar &&
                                  errors.sexualnoinformanoperjudicar ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.sexualnoinformanoperjudicar}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Empeoraría la situación"
                                  name="sexualnoinformaempeoraria"
                                  value={values.sexualnoinformaempeoraria}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.sexualnoinformaempeoraria &&
                                  errors.sexualnoinformaempeoraria ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.sexualnoinformaempeoraria}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )
                        }
                      </>
                    )}
                  </>
                )}
                <div className="border-b border-t border-gray-900/10 col-span-12 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    Información DINERO
                  </h2>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
                <div className="col-span-12">
                  <div className="rounded-md bg-yellow-50 p-2">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Atención</h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>
                            Si el valor se encuentra en "Si" y los demás campos tienen valores, cuando usted cambie a "No", los valores de los siguientes campos, serán limpiados, en caso de que se no quiera eliminar esos datos, no guarde la edición de esta denuncia.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Select
                    label="6. ¿Alguien utilizó/tomó su dinero sin su consentimiento y/o la/lo despojó de algún bien inmueble (casa, locales, terrenos)?"
                    name="dinero"
                    value={values.dinero}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value == "No") {
                        const dinero = e.target.value;
                        setFieldValue("dinero", dinero);
                        setFieldValue("dineromeses", '');
                        setFieldValue("dinerohijos", '');
                        setFieldValue("dineronietos", '');
                        setFieldValue("dineropareja", '');
                        setFieldValue("dineroyernonuera", '');
                        setFieldValue("dinerootros", '');
                        setFieldValue("dinerotestigo", '');
                        setFieldValue("dinerointoxicado", '');
                        setFieldValue("dineroeconomico", '');
                        setFieldValue("dinerodomicilio", '');
                        setFieldValue("dinerodefensa", '');
                        setFieldValue("dinerodefensahijos", '');
                        setFieldValue("dinerodefensanietos", '');
                        setFieldValue("dinerodefensapareja", '');
                        setFieldValue("dinerodefensayerno", '');
                        setFieldValue("dinerodefensaotros", '');
                        setFieldValue("dineroinforma", '');
                        setFieldValue("dineroinformafamiliares", '');
                        setFieldValue("dineroinformaamigos", '');
                        setFieldValue("dineroinformasacerdote", '');
                        setFieldValue("dineroinformahospital", '');
                        setFieldValue("dineroinformabienestar", '');
                        setFieldValue("dineroinformapolicia", '');
                        setFieldValue("dineronoinformanosabe", '');
                        setFieldValue("dineronoinformanoapoyo", '');
                        setFieldValue("dineronoinformanoperjudicar", '');
                        setFieldValue("dineronoinformaempeoraria", '');
                      }
                    }}
                    array={SINO}
                  />
                  {touched.dinero && errors.dinero ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.dinero}
                    </div>
                  ) : null}
                </div>
                {values.dinero == "Si" && (
                  <>
                    <div className="col-span-12">
                      <Input
                        label="6.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                        name="dineromeses"
                        placeholder="Inserta cuanto tiempo"
                        type="text"
                        value={values.dineromeses}
                        onChange={handleChange}
                      />
                      {touched.dineromeses && errors.dineromeses ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.dineromeses}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                      <h2 className="text-base font-bold leading-7 text-gray-900">
                        ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                      </h2>
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Hijos/Hijas"
                        name="dinerohijos"
                        placeholder="Hijos"
                        value={values.dinerohijos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.dinerohijos && errors.dinerohijos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.dinerohijos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Nietos/Nietas"
                        name="dineronietos"
                        placeholder="Nietos"
                        value={values.dineronietos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.dineronietos && errors.dineronietos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.dineronietos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Pareja"
                        name="dineropareja"
                        placeholder="Pareja"
                        value={values.dineropareja}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.dineropareja && errors.dineropareja ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.dineropareja}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Yerno/Nuera"
                        name="dineroyernonuera"
                        placeholder="Yerno/Nuera"
                        value={values.dineroyernonuera}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.dineroyernonuera && errors.dineroyernonuera ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.dineroyernonuera}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Otros"
                        name="dinerootros"
                        placeholder="Otros"
                        value={values.dinerootros}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.dinerootros && errors.dinerootros ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.dinerootros}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Select
                        label="No sabe, pero lo atestiguó"
                        name="dinerotestigo"
                        value={values.dinerotestigo}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.dinerotestigo && errors.dinerotestigo ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.dinerotestigo}
                        </div>
                      ) : null}
                    </div>
                    {values.familia === "Si" && (
                      <>
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="6.3 ¿El uso/sustracción de recursos y/o el despojo de bienes inmuebles ocurre cuando la persona se encuentra con problemas económicos?"
                            name="dinerointoxicado"
                            value={values.dinerointoxicado}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.dinerointoxicado &&
                            errors.dinerointoxicado ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.dinerointoxicado}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿El/la/las personas que usan/toman su dinero y/o lo despojan de bienes inmuebles comparten el domicilio que la PAM objeto de violencia?"
                            name="dinerodomicilio"
                            value={values.dinerodomicilio}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.dinerodomicilio && errors.dinerodomicilio ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.dinerodomicilio}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                            name="dineroeconomico"
                            value={values.dineroeconomico}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.dineroeconomico && errors.dineroeconomico ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.dineroeconomico}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-12">
                          <Select
                            label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                            name="dinerodefensa"
                            value={values.dinerodefensa}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value === "No") {
                                setFieldValue('dinerodefensahijos', "")
                                setFieldValue('dinerodefensanietos', "")
                                setFieldValue('dinerodefensapareja', "")
                                setFieldValue('dinerodefensayerno', "")
                                setFieldValue('dinerodefensaotros', "")
                              }
                            }}
                            array={SINO}
                          />
                          {touched.dinerodefensa && errors.dinerodefensa ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.dinerodefensa}
                            </div>
                          ) : null}
                        </div>
                        {values.dinerodefensa == "Si" && (
                          <>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Hijos/Hijas"
                                name="dinerodefensahijos"
                                placeholder="Hijos"
                                value={values.dinerodefensahijos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.dinerodefensahijos && errors.dinerodefensahijos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.dinerodefensahijos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Nietos/Nietas"
                                name="dinerodefensanietos"
                                placeholder="Nietos"
                                value={values.dinerodefensanietos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.dinerodefensanietos && errors.dinerodefensanietos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.dinerodefensanietos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Pareja/esposo/esposa"
                                name="dinerodefensapareja"
                                placeholder="Pareja/esposo/esposa"
                                value={values.dinerodefensapareja}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.dinerodefensapareja && errors.dinerodefensapareja ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.dinerodefensapareja}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Yerno/Nuera"
                                name="dinerodefensayerno"
                                placeholder="Yerno"
                                value={values.dinerodefensayerno}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.dinerodefensayerno && errors.dinerodefensayerno ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.dinerodefensayerno}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Otras personas que no son familiares directos"
                                name="dinerodefensaotros"
                                placeholder="Otros"
                                value={values.dinerodefensaotros}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.dinerodefensaotros && errors.dinerodefensaotros ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.dinerodefensaotros}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="6.4 En caso hayan utilizado estos recursos sin su consentimiento, ¿La PAM ha informado estos hechos a alguna persona? (indique todos los casos que apliquen)"
                            name="dineroinforma"
                            value={values.dineroinforma}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.dineroinforma && errors.dineroinforma ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.dineroinforma}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Familiares"
                            name="dineroinformafamiliares"
                            value={values.dineroinformafamiliares}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.dineroinformafamiliares &&
                            errors.dineroinformafamiliares ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.dineroinformafamiliares}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Amigos"
                            name="dineroinformaamigos"
                            value={values.dineroinformaamigos}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.dineroinformaamigos &&
                            errors.dineroinformaamigos ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.dineroinformaamigos}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Sacerdote"
                            name="dineroinformasacerdote"
                            value={values.dineroinformasacerdote}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.dineroinformasacerdote &&
                            errors.dineroinformasacerdote ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.dineroinformasacerdote}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Hospital"
                            name="dineroinformahospital"
                            value={values.dineroinformahospital}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.dineroinformahospital &&
                            errors.dineroinformahospital ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.dineroinformahospital}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Bienestar Social"
                            name="dineroinformabienestar"
                            value={values.dineroinformabienestar}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.dineroinformabienestar &&
                            errors.dineroinformabienestar ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.dineroinformabienestar}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Policia"
                            name="dineroinformapolicia"
                            value={values.dineroinformapolicia}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.dineroinformapolicia &&
                            errors.dineroinformapolicia ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.dineroinformapolicia}
                            </div>
                          ) : null}
                        </div>
                        {
                          values.dineroinforma === "No" && (
                            <>
                              <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                                <h2 className="text-base font-bold leading-7 text-gray-900">
                                  Si no informó, ¿por qué no lo hizo?
                                </h2>
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No sabía a quién acudir"
                                  name="dineronoinformanosabe"
                                  value={values.dineronoinformanosabe}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.dineronoinformanosabe &&
                                  errors.dineronoinformanosabe ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.dineronoinformanosabe}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                  name="dineronoinformanoapoyo"
                                  value={values.dineronoinformanoapoyo}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.dineronoinformanoapoyo &&
                                  errors.dineronoinformanoapoyo ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.dineronoinformanoapoyo}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No quiero perjudicar a mis familiares"
                                  name="dineronoinformanoperjudicar"
                                  value={values.dineronoinformanoperjudicar}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.dineronoinformanoperjudicar &&
                                  errors.dineronoinformanoperjudicar ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.dineronoinformanoperjudicar}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Empeoraría la situación"
                                  name="dineronoinformaempeoraria"
                                  value={values.dineronoinformaempeoraria}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.dineronoinformaempeoraria &&
                                  errors.dineronoinformaempeoraria ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.dineronoinformaempeoraria}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )
                        }
                      </>
                    )}
                  </>
                )}
                <div className="border-b border-t border-gray-900/10 col-span-12 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    Información MOVILIDAD
                  </h2>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
                <div className="col-span-12">
                  <div className="rounded-md bg-yellow-50 p-2">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Atención</h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>
                            Si el valor se encuentra en "Si" y los demás campos tienen valores, cuando usted cambie a "No", los valores de los siguientes campos, serán limpiados, en caso de que se no quiera eliminar esos datos, no guarde la edición de esta denuncia.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Select
                    label="7.  ¿Alguien le impide salir de su casa sin motivo aparente, visitar y/o llamar por teléfono a otros familiares/amigos??"
                    name="movilidad"
                    value={values.movilidad}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value == "No") {
                        const movilidad = e.target.value;
                        setFieldValue("movilidad", movilidad);
                        setFieldValue("movilidadmeses", '');
                        setFieldValue("movilidadhijos", '');
                        setFieldValue("movilidadnietos", '');
                        setFieldValue("movilidadpareja", '');
                        setFieldValue("movilidadyernonuera", '');
                        setFieldValue("movilidadotros", '');
                        setFieldValue("movilidadtestigo", '');
                        setFieldValue("movilidadintoxicado", '');
                        setFieldValue("movilidadeconomico", '');
                        setFieldValue("movilidaddomicilio", '');
                        setFieldValue("movilidaddefensa", '');
                        setFieldValue("movilidaddefensahijos", '');
                        setFieldValue("movilidaddefensanietos", '');
                        setFieldValue("movilidaddefensapareja", '');
                        setFieldValue("movilidaddefensayerno", '');
                        setFieldValue("movilidaddefensaotros", '');
                        setFieldValue("movilidadinforma", '');
                        setFieldValue("movilidadinformafamiliares", '');
                        setFieldValue("movilidadinformaamigos", '');
                        setFieldValue("movilidadinformasacerdote", '');
                        setFieldValue("movilidadinformahospital", '');
                        setFieldValue("movilidadinformabienestar", '');
                        setFieldValue("movilidadinformapolicia", '');
                        setFieldValue("movilidadnoinformanosabe", '');
                        setFieldValue("movilidadnoinformanoapoyo", '');
                        setFieldValue("movilidadnoinformanoperjudicar", '');
                        setFieldValue("movilidadnoinformaempeoraria", '');
                      }
                    }}
                    array={SINO}
                  />
                  {touched.movilidad && errors.movilidad ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.movilidad}
                    </div>
                  ) : null}
                </div>
                {values.movilidad == "Si" && (
                  <>
                    <div className="col-span-12">
                      <Input
                        label="7.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                        name="movilidadmeses"
                        placeholder="Inserta cuanto tiempo"
                        type="text"
                        value={values.movilidadmeses}
                        onChange={handleChange}
                      />
                      {touched.movilidadmeses && errors.movilidadmeses ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.movilidadmeses}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                      <h2 className="text-base font-bold leading-7 text-gray-900">
                        ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                      </h2>
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Hijos/Hijas"
                        name="movilidadhijos"
                        placeholder="Hijos"
                        value={values.movilidadhijos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.movilidadhijos && errors.movilidadhijos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.movilidadhijos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Nietos/Nietas"
                        name="movilidadnietos"
                        placeholder="Nietos"
                        value={values.movilidadnietos}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.movilidadnietos && errors.movilidadnietos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.movilidadnietos}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Pareja"
                        name="movilidadpareja"
                        placeholder="Pareja"
                        value={values.movilidadpareja}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.movilidadpareja && errors.movilidadpareja ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.movilidadpareja}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Yerno/Nuera"
                        placeholder="Yerno/Nuera"
                        name="movilidadyernonuera"
                        value={values.movilidadyernonuera}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.movilidadyernonuera &&
                        errors.movilidadyernonuera ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.movilidadyernonuera}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Input
                        label="Otros"
                        placeholder="Otros"
                        name="movilidadotros"
                        value={values.movilidadotros}
                        onChange={handleChange}
                        type="text"
                      />
                      {touched.movilidadotros && errors.movilidadotros ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.movilidadotros}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                      <Select
                        label="No sabe, pero lo atestiguó"
                        name="movilidadtestigo"
                        value={values.movilidadtestigo}
                        onChange={handleChange}
                        array={SINO}
                      />
                      {touched.movilidadtestigo && errors.movilidadtestigo ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" />{" "}
                          {errors.movilidadtestigo}
                        </div>
                      ) : null}
                    </div>
                    {values.familia === "Si" && (
                      <>
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="7.3 ¿El uso/sustracción de recursos y/o el despojo de bienes inmuebles ocurre cuando la persona se encuentra con problemas económicos?"
                            name="movilidadintoxicado"
                            value={values.movilidadintoxicado}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.movilidadintoxicado &&
                            errors.movilidadintoxicado ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.movilidadintoxicado}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿El/la/las personas que usan/toman su dinero y/o lo despojan de bienes inmuebles comparten el domicilio que la PAM objeto de violencia?"
                            name="movilidaddomicilio"
                            value={values.movilidaddomicilio}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.movilidaddomicilio &&
                            errors.movilidaddomicilio ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.movilidaddomicilio}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                            name="movilidadeconomico"
                            value={values.movilidadeconomico}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.movilidadeconomico &&
                            errors.movilidadeconomico ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.movilidadeconomico}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-12">
                          <Select
                            label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                            name="movilidaddefensa"
                            value={values.movilidaddefensa}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value === "No") {
                                setFieldValue('movilidaddefensahijos', "")
                                setFieldValue('movilidaddefensanietos', "")
                                setFieldValue('movilidaddefensapareja', "")
                                setFieldValue('movilidaddefensayerno', "")
                                setFieldValue('movilidaddefensaotros', "")
                              }
                            }}
                            array={SINO}
                          />
                          {touched.movilidaddefensa && errors.movilidaddefensa ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.movilidaddefensa}
                            </div>
                          ) : null}
                        </div>
                        {values.movilidaddefensa == "Si" && (
                          <>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Hijos/Hijas"
                                name="movilidaddefensahijos"
                                placeholder="Hijos"
                                value={values.movilidaddefensahijos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.movilidaddefensahijos && errors.movilidaddefensahijos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.movilidaddefensahijos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Nietos/Nietas"
                                name="movilidaddefensanietos"
                                placeholder="Nietos"
                                value={values.movilidaddefensanietos}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.movilidaddefensanietos && errors.movilidaddefensanietos ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.movilidaddefensanietos}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Pareja/esposo/esposa"
                                name="movilidaddefensapareja"
                                placeholder="Pareja/esposo/esposa"
                                value={values.movilidaddefensapareja}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.movilidaddefensapareja && errors.movilidaddefensapareja ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.movilidaddefensapareja}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Yerno/Nuera"
                                name="movilidaddefensayerno"
                                placeholder="Yerno"
                                value={values.movilidaddefensayerno}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.movilidaddefensayerno && errors.movilidaddefensayerno ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.movilidaddefensayerno}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Input
                                label="Otras personas que no son familiares directos"
                                name="movilidaddefensaotros"
                                placeholder="Otros"
                                value={values.movilidaddefensaotros}
                                onChange={handleChange}
                                type="text"
                              />
                              {touched.movilidaddefensaotros && errors.movilidaddefensaotros ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.movilidaddefensaotros}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12">
                          <Select
                            label="7.4 En caso alguien le impida poder salir de su casa sin motivo aparente, visitar y/o llamar por teléfono a otros familiares/amigos, ¿La PAM ha informado estos hechos a alguna persona? (indique todos los casos que apliquen)"
                            name="movilidadinforma"
                            value={values.movilidadinforma}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.movilidadinforma &&
                            errors.movilidadinforma ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.movilidadinforma}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Familiares"
                            name="movilidadinformafamiliares"
                            value={values.movilidadinformafamiliares}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.movilidadinformafamiliares &&
                            errors.movilidadinformafamiliares ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.movilidadinformafamiliares}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Amigos"
                            name="movilidadinformaamigos"
                            value={values.movilidadinformaamigos}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.movilidadinformaamigos &&
                            errors.movilidadinformaamigos ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.movilidadinformaamigos}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Sacerdote"
                            name="movilidadinformasacerdote"
                            value={values.movilidadinformasacerdote}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.movilidadinformasacerdote &&
                            errors.movilidadinformasacerdote ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.movilidadinformasacerdote}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Hospital"
                            name="movilidadinformahospital"
                            value={values.movilidadinformahospital}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.movilidadinformahospital &&
                            errors.movilidadinformahospital ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.movilidadinformahospital}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Bienestar Social"
                            name="movilidadinformabienestar"
                            value={values.movilidadinformabienestar}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.movilidadinformabienestar &&
                            errors.movilidadinformabienestar ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.movilidadinformabienestar}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                          <Select
                            label="Policia"
                            name="movilidadinformapolicia"
                            value={values.movilidadinformapolicia}
                            onChange={handleChange}
                            array={SINO}
                          />
                          {touched.movilidadinformapolicia &&
                            errors.movilidadinformapolicia ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.movilidadinformapolicia}
                            </div>
                          ) : null}
                        </div>
                        {
                          values.movilidadinforma === "No" && (
                            <>
                              <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                                <h2 className="text-base font-bold leading-7 text-gray-900">
                                  Si no informó, ¿por qué no lo hizo?
                                </h2>
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No sabía a quién acudir"
                                  name="movilidadnoinformanosabe"
                                  value={values.movilidadnoinformanosabe}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.movilidadnoinformanosabe &&
                                  errors.movilidadnoinformanosabe ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.movilidadnoinformanosabe}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                  name="movilidadnoinformanoapoyo"
                                  value={values.movilidadnoinformanoapoyo}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.movilidadnoinformanoapoyo &&
                                  errors.movilidadnoinformanoapoyo ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.movilidadnoinformanoapoyo}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="No quiero perjudicar a mis familiares"
                                  name="movilidadnoinformanoperjudicar"
                                  value={values.movilidadnoinformanoperjudicar}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.movilidadnoinformanoperjudicar &&
                                  errors.movilidadnoinformanoperjudicar ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.movilidadnoinformanoperjudicar}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-span-12 lg:col-span-6">
                                <Select
                                  label="Empeoraría la situación"
                                  name="movilidadnoinformaempeoraria"
                                  value={values.movilidadnoinformaempeoraria}
                                  onChange={handleChange}
                                  array={SINO}
                                />
                                {touched.movilidadnoinformaempeoraria &&
                                  errors.movilidadnoinformaempeoraria ? (
                                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                    <ErrorIcon fontSize="small" />{" "}
                                    {errors.movilidadnoinformaempeoraria}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )
                        }
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="flex justify-end mt-6">
                {
                  dirty ?
                    !loading ?
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-900 hover:bg-claret-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-claret-500"
                      >
                        Guardar
                      </button>
                      :
                      <button
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-claret-500"
                      >
                        Guardando...
                      </button>

                    : <button
                      disabled
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-claret-500"
                    >
                      Guardar
                    </button>
                }
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default Edit;
