import axios from "axios";

export const del = async (url, data, token) => {
  try {
    const response = await axios.put(process.env.REACT_APP_URL + url , data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
