/* eslint-disable jsx-a11y/no-redundant-roles */
import { CheckCircleIcon } from '@heroicons/react/24/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ComentariosComponents({
  comentarioValue,
  setcomentarioValue,
  comentarios,
  guardar,
  loading,
  role,
  deleteComment,
  updateComment,
  updateCommentario,
  setupdateCommentario
}) {

  const parseDate = (date) => {
    const dateObj = new Date(date)
    return dateObj.toLocaleString()
  }

  return (
    <>
      {
        comentarios.length === 0 && (
          <div className="flex justify-center items-center">
            <div className="w-100" style={{
              width: '500px'
            }}>
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Comentarios</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">No hay comentarios</p>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {comentarios.map((review, reviewIdx) => (
        <div key={review.id} className="flex space-x-4 text-sm text-gray-500">
          <div className="flex-none py-10">
            <img src={
              "https://ui-avatars.com/api/?name=" + (review?.user_id?.email ?? "Cuestionario")
            } alt="" className="h-10 w-10 rounded-full bg-gray-100" />

          </div>
          <div className={classNames(reviewIdx === 0 ? '' : 'border-t border-gray-200', 'flex-1 py-10')}>
            <h3 className="font-medium text-gray-900">{review?.user_id?.email ?? "Desde cuestionario"}</h3>
            <p>
              {parseDate(review?.updatedAt || review?.createdAt)}
            </p>

            <div
              className="prose prose-sm mt-2 max-w-none text-gray-500"
              dangerouslySetInnerHTML={{ __html: review?.description }}
            />
          </div>
          <div className='mt-10'>
            {
              (role === "admin" || role === "super_admin") && (
                < div className="flex items-center space-x-4">
                  <button
                    onClick={() => {
                      setcomentarioValue(review?.description)
                      setupdateCommentario(review?._id)
                    }}
                    id={review?._id}
                    className="bg-indigo-500 p-2 text-white rounded-lg shadow px-4 hover:underline font-bold"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                    </svg>

                  </button>

                  <button
                    onClick={() => {
                      deleteComment(review?._id)
                    }}
                    id={review?._id}
                    className="bg-red-500 p-2 text-white rounded-lg shadow px-3 hover:underline font-bold"
                  >
                    <svg
                      id={review._id}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        id={review._id}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </div>
              )
            }
          </div>
        </div >
      ))
      }

      {/* New comment form */}
      <div className="mt-6 flex gap-x-3" style={{
        width: '500px'
      }}>
        <form className="relative flex-auto" onSubmit={(e) => {
          if (updateCommentario) {
            updateComment(e)
          } else {
            guardar(e)
          }
        }}>
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-gray-200">
            <label htmlFor="comment" className="sr-only">
              Escribe un comentario
            </label>
            <textarea
              rows={2}
              name="comment"
              id="comment"
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Escribe un comentario"
              value={comentarioValue}
              onChange={(e) => setcomentarioValue(e.target.value)}
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-end py-2 pl-3 pr-2">
            {
              !loading && (
                <button
                  type="submit"
                  className="rounded-md bg-teal-500 text-white px-2.5 py-1.5 text-sm font-semibold  shadow-sm hover:bg-teal-600 flex gap-1"
                >
                  {
                    updateCommentario ? (
                      <>
                        <CheckCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        Actualizar
                      </>
                    ) : (
                      <>
                        <CheckCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        Comentar
                      </>
                    )
                  }
                </button>
              )
            }
            {
              loading && (
                <button
                  className="rounded-md bg-teal-500 text-white px-2.5 py-1.5 text-sm font-semibold  shadow-sm hover:bg-teal-600"
                >
                  {
                    updateCommentario ? (
                      "Actualizando..."
                    ) : (
                      "Guardando..."
                    )
                  }
                </button>
              )
            }
          </div>
        </form>
      </div>
    </>
  )
}
