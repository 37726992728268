/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, Fragment, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { get } from "../service/get";
import useLogin from "../hook/useLogin";
import { put } from "../service/put";
import LoaderTable from "./LoaderTable";
import moment from 'moment'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default function Table() {
  const { token, role, municipio } = useLogin();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte_Denuncias_" + moment().local().format('DDMMYYYYHHmm') + fileExtension);
  };

  const Export = ({ onExport }) => (
    <button
      onClick={e => onExport(e.target.value)}
      className="block rounded-md bg-lime-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
    >
      Exportar
    </button>
  );

  const ExpandedComponent = ({ data }) => (
    <pre className="text-xs">{JSON.stringify(data, null, 2)}</pre>
  );

  const columns = [
    {
      name: "Familiar",
      selector: (row) => row?.id_denunciante?.familia ?? "",
      sortable: true,
    },
    {
      name: "Anonimato",
      selector: (row) => row?.id_denunciante?.anonimato ?? "",
      sortable: true,
    },
    {
      name: "Denunciante",
      selector: (row) => row?.id_denunciante?.nombredenunciante ?? "",
      sortable: true,
    },
    {
      name: "PAM",
      selector: (row) => row.pamviolencia,
      sortable: true,
    },
    {
      name: "Municipio PAM",
      selector: (row) => row.municipioPam,
      sortable: true,
    },
    {
      name: "Comunidad",
      selector: (row) => row.comunidadPam,
      sortable: true,
    },
    {
      name: "Fecha",
      selector: (row) => row?.id_denunciante?.createdAt,
      sortable: true,
    },
    {
      name: "Ver",
      button: true,
      cell: (row) => (
        <Link
          to={`/view/` + row?._id}
          rel="noopener noreferrer"
          className="bg-green-500 p-2 text-white rounded-lg shadow px-4 hover:underline font-bold"
        >
          Ver
        </Link>
      ),
    },
    //Solo cuenta principal del estado
    (role === "admin" || role === "super_admin") && {
      name: "Editar",
      button: true,
      cell: (row) => (
        <Link
          to={`/edit/` + row?._id}
          rel="noopener noreferrer"
          className="bg-indigo-500 p-2 text-white rounded-lg shadow px-4 hover:underline font-bold"
        >
          Editar
        </Link>
      ),
    },
    {
      name: "Comentarios",
      button: true,
      cell: (row) => (
        <Link
          to={`/comments/` + row?._id}
          rel="noopener noreferrer"
          className="bg-teal-500 p-2 text-white rounded-lg shadow px-4 hover:underline font-bold"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
          </svg>
        </Link>
      ),
    },
    //Solo cuenta principal del estado
    (role === "admin" || role === "super_admin") && {
      name: "Eliminar",
      button: true,
      cell: (row) => (
        <button
          onClick={() => handleButtonClick(row?.id_denunciante._id)}
          id={row?.id_denunciante._id}
          className="bg-red-500 p-2 text-white rounded-lg shadow px-3 hover:underline font-bold"
        >
          <svg
            id={row._id}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              id={row._id}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      ),
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const [pending, setPending] = useState(true);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [data, setdata] = useState([]);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  const handleButtonClick = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleDelete = () => {
    put("denunciante-delete/" + id, {}, token).then((res) => {
      getInformacion();
      setOpen(false);
    });
  };

  const getInformacion = async () => {
    if (role === "admin") {
      await get("denunciante", token).then((res) => {
        setdata(res);
      });
    } else {
      await get("denunciante/" + municipio, token).then((res) => {
        setdata(res);
      });
    }
  };

  useEffect(() => {
    getInformacion();
    return () => { };
  }, []);


  const actionsMemo = useMemo(() => <Export onExport={() => exportToCSV(data)} />);

  return (
    <div className="px-4 sm:px-6 lg:px-8 pt-36">
      <div className="sm:flex sm:items-center mb-10">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            Todos los registros
          </h1>
          <p className="mt-2 text-md text-gray-700">
            A continuación, se muestran todos los registros, los cuales puede
            exportar, dando clic en el botón <strong>"Exportar"</strong> y
            también puede ordenar por cada uno de los campos, dando clic en el
            nombre de campo, por default, los registros estan ordenados por
            fecha de aplicación
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>

      <div className="shadow-2xl rounded p-2">
        <DataTable
          columns={columns}
          data={data}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          highlightOnHover
          pointerOnHover
          pagination
          fixedHeader
          actions={actionsMemo}
          paginationComponentOptions={paginationComponentOptions}
          progressPending={pending}
          progressComponent={<LoaderTable />}
        />
      </div>
      {open ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Estas a punto de eliminar un registro
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            ¿Estas seguro que quieres eliminar el registro? Esta
                            acción no se puede deshacer.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={() => {
                          handleDelete(id);
                          setOpen(false);
                        }}
                      >
                        Eliminar
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                      >
                        Cancelar
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : (
        ""
      )}
    </div>
  );
}
