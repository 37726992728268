/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { useParams } from "react-router-dom";
import { get } from "../service/get";
import useLogin from "../hook/useLogin";

const View = () => {
  const { token } = useLogin();
  const [data, setdata] = useState({});
  const params = useParams();

  useEffect(() => {
    get("denunciante-pam/" + params.id, token).then((res) => {
      setdata(res);
    });
    return () => { };
  }, []);

  return (
    <Layout>
      <div className="mx-auto px-16 pt-16 lg:pt-36">
        <div className="grid space-y-6 divide-y p-2">
          <div className="border-b border-t border-gray-900/10 col-span-4 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              Datos del denunciante
            </h2>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Es familiar?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.id_denunciante?.familia}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Es anonimo?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.id_denunciante?.anonimato}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Nombre
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.id_denunciante?.nombredenunciante}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Dirección
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.id_denunciante?.direcciondenunciante}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Correo electrónico
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.id_denunciante?.emaildenunciante}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Teléfono:
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.id_denunciante?.teldenunciante}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              CURP
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.id_denunciante?.curpdenunciante}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Fecha de registro
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.id_denunciante?.createdAt}
            </p>
          </div>
          <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              PAM Objeto de violencia
            </h2>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Primera denuncia que se relaciona con esta pam
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.primerapam ?? "No hay denuncias"}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Cantidad de denuncias previas
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.denunciaspreviaspam ?? 0}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Nombre y apellidos
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.pamviolencia}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Dirección
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.pamdireccion}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Correo electrónico
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.emailpamviolencia}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Teléfono
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.telpamviolencia}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              CURP
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.curppamviolencia}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Sexo
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.sexo}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Usa muletas, bastón o silla de ruedas?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.muletasilla}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Edad
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.edad}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Estado civil
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.edocivil}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Ultimo nivel escolar
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.ultimoescolar}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Sabe leer
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.leer}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Sabe escribir
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.escribir}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Pertenece a pueblo
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.indigena}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Habitualmente, La PAM vive en una casa/departamento…
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.viveen}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              {" "}
              ¿Ha dado en herencia la casa/departamento en la cual La PAM
              actualmente vive?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.herenciacasa}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿La PAM ya heredó (otros bienes) a sus hijos/hijas?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.heredo}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Habitualmente, La PAM vive con…
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.vivecon}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Incluyéndolo a La PAM, en el lugar en el cual habitualmente La PAM
              vive… ¿Cuántos personas residen?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.personas}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              De éstos, ¿cuántos son menores de edad?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.menores}
            </p>
          </div>
          <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              En los últimos tres meses, La PAM o algún adulto en la vivienda en
              la cual La PAM habitualmente vive…
            </h2>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Dejó de desayunar, comer o cenar por falta de dinero?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.aliadulto1}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Comió menos de lo que debería comer por falta de dinero?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.aliadulto2}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Tuvo poca variedad en sus alimentos por falta de dinero?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.aliadulto3}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Comió sólo una vez al día o dejó de comer todo un día por falta de
              dinero?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.aliadulto4}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Sintió hambre, pero por falta de dinero no comió?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.aliadulto5}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Se quedó sin comida por falta de dinero?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.aliadulto6}
            </p>
          </div>
          <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              En los últimos tres meses, algún menor…
            </h2>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Dejó de desayunar, comer o cenar por falta de dinero?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.alimenor1}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Comió menos de lo que debería comer por falta de dinero?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.alimenor2}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Tuvo poca variedad en sus alimentos por falta de dinero?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.alimenor3}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Comió sólo una vez al día o dejó de comer todo un día por falta de
              dinero?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.alimenor4}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Sintió hambre, pero por falta de dinero no comió?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.alimenor5}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Se quedó sin comida por falta de dinero?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.alimenor6}
            </p>
          </div>
          <div className="border-b border-t border-gray-900/10 pb-2 pt-2 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow"></div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              La PAM tiene seguridad social por parte de:
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.seguridadsocial}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Si se enferma, ¿a quién acude?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.atnmedica}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿La PAM trabaja?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.trabaja}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Por semana, apróximadamente, ¿cuántas horas dedica al trabajo?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.horastrabajo}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Qué remuneración/ingresos recibe cada quincena por su trabajo?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.remuneracion}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Me pagan en especie (ej. comida, hospedaje)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.soloespecie}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Si tiene otros ingresos monetarios, ¿Apróximadamente qué cantidad
              recibe cada mes? (en pesos)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.otrosingresos}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Qué cantidad tiene ahorrada? (en pesos)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.ahorro}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Qué cantidad debe en este momento (deuda)? (en pesos)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.deuda}
            </p>
          </div>
          <div className="border-b border-t border-gray-900/10 pb-2 pt-2 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow"></div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              La PAM considera necesitar apoyo económico?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.necapoyoeconomico}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Alguien de su familia lo apoya economicamente?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.recibeapoyoeconomico}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Apróximadamente, ¿Con qué cantidad lo apoyan mensualmente? (en
              pesos)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.cantidadapoyoeconomico}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿La PAM considera necesitar apoyo en especie?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.necapoyoespecie}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿Alguien de su familia lo apoya en especie?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.recibeapoyoespecie}
            </p>
          </div>
          <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              ¿Con qué lo apoyan mensualmente?
            </h2>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Alimentos/ropa
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.apoyoespeciealimentos}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Medicinas/pañales/artículos higiene personal
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.apoyoespeciemedicinas}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Traslados/transporte
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.apoyoespecietraslados}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Me dedican tiempo/Están conmigo
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.apoyoespecietiempo}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Otro (cualquiera distinto de los anteriores)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.apoyoespecieotro}
            </p>
          </div>
          <div className="col-span-4 border-t border-b pt-2 pb-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿La PAM proporciona algún apoyo a sus hijos?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.apoyasushijos}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Cuido los nietos
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.cuidonietos}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Llevo los nietos a la escuela
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.llevoalaescuela}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Les doy dinero (ej. para la comida)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.doydinero}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Los hospedo en mi casa
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.vivenenmicasa}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Pago los servicios (ej. luz, agua, gas)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.pagoservicios}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Ayudo en la casa (ej. lavo trastes)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.ayudoenlacasa}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Si no recibiera ayuda (en especie o monetaria) por parte de
              familiares, ¿sus ingresos alcanzan para cubrir sus necesidades?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.alcanza}
            </p>
          </div>
          <div className="border-b border-t border-gray-900/10 pb-2 pt-2 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow"></div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿La PAM está inscrito en el Programa Pensión para el Bienestar y
              más y tiene tarjeta del mismo?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.inscritoprograma65ymas}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿La PAM usa la tarjeta del Programa Pensión para el Bienestar?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.usatarjeta65ymas}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              ¿La PAM decide cómo se utilizan los recursos del Programa Pensión
              para el Bienestar?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.disponerecursos65ymas}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Además de la tarjeta del Programa Pensión para el Bienestar,
              ¿La PAM tiene alguna otra tarjeta/cuenta bancaria?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.cuentabancaria}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Si tiene otras tarjetas o cuentas bancarias, además del Programa
              Pensión para el Bienestar, generalmente ¿las maneja La PAM?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.manejotrascuentas}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Si tiene tarjeta/cuenta bancaria (programa pensión para el
              bienestar u otra), cuando va al cajero automático…
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.atmyayuda}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Si tiene tarjeta/cuenta bancaria (programa pensión para el
              bienestar u otra), ¿cuándo acude al banco, ¿necesita ayuda?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.acudebancoayuda}
            </p>
          </div>
          <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              ¿La PAM padece alguna Enfermedad de la lista a continuación?
              Indique todas las que padece
            </h2>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Asma bronquial
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.asma}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Hipercolesterolemia (colesterol alto)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.colesterol}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Enfermedades cerebrovasculares
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.ecv}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Cardiopatía isquémica (problemas cardíacos)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.cardio}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Hipertensión arterial (presión alta)
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.hipertension}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Cáncer
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.cancer}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              EPOC-Enfermedad pulmonar obstructiva crónica
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.epoc}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Diabetes
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.diabetes}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Osteoporosis
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.osteoporosis}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Artrosis
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.artrosis}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Insuficiencia renal
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.renal}
            </p>
          </div>
          <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              ¿Tiene alguna discapacidad?
            </h2>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Motriz
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.dismotriz}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Auditiva
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.disauditiva}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Visual
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.disvisual}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Del habla
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.dishabla}
            </p>
          </div>
          <div className="pt-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              Otra
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.disotra}
            </p>
          </div>
          {/* VF */}
          <div className="border-b border-t border-gray-900/10 col-span-4 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              Información VF
            </h2>
          </div>
          <div className="col-span-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              1. Recientemente...¿alguien le ha dado golpes, empujones o
              cachetadas?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.vf}
            </p>
          </div>
          {
            data?.vf !== "No" ?
              <Fragment>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    1.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfmeses}
                  </p>
                </div>
                <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                  </h2>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Hijos/Hijas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfhijos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Nietos/Nietas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfnietos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Pareja/esposo/esposa
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfpareja}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Yerno/Nuera
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfyernonuera}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Otras personas que no son familiares directos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfotros}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    No sabe, pero lo atestiguó
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vftestigo}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    1.3 ¿Estos episodios ocurren cuando el agresor se encuentra
                    intoxicado (tomó alcohol, alguna droga)?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfintoxicado}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿El/la/los agresores viven en el mismo domicilio que la PAM?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfdomicilio}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿Estos episodios ocurren cuando el agresor tiene problemas
                    económicos?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfeconomico}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    1.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta
                    situación? (indique todos los casos que apliquen)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfinforma}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Familiares
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfinformafamiliares}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Amigos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfinformaamigos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Sacerdote
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfinformasacerdote}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Centro Salud/hospital
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfinformahospital}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Personal de Bienestar social
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfinformabienestar}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Policia
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vfinformapolicia}
                  </p>
                </div>
                {
                  data?.vfinforma === "No" && (
                    <>
                      <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                        <h2 className="text-base font-bold leading-7 text-gray-900">
                          Si no informó ¿por qué no lo hizo?
                        </h2>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No sabía a quién acudir
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.vfnoinformanosabe}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Había informado en casos de violencia anteriores, pero no recibí
                          apoyo
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.vfnoinformanoapoyo}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No quiero perjudicar a mis familiares
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.vfnoinformanoperjudicar}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Empeoraría la situación
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.vfnoinformaempeoraria}
                        </p>
                      </div>
                    </>
                  )
                }
              </Fragment>
              :
              ""
          }
          {/* MAL */}
          <div className="border-b border-t border-gray-900/10 col-span-4 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              Información MAL
            </h2>
          </div>
          <div className="col-span-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              2. Algunos de sus familiares directos/personas cercanas a La PAM...
              ¿no le hablan y/o no le apoyan, o lo/la dejan solo/a por mucho
              tiempo?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.mal}
            </p>
          </div>
          {
            data?.mal !== "No" ?
              <Fragment>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    2.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malmeses}
                  </p>
                </div>
                <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                  </h2>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Hijos/Hijas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malhijos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Nietos/Nietas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malnietos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Pareja/esposo/esposa
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malpareja}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Yerno/Nuera
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malyernonuera}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Otras personas que no son familiares directos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malotros}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    No sabe, pero lo atestiguó
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.maltestigo}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    2.3 ¿Estos episodios ocurren cuando el agresor se encuentra
                    intoxicado (tomó alcohol, alguna droga)?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malintoxicado}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿El/la/los agresores viven en el mismo domicilio que la PAM?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.maldomicilio}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿Estos episodios ocurren cuando el agresor tiene problemas
                    económicos?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.maleconomico}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    2.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta
                    situación? (indique todos los casos que apliquen)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malinforma}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Familiares
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malinformafamiliares}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Amigos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malinformaamigos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Sacerdote
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malinformasacerdote}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Centro Salud/hospital
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malinformahospital}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Personal de Bienestar social
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malinformabienestar}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Policia
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.malinformapolicia}
                  </p>
                </div>
                {
                  data?.malinforma === "No" && (
                    <>
                      <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                        <h2 className="text-base font-bold leading-7 text-gray-900">
                          Si no informó ¿por qué no lo hizo?
                        </h2>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No sabía a quién acudir
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.malnoinformanosabe}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Había informado en casos de violencia anteriores, pero no recibí
                          apoyo
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.malnoinformanoapoyo}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No quiero perjudicar a mis familiares
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.malnoinformanoperjudicar}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Empeoraría la situación
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.malnoinformaempeoraria}
                        </p>
                      </div>
                    </>
                  )
                }
              </Fragment>
              :
              ""
          }
          {/* ABAND */}
          <div className="border-b border-t border-gray-900/10 col-span-4 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              Información ABAND
            </h2>
          </div>
          <div className="col-span-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              3. ¿La PAM ha sido expulsado/a del hogar por parte de alguno de sus
              familiares (hijos, nietos, pareja)?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.aband}
            </p>
          </div>
          {
            data?.aband !== "No" ?
              <Fragment>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    3.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandmeses}
                  </p>
                </div>
                <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                  </h2>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Hijos/Hijas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandhijos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Nietos/Nietas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandnietos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Pareja/esposo/esposa
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandpareja}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Yerno/Nuera
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandyernonuera}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Otras personas que no son familiares directos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandotros}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    No sabe, pero lo atestiguó
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandtestigo}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    3.3 ¿Estos episodios ocurren cuando el agresor se encuentra
                    intoxicado (tomó alcohol, alguna droga)?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandintoxicado}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿Estos episodios ocurren cuando el agresor tiene problemas
                    económicos?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandeconomico}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    3.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta
                    situación? (indique todos los casos que apliquen)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandinforma}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Sacerdote
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandinformasacerdote}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Centro Salud/hospital
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandinformahospital}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Personal de Bienestar social
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandinformabienestar}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Policia
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.abandinformapolicia}
                  </p>
                </div>
                {
                  data?.abandinforma === "No" && (
                    <>
                      <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                        <h2 className="text-base font-bold leading-7 text-gray-900">
                          Si no informó ¿por qué no lo hizo?
                        </h2>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No sabía a quién acudir
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.abandnoinformanosabe}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Había informado en casos de violencia anteriores, pero no recibí
                          apoyo
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.abandnoinformanoapoyo}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No quiero perjudicar a mis familiares
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.abandnoinformanoperjudicar}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Empeoraría la situación
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.abandnoinformaempeoraria}
                        </p>
                      </div>
                    </>
                  )
                }
              </Fragment>
              :
              ""
          }
          {/* VP */}
          <div className="border-b border-t border-gray-900/10 col-span-4 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              Información VP
            </h2>
          </div>
          <div className="col-span-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              4. Algunos de sus familiares directos/personas cercanas a La PAM...
              ¿no le hablan y/o no le apoyan, o lo/la dejan solo/a por mucho
              tiempo?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.vp}
            </p>
          </div>
          {
            data?.vp !== "No" ?
              <Fragment>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    4.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpmeses}
                  </p>
                </div>
                <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                  </h2>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Hijos/Hijas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vphijos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Nietos/Nietas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpnietos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Pareja/esposo/esposa
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vppareja}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Yerno/Nuera
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpyernonuera}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Otras personas que no son familiares directos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpotros}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    No sabe, pero lo atestiguó
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vptestigo}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    4.3 ¿Estos episodios ocurren cuando el agresor se encuentra
                    intoxicado (tomó alcohol, alguna droga)?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpintoxicado}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿El/la/los agresores viven en el mismo domicilio que la PAM?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpdomicilio}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿Estos episodios ocurren cuando el agresor tiene problemas
                    económicos?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpeconomico}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    4.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta
                    situación? (indique todos los casos que apliquen)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpinforma}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Familiares
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpinformafamiliares}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Amigos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpinformaamigos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Sacerdote
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpinformasacerdote}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Centro Salud/hospital
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpinformahospital}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Personal de Bienestar social
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpinformabienestar}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Policia
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.vpinformapolicia}
                  </p>
                </div>
                {
                  data?.vpinforma === "No" && (
                    <>
                      <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                        <h2 className="text-base font-bold leading-7 text-gray-900">
                          Si no informó ¿por qué no lo hizo?
                        </h2>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No sabía a quién acudir
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.vpnoinformanosabe}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Había informado en casos de violencia anteriores, pero no recibí
                          apoyo
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.vpnoinformanoapoyo}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No quiero perjudicar a mis familiares
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.vpnoinformanoperjudicar}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Empeoraría la situación
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.vpnoinformaempeoraria}
                        </p>
                      </div>
                    </>
                  )
                }
              </Fragment>
              :
              ""
          }

          {/* SEXUAL */}
          <div className="border-b border-t border-gray-900/10 col-span-4 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              Información SEXUAL
            </h2>
          </div>
          <div className="col-span-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              5. Algunos de sus familiares directos/personas cercanas a La PAM...
              ¿no le hablan y/o no le apoyan, o lo/la dejan solo/a por mucho
              tiempo?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.sexual}
            </p>
          </div>
          {
            data?.sexual !== "No" ?
              <Fragment>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    5.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualmeses}
                  </p>
                </div>
                <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                  </h2>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Hijos/Hijas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualhijos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Nietos/Nietas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualnietos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Pareja/esposo/esposa
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualpareja}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Yerno/Nuera
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualyernonuera}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Otras personas que no son familiares directos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualotros}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    No sabe, pero lo atestiguó
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualtestigo}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    5.3 ¿Estos episodios ocurren cuando el agresor se encuentra
                    intoxicado (tomó alcohol, alguna droga)?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualintoxicado}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿El/la/los agresores viven en el mismo domicilio que la PAM?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualdomicilio}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿Estos episodios ocurren cuando el agresor tiene problemas
                    económicos?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualeconomico}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    5.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta
                    situación? (indique todos los casos que apliquen)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualinforma}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Familiares
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualinformafamiliares}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Amigos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualinformaamigos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Sacerdote
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualinformasacerdote}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Centro Salud/hospital
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualinformahospital}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Personal de Bienestar social
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualinformabienestar}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Policia
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.sexualinformapolicia}
                  </p>
                </div>
                {
                  data?.sexualinforma === "No" && (
                    <>
                      <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                        <h2 className="text-base font-bold leading-7 text-gray-900">
                          Si no informó ¿por qué no lo hizo?
                        </h2>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No sabía a quién acudir
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.sexualnoinformanosabe}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Había informado en casos de violencia anteriores, pero no recibí
                          apoyo
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.sexualnoinformanoapoyo}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No quiero perjudicar a mis familiares
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.sexualnoinformanoperjudicar}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Empeoraría la situación
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.sexualnoinformaempeoraria}
                        </p>
                      </div>
                    </>
                  )
                }
              </Fragment>
              :
              ""
          }
          {/* DINERO */}
          <div className="border-b border-t border-gray-900/10 col-span-4 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              Información DINERO
            </h2>
          </div>
          <div className="col-span-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              6. Algunos de sus familiares directos/personas cercanas a La PAM...
              ¿no le hablan y/o no le apoyan, o lo/la dejan solo/a por mucho
              tiempo?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.dinero}
            </p>
          </div>
          {
            data?.dinero !== "No" ?
              <Fragment>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    6.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineromeses}
                  </p>
                </div>
                <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                  </h2>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Hijos/Hijas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dinerohijos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Nietos/Nietas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineronietos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Pareja/esposo/esposa
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineropareja}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Yerno/Nuera
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineroyernonuera}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Otras personas que no son familiares directos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dinerootros}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    No sabe, pero lo atestiguó
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dinerotestigo}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    6.3 ¿Estos episodios ocurren cuando el agresor se encuentra
                    intoxicado (tomó alcohol, alguna droga)?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dinerointoxicado}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿El/la/los agresores viven en el mismo domicilio que la PAM?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dinerodomicilio}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿Estos episodios ocurren cuando el agresor tiene problemas
                    económicos?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineroeconomico}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    6.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta
                    situación? (indique todos los casos que apliquen)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineroinforma}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Familiares
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineroinformafamiliares}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Amigos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineroinformaamigos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Sacerdote
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineroinformasacerdote}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Centro Salud/hospital
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineroinformahospital}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Personal de Bienestar social
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineroinformabienestar}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Policia
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.dineroinformapolicia}
                  </p>
                </div>
                {
                  data?.dineroinforma === "No" && (
                    <>
                      <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                        <h2 className="text-base font-bold leading-7 text-gray-900">
                          Si no informó ¿por qué no lo hizo?
                        </h2>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No sabía a quién acudir
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.dineronoinformanosabe}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Había informado en casos de violencia anteriores, pero no recibí
                          apoyo
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.dineronoinformanoapoyo}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No quiero perjudicar a mis familiares
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.dineronoinformanoperjudicar}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Empeoraría la situación
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.dineronoinformaempeoraria}
                        </p>
                      </div>
                    </>
                  )
                }
              </Fragment>
              :
              ""
          }
          {/* MOVILIDAD */}
          <div className="border-b border-t border-gray-900/10 col-span-4 pb-6 pt-6 mt-6 mb-6 bg-claret-100 p-2 rounded shadow">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              Información MOVILIDAD
            </h2>
          </div>
          <div className="col-span-2">
            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
              7. Algunos de sus familiares directos/personas cercanas a La PAM...
              ¿no le hablan y/o no le apoyan, o lo/la dejan solo/a por mucho
              tiempo?
            </h3>
            <p className="text-base font-semibold leading-6 text-claret-600">
              {data?.movilidad}
            </p>
          </div>
          {
            data?.movilidad !== "No" ?
              <Fragment>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    7.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadmeses}
                  </p>
                </div>
                <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                  <h2 className="text-base font-bold leading-7 text-gray-900">
                    ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                  </h2>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Hijos/Hijas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadhijos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Nietos/Nietas
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadnietos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Pareja/esposo/esposa
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadpareja}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Yerno/Nuera
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadyernonuera}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Otras personas que no son familiares directos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadotros}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    No sabe, pero lo atestiguó
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadtestigo}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    7.3 ¿Estos episodios ocurren cuando el agresor se encuentra
                    intoxicado (tomó alcohol, alguna droga)?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadintoxicado}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿El/la/los agresores viven en el mismo domicilio que la PAM?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidaddomicilio}
                  </p>
                </div>
                <div className="col-span-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    ¿Estos episodios ocurren cuando el agresor tiene problemas
                    económicos?
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadeconomico}
                  </p>
                </div>
                <div className="col-span-4">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    7.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta
                    situación? (indique todos los casos que apliquen)
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadinforma}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Familiares
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadinformafamiliares}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Amigos
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadinformaamigos}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Sacerdote
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadinformasacerdote}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Centro Salud/hospital
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadinformahospital}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Personal de Bienestar social
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadinformabienestar}
                  </p>
                </div>
                <div className="pt-2">
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    Policia
                  </h3>
                  <p className="text-base font-semibold leading-6 text-claret-600">
                    {data?.movilidadinformapolicia}
                  </p>
                </div>
                {
                  data?.movilidadinforma === "No" && (
                    <>
                      <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-4 bg-claret-100 p-2 rounded shadow">
                        <h2 className="text-base font-bold leading-7 text-gray-900">
                          Si no informó ¿por qué no lo hizo?
                        </h2>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No sabía a quién acudir
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.movilidadnoinformanosabe}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Había informado en casos de violencia anteriores, pero no recibí
                          apoyo
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.movilidadnoinformanoapoyo}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          No quiero perjudicar a mis familiares
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.movilidadnoinformanoperjudicar}
                        </p>
                      </div>
                      <div className="pt-2">
                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                          Empeoraría la situación
                        </h3>
                        <p className="text-base font-semibold leading-6 text-claret-600">
                          {data?.movilidadnoinformaempeoraria}
                        </p>
                      </div>
                    </>
                  )
                }
              </Fragment>
              :
              ""
          }
        </div>
      </div>
    </Layout>
  );
};

export default View;
