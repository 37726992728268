import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-white">
            <div className="mx-auto container py-12 md:flex md:items-center md:justify-between">
                <div className="mt-8 md:order-1 md:mt-0">
                    <p className="text-center text-xs leading-5 text-gray-500">
                        &copy;  {new Date().getFullYear()} Todos los derechos reservados | Hecho con 💙 con fines de reducir la violencia.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;