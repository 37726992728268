import Crypto from "crypto-js";

const auth = {
  encode(token) {
    const decoded = Crypto.AES.encrypt(
      JSON.stringify(token),
      process.env.REACT_APP_SECRET
    ).toString();
    return decoded;
  },
  decode(token) {
    if (!token) return null;
    const encode = Crypto.AES.decrypt(
      token,
      process.env.REACT_APP_SECRET
    ).toString(Crypto.enc.Utf8);

    if (encode) {
      return JSON.parse(encode);
    } else {
      return null;
    }
  },
};

export default auth;
