import axios from "axios";

export const post = async (url, data) => {
  try {
    const response = await axios.post(process.env.REACT_APP_URL_AUTH + url , data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const post_ = async (url, data, token) => {
  try {
    const response = await axios.post(process.env.REACT_APP_URL + url , data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
