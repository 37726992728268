import axios from "axios";

export const get = async (url, token) => {
  try {
    const response = await axios.get(process.env.REACT_APP_URL + url, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


