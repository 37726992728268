import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Components/Login";
import Index from "./Pages/Index";
import View from "./Pages/View";
import Edit from "./Pages/Edit";
import Comentarios from "./Pages/Comments";
import PrivateRoute from "./routes/PrivateRoute";

function App() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" exact element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route index exact element={<Index />} />
          <Route path="/view/:id" exact element={<View />} />
          <Route path="/edit/:id" exact element={<Edit />} />
          <Route path="/comments/:id" exact element={<Comentarios />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
